import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import AdminWrapper from "../../components/AdminWrapper";
import BookingsTable from "./BookingsTable";
import UpdateBooking from "./UpdateBooking";
import { FaSearch, FaChevronDown } from "react-icons/fa";
import { BsCalendar4Range } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PromoBookingsTable from "./Promo";
import Promo from "./Promo";
import Habitable from "./Habitable";

const Index = () => {
  const [userBookings, setUserBookings] = useState<any[]>([]);
  const [isBookingsListingsPage, setIsBookingsListingsPage] = useState(true);
  const [isUpdateBookingPage, setIsUpdateBookingPage] = useState(false);
  const [userBookingToUpdate, setUserBookingToUpdate] = useState(null);
  const [triggerReload, setTriggerReload] = useState(false);
  const [activeTab, setActiveTab] = useState("guestList");
  const [showStatusOptions, setShowStatusOptions] = useState(false);

  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
  const [showCheckInCalendar, setShowCheckInCalendar] = useState(false);
  const [showCheckOutCalendar, setShowCheckOutCalendar] = useState(false);

  const showListingspage = () => {
    setIsBookingsListingsPage(true);
    setIsUpdateBookingPage(false);
    setUserBookingToUpdate(null);
  };

  const showUpdatePage = (booking: any) => {
    setIsBookingsListingsPage(false);
    setIsUpdateBookingPage(true);
    setUserBookingToUpdate(booking);
  };

  useEffect(() => {
    const getBookings = async () => {
      try {
        const res = await userRequest("/admin/bookings/list");
        setUserBookings(res?.data?.data);
      } catch (err) {
        toast.error("There was an error fetching user bookings");
      }
    };
    getBookings();
  }, [triggerReload]);

  return (
    <AdminWrapper>
      <div className="flex w-[40%] p-2 rounded-[50px] bg-[#1E1E1E] bg-opacity-25 relative">
        <button
          className={`py-1 px-4 font-jakarta rounded-lg ${
            activeTab === "guestList"
              ? "bg-[#DEA73A] text-black"
              : "bg-transparent text-white"
          }`}
          onClick={() => setActiveTab("guestList")}
        >
          Guest List
        </button>
        <button
          className={`py-1 px-4 font-jakarta rounded-lg ${
            activeTab === "promo"
              ? "bg-[#DEA73A] text-black"
              : "bg-transparent text-white"
          }`}
          onClick={() => setActiveTab("promo")}
        >
          Promo
        </button>
        <button
          className={`py-1 px-4 font-jakarta rounded-lg ${
            activeTab === "habitableDays"
              ? "bg-[#DEA73A] text-black"
              : "bg-transparent text-white"
          }`}
          onClick={() => setActiveTab("habitableDays")}
        >
          Habitable Days
        </button>
      </div>

      {activeTab === "guestList" && (
        <>
          <div className="flex justify-between items-center my-5">
            <div className="flex items-center space-x-2">
              <div className="relative">
                <input
                  type="text"
                  className="rounded-lg border bg-transparent border-[#353945] text-sm py-2 pl-10 pr-2 focus:outline-none"
                  placeholder="Search"
                />
                <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              </div>
              <div>
                <p className="font-jakarta">Filter by:</p>
              </div>
              <div className="relative">
                <button
                  className="bg-[#323030] font-jakarta text-white py-2 px-4 rounded-lg flex items-center space-x-2"
                  onClick={() => setShowStatusOptions(!showStatusOptions)}
                >
                  <span>Status</span>
                  <FaChevronDown />
                </button>
                {showStatusOptions && (
                  <div className="absolute bg-[#323030] border border-[#323030] rounded-lg shadow-lg mt-2">
                    <div className="py-2 px-4 font-jakarta cursor-pointer">
                      Status Option 1
                    </div>
                    <div className="py-2 px-4 font-jakarta cursor-pointer">
                      Status Option 2
                    </div>
                    <div className="py-2 px-4 font-jakarta cursor-pointer">
                      Status Option 3
                    </div>
                  </div>
                )}
              </div>

              <button
                className="bg-[#323030] flex gap-5 items-center text-white py-2 px-4 rounded-lg relative"
                onClick={() => setShowCheckInCalendar(!showCheckInCalendar)}
              >
                {checkInDate ? checkInDate.toLocaleDateString() : "Check-in"}
                <BsCalendar4Range />
                {showCheckInCalendar && (
                  <div className="absolute z-10 top-full mt-2">
                    <DatePicker
                      selected={checkInDate}
                      onChange={(date) => {
                        setCheckInDate(date);
                        setShowCheckInCalendar(false);
                      }}
                      inline
                    />
                  </div>
                )}
              </button>
              
              <button
                className="bg-[#323030] flex gap-5 items-center text-white py-2 px-4 rounded-lg relative"
                onClick={() => setShowCheckOutCalendar(!showCheckOutCalendar)}
              >
                {checkOutDate ? checkOutDate.toLocaleDateString() : "Check-out"}
                <BsCalendar4Range />
                {showCheckOutCalendar && (
                  <div className="absolute z-10 top-full mt-2">
                    <DatePicker
                      selected={checkOutDate}
                      onChange={(date) => {
                        setCheckOutDate(date);
                        setShowCheckOutCalendar(false);
                      }}
                      inline
                    />
                  </div>
                )}
              </button>
            </div>
          </div>

          <BookingsTable
            userBookings={userBookings}
            setTriggerReload={setTriggerReload}
            showUpdatePage={showUpdatePage}
          />
        </>
      )}

      {activeTab === "promo" && (
        <div>
        <Promo />
        </div>
      )}

      {activeTab === "habitableDays" && (
        <div>
          <Habitable />
        </div>
      )}

      {isUpdateBookingPage && (
        <UpdateBooking
          userBooking={userBookingToUpdate}
          showListingspage={showListingspage}
          setTriggerReload={setTriggerReload}
        />
      )}
    </AdminWrapper>
  );
};

export default Index;
