import React, { useState } from 'react';

const Location: React.FC<{ onSave: (data: any) => void; onPrevious: () => void }> = ({ onSave, onPrevious }) => {
  const [apartmentNumber, setApartmentNumber] = useState('');
  const [building, setBuilding] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);

  const toggleCityDropdown = () => setCityDropdownOpen(!cityDropdownOpen);
  const toggleStateDropdown = () => setStateDropdownOpen(!stateDropdownOpen);

  const handleCitySelect = (selectedCity: string) => {
    setCity(selectedCity);
    setCityDropdownOpen(false);
  };

  const handleStateSelect = (selectedState: string) => {
    setState(selectedState);
    setStateDropdownOpen(false);
  };

  // Check if inputs are valid to enable buttons
  const isButtonDisabled =
    !apartmentNumber || !building || !city || !state || !country || !longitude || !latitude;

  // Function to handle saving the location data
  const handleSave = () => {
    const locationData = {
      apartmentNumber,
      building,
      city,
      state,
      country,
      longitude,
      latitude,
    };
    onSave(locationData); // Pass the location data back to AddProperty
  };

  return (
    <div className="px-[50px] py-[20px]">
      <div className="flex space-x-4 mb-4">
        <div className="flex-1">
          <label className="block mb-1">Apartment Number</label>
          <input
            type="number"
            placeholder="Enter Apartment Number"
            value={apartmentNumber}
            onChange={(e) => setApartmentNumber(e.target.value)}
            className="bg-[#242424] p-2 w-full rounded-[5px] outline-none text-[14px]"
          />
        </div>
        <div className="flex-1">
          <label className="block mb-1">Building</label>
          <input
            type="text"
            placeholder="Enter Building"
            value={building}
            onChange={(e) => setBuilding(e.target.value)}
            className="bg-[#242424] p-2 w-full rounded-[5px] outline-none text-[14px]"
          />
        </div>
      </div>
      <div className="flex space-x-4 mb-4">
        <div className="flex-1">
          <label className="block mb-1">City</label>
          <div
            onClick={toggleCityDropdown}
            className="bg-[#242424] p-2 cursor-pointer rounded-[5px] text-[14px]"
          >
            {city || 'Select City'}
          </div>
          {cityDropdownOpen && (
            <div className="bg-[#242424] mt-1">
              <div
                onClick={() => handleCitySelect('New York')}
                className="p-2 cursor-pointer"
              >
                New York
              </div>
              <div
                onClick={() => handleCitySelect('Los Angeles')}
                className="p-2 cursor-pointer"
              >
                Los Angeles
              </div>
              <div
                onClick={() => handleCitySelect('Chicago')}
                className="p-2 cursor-pointer"
              >
                Chicago
              </div>
            </div>
          )}
        </div>
        <div className="flex-1">
          <label className="block mb-1">State</label>
          <div
            onClick={toggleStateDropdown}
            className="bg-[#242424] p-2 cursor-pointer rounded-[5px] text-[14px]"
          >
            {state || 'Select State'}
          </div>
          {stateDropdownOpen && (
            <div className="bg-[#242424] mt-1">
              <div
                onClick={() => handleStateSelect('California')}
                className="p-2 cursor-pointer"
              >
                California
              </div>
              <div
                onClick={() => handleStateSelect('Texas')}
                className="p-2 cursor-pointer"
              >
                Texas
              </div>
              <div
                onClick={() => handleStateSelect('New York')}
                className="p-2 cursor-pointer"
              >
                New York
              </div>
            </div>
          )}
        </div>
        <div className="flex-1">
          <label className="block mb-1">Country</label>
          <input
            type="text"
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="w-full bg-[#242424] p-2 flex-1 outline-none rounded-[5px] text-[14px]"
          />
        </div>
      </div>
      <div className="flex space-x-4 mb-4">
        <div className="flex-1">
          <label className="block mb-1">Longitude</label>
          <input
            type="number"
            placeholder="Enter Longitude"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
            className="w-full bg-[#242424] p-2 rounded-[5px] outline-none text-[14px]"
          />
        </div>
        <div className="flex-1">
          <label className="block mb-1">Latitude</label>
          <input
            type="number"
            placeholder="Enter Latitude"
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
            className="w-full bg-[#242424] p-2 rounded-[5px] outline-none text-[14px]"
          />
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={onPrevious}
          className={`border border-[#DEA838] text-[#DEA838] py-1 px-3 rounded-[5px] ${
            isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isButtonDisabled}
        >
          Previous
        </button>
        <button
          onClick={handleSave}
          className={`bg-[#DEA838] text-black py-1 px-3 rounded-[5px] ${
            isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isButtonDisabled}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Location;
