import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  // Grid,
  CircularProgress,
  Typography,
  Link,
} from "@mui/material";
// import { authImage } from "../../assets";
import { logo } from "../../assets";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { loginUserSuccess, loginUserFailure } from "../../services/loginSlice";
// import { useDispatch } from "react-redux";
// import axios from "../../utils/index";
import Cookies from "js-cookie";
// import { userData } from "../Admin/Users/userData";
import styled from "@emotion/styled";
import { publicRequest } from "../../requestMethod";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = Cookies.get("adminUserData_maihomm");
    if (user) {
      navigate("/overview");
      window.location.reload();
    }
  });

  const handleReset = async (values: any) => {
    if (!otpSent) {
      try {
        setLoading(true);
        await publicRequest.post("/forgot-password", {
          email: values.email,
        });
        toast.success(`OTP sent to ${values.email}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: true,
        });
        setOtpSent(true);
      } catch (error: any) {
        toast.error(`${error.response.data.message}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      } finally {
        setLoading(false);
      }
    } else if (otpSent) {
      try {
        setLoading(true);
        await publicRequest.post("/password-reset", {
          code: values.otp,
          email: values.email,
          new_password: values.password,
          confirm_password: values.passwordConfirmed,
        });
        toast.success("Password Reset successful", {
          // position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } catch (error: any) {
        toast.error(error.response.data.error_debug, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
          hideProgressBar: true,
        });
        console.log(error.response.data.error_debug);
      } finally {
        setLoading(false);
      }
    }
  };
  const initialValues = {
    otp: "",
    password: "",
    passwordConfirm: "",
  };

  const emailValidSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
    password: Yup.string()
      .min(6, "Password must have at least 6 characters")
      .required("Password is required"),
    passwordConfirmed: Yup.string().oneOf(
      [Yup.ref("password")],
      "Password must match"
    ),
  });

  const textFieldStyle = {
    fontSize: "12px",
    marginBottom: "15px",
    backgroundColor: "#1E1E1E",
    borderRadius: "10px",
  };
  return (
    <Container>
      <img
        src={logo}
        alt=""
        style={{
          height: "40px",
          width: "150px",
          marginBottom: "50px",
        }}
      />
      {!otpSent ? (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={emailValidSchema}
          onSubmit={handleReset}
        >
          <Form style={{ maxWidth: "530px", width: "85%" }}>
            <div className="form-control">
              {/* <Typography
                mb="50px"
                variant="subtitle1"
                sx={{ fontWeight: 600, fontSize: "24px" }}
              >
                Login to your account
              </Typography> */}
              <Typography variant="subtitle2" gutterBottom>
                * Input Login Email
              </Typography>
              <Field
                as={TextField}
                type="email"
                label="email"
                name="email"
                size="small"
                variant="filled"
                style={textFieldStyle}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
              <ErrorMessage name="email" component="div" />
            </div>

            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Reset"}
              </Button>
            </div>

            <p style={{ textAlign: "right" }}>
              ← <Link href="/login"> back to login</Link>
            </p>
          </Form>
        </Formik>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleReset}
        >
          <Form style={{ maxWidth: "530px", width: "85%" }}>
            <div className="form-control">
              <Typography
                mb="30px"
                variant="subtitle1"
                sx={{ fontWeight: 600, fontSize: "24px" }}
              >
                Reset Password
              </Typography>

              <Typography variant="subtitle2" gutterBottom>
                OTP Code
              </Typography>
              <Field
                as={TextField}
                type="string"
                // label="email"
                name="otp"
                size="small"
                variant="filled"
                style={textFieldStyle}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </div>
            <div className="form-control">
              <Typography variant="subtitle2" gutterBottom>
                New Password
              </Typography>
              <Field
                as={TextField}
                type="password"
                name="password"
                size="small"
                variant="filled"
                style={{ ...textFieldStyle }}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
              <ErrorMessage name="password" component="div" />
            </div>
            <div className="form-control">
              <Typography variant="subtitle2" gutterBottom>
                Confirm New Password
              </Typography>
              <Field
                as={TextField}
                type="password"
                name="passwordConfirmed"
                size="small"
                variant="filled"
                style={{ ...textFieldStyle }}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
              <ErrorMessage name="passwordConfirmed" component="div" />
            </div>

            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Confirm"}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                margin: "20px 0",
              }}
            >
              <Button
                onClick={() => setOtpSent(false)}
                sx={{ textTransform: "lowercase" }}
              >
                {" "}
                resend otp?{" "}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </Container>
  );
};

export default ForgotPassword;
