import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { formatPrice } from "../../utils/helpers";
import { Box, Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export default function PropTable(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [prodToDeleteId, setProdToDeleteId] = React.useState();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const deleteProductFromDb = async () => {
    try {
      await userRequest.delete(`admin/properties/delete/${prodToDeleteId}`);
      toast.success("Property successfully deleted", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
      });
    } catch (err) {
      toast.error("Error occurred while deleting product", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
    setShowDeleteDialog(false);
    props.setTriggerReload(!props.triggerReload);
  };

  const handleDelete = (prod: any) => {
    setShowDeleteDialog(true);
    setProdToDeleteId(prod);
  };

  const handleClose = () => {
    setShowDeleteDialog(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="w-full overflow-hidden rounded-lg font-jakarta border border-gray-800">
      {/* Delete Dialog */}
      {showDeleteDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-black p-6 rounded-lg">
            <h2 className="text-lg font-semibold">Delete Product?</h2>
            <p>Are you sure you want to delete this product?</p>
            <div className="mt-4 flex justify-between">
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={deleteProductFromDb} className="text-red-500">
                DELETE
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Table */}
      <div className="overflow-x-auto font-jakarta">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-[#242424] text-left">
              <th className="px-4 text-[14px] py-2">PROPERTY NAME</th>
              <th className="px-4 text-[14px] py-2">PRICE</th>
              <th className="px-4 text-[14px] py-2">STATUS</th>
              <th className="px-4 text-[14px] py-2">VIEWS</th>
              <th className="px-4 text-[14px] py-2">SOLD</th>
              <th className="px-4 text-[14px] py-2">UPLOAD DATE</th>
              <th className="px-4 text-[14px] py-2"></th>
            </tr>
          </thead>
          <tbody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => (
                <tr key={row.id} className="">
                  <td className="px-4 text-[14px] py-2">{row.name}</td>
                  <td className="px-4 text-[14px] py-2">{formatPrice(row?.costings?.price)}</td>
                  <td className="px-4 text-[14px] py-2">{row?.status}</td>
                  <td className="px-4 text-[14px] py-2">{row?.impressions?.total_views}</td>
                  <td className="px-4 text-[14px] py-2">{row?.impressions?.total_sold}</td>
                  <td className="px-4 text-[14px] py-2">{row.uploaded}</td>
                  <td className="px-4 text-[14px] py-2">
                    <div className="flex justify-between items-center">
                      <div></div>
                      <div className="flex gap-3">
                        <EditOutlinedIcon
                          className="cursor-pointer"
                          onClick={() => props.showEditPage(row)}
                        />
                        <Link to={`/properties/${row.id}`}>
                          <RemoveRedEyeOutlinedIcon className="cursor-pointer" />
                        </Link>
                        <DeleteOutlineOutlinedIcon
                          className="cursor-pointer text-red-500"
                          onClick={() => handleDelete(row.id)}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4 p-4">
        <div>
          <span>Rows per page:</span>
          <select
            className="ml-2 p-2 border bg-[#DEA838] border-[#DEA838] text-black rounded"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <button
            className="p-2 bg-[#DEA838] text-[black] rounded"
            onClick={(event) => handleChangePage(event, page - 1)}
            disabled={page === 0}
          >
            Previous
          </button>
          <span className="mx-4">{`${page + 1} of ${Math.ceil(props.data.length / rowsPerPage)}`}</span>
          <button
            className="p-2 bg-[#DEA838] text-[black] rounded"
            onClick={(event) => handleChangePage(event, page + 1)}
            disabled={page >= Math.ceil(props.data.length / rowsPerPage) - 1}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
