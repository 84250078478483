import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const InvestmentsReturn = () => {
  return (
    <div className="bg-[#1E1E1E] shadow-md font-jakarta rounded-lg p-4">
      <div className="flex items-start justify-start">
        <p className="text-[#B6B7B9] text-lg">
          Investment Returns
        </p>
      </div>
      <hr className="my-2 border-[#B3B3B3]" />
      <div className="flex items-center justify-between">
        <div>
          <p className="text-[#B6B7B9] text-[14px]">
            Transaction Value
          </p>
          <div className="flex items-center">
            <p className="text-[25px] mr-2">£120,000</p>
            <div className="flex items-center text-green-500">
              <ArrowUpwardIcon fontSize="small" />
              <p className="text-[25px] ml-1">+5%</p> {/* Adjust the percentage value as needed */}
            </div>
          </div>
        </div>
        <div>
          <p className="text-[#B6B7B9] text-[14px]">
            Transaction Volume
          </p>
          <p className="text-[25px]">£90,000,000</p>
        </div>
        <div>
          <p className="text-[#B6B7B9] text-[14px]">
            Total Number of Investors
          </p>
          <p className="text-[25px]">01/05/2023</p>
        </div>
      </div>
    </div>
  );
};

export default InvestmentsReturn;
