import { Route, Routes } from "react-router-dom";
import {
  Account,
  // IncomeHistory,
  // Loan,
  Login,
  // Portfolio,
  Profile,
  // Properties,
  PropertyDetails,
  Register,
  Settings,
  // Cart,
  // Booking,
  Payment,
  // BookingDetails,
  // Rewards,
  // Overview,
  // Wallet,
  AdminOverview,
  AdminInvestments,
  AdminUsers,
  EditUser,
  AddNew,
  ManageProperties,
  ManageInvestments,
  Support,
  ForgotPassword,
} from "../pages";
import UserProperty from "../pages/UserProperty/userProperty";
import RoiPayroll from "../pages/Investment/RoiPayroll";
import PortfolioForm from "../pages/Investment/RoiForm";
import SlotDetail from "../pages/Payment/SlotDetails";
import Booking from "../pages/Booking/index";
import Withdraw from "../pages/Withdraw";

const RouterConfig = () => {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/properties" element={<Properties />} /> */}
        <Route path="/account" element={<Account />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/resetpassword" element={<ForgotPassword />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/properties/:id" element={<PropertyDetails />} />
        {/* <Route path="/loan" element={<Loan />} />
        <Route path="/income-history" element={<IncomeHistory />} /> */}
        {/* <Route path="/cart" element={<Cart />} /> */}
        {/*<Route path="/bookings/:id" element={<BookingDetails />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/overview" element={<Overview />} />
      <Route path="/wallet" element={<Wallet />} /> */}
        <Route path="/admin/overview" element={<AdminOverview />} />
        <Route path="/property" element={<UserProperty />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/users/new" element={<AddNew />} />
        <Route path="/admin/investments" element={<AdminInvestments />} />
        <Route path="/admin/roipayroll" element={<RoiPayroll />} />
        <Route path="/admin/investForm" element={<PortfolioForm />} />
        <Route path="/admin/investments/:id" element={<ManageInvestments />} />
        <Route path="/admin/users/:id" element={<EditUser />} />
        <Route path="/admin/properties" element={<ManageProperties />} />
        <Route path="/admin/bookings" element={<Booking />} />
        <Route path="/admin/withdraw" element={<Withdraw />} />
        <Route path="/admin/payments" element={<Payment />} />
        <Route path="/admin/support" element={<Support />} />
        <Route path="/admin/slotdetail" element={<SlotDetail />} />
      </Routes>
    </>
  );
};

export default RouterConfig;
