import AdminWrapper from "../../components/AdminWrapper";
import FilterBar from "./filterBar";

const UserProperty = () =>{
    return (
        <AdminWrapper>
            <FilterBar />
        </AdminWrapper>
    )
}

export default UserProperty;