import React, { useState } from 'react';
import ReactQuill from 'react-quill'; // Make sure to install react-quill
import 'react-quill/dist/quill.snow.css'; // Import the Quill CSS for styling

const BasicInformation: React.FC<{ onSave: (data: any) => void; }> = ({ onSave }) => {
  const [propertyName, setPropertyName] = useState('');
  const [bedroom, setBedroom] = useState('');
  const [bathroom, setBathroom] = useState('');
  const [squareArea, setSquareArea] = useState('');
  const [description, setDescription] = useState('');

  // Function to determine if the save button should be enabled
  const isButtonDisabled = () => {
    return !propertyName || !bedroom || !bathroom || !squareArea || !description;
  };

  const handleSave = () => {
    // Call the onSave function if the button is not disabled
    const data = {
      propertyName,
      bedroom,
      bathroom,
      squareArea,
      description,
    };

    // Call the onSave function with the filled data
    if (!isButtonDisabled()) {
      onSave(data);
    }
  };

  return (
    <div className='px-[50px]'>
      {/* Property Name Input */}
      <div className="my-4">
        <label htmlFor="propertyName" className="block text-sm font-medium text-white">
          Property Name
        </label>
        <input
          id="propertyName"
          type="text"
          value={propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          className="mt-1 block w-full rounded-md shadow-sm p-2 bg-[#242424] text-white placeholder-gray-500 focus:outline-none"
          placeholder="Enter Property Name"
        />
      </div>

      {/* Flex container for Bedroom, Bathroom, and Square Area */}
      <div className="flex justify-between mb-4">
        <div className="flex-1 mr-2">
          <label htmlFor="bedroom" className="block text-sm font-medium text-white">
            Bedroom
          </label>
          <input
            id="bedroom"
            type="number"
            value={bedroom}
            onChange={(e) => setBedroom(e.target.value)}
            className="mt-1 block w-full rounded-md shadow-sm p-2 bg-[#242424] text-white placeholder-gray-500 focus:outline-none"
            placeholder="Enter Bedroom Count"
          />
        </div>
        <div className="flex-1 mx-2">
          <label htmlFor="bathroom" className="block text-sm font-medium text-white">
            Bathroom
          </label>
          <input
            id="bathroom"
            type="number"
            value={bathroom}
            onChange={(e) => setBathroom(e.target.value)}
            className="mt-1 block w-full rounded-md shadow-sm p-2 bg-[#242424] text-white placeholder-gray-500 focus:outline-none"
            placeholder="Enter Bathroom Count"
          />
        </div>
        <div className="flex-1 ml-2">
          <label htmlFor="squareArea" className="block text-sm font-medium text-white">
            Square Area
          </label>
          <input
            id="squareArea"
            type="number"
            value={squareArea}
            onChange={(e) => setSquareArea(e.target.value)}
            className="mt-1 block w-full rounded-md shadow-sm p-2 bg-[#242424] text-white placeholder-gray-500 focus:outline-none"
            placeholder="Enter Square Area"
          />
        </div>
      </div>

      {/* React Quill for description */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">Property Overview</label>
        <ReactQuill 
          value={description}
          onChange={setDescription}
          className="mt-1"
        />
      </div>

      {/* Save Button */}
      <div className="flex justify-end mt-4">
        <button 
          className={`px-3 py-1 rounded-[5px] text-sm ${isButtonDisabled() ? 'bg-opacity-50 bg-[#DEA838] text-black' : 'bg-[#DEA838] text-black'}`} 
          onClick={handleSave} 
          disabled={isButtonDisabled()} // Disable button based on validation
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default BasicInformation;
