import { Button } from '@mui/material';
import React, { useState } from 'react';
import { BiUpload } from 'react-icons/bi';
import { MdArrowDropDown } from 'react-icons/md';

const OtherInformation: React.FC<{ onSave: (payload: any) => void; onPrevious: () => void }> = ({ onSave, onPrevious }) => {
  const [info, setInfo] = useState('');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
  const [selectedOption, setSelectedOption] = useState('Option 1');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [vrLink, setVrLink] = useState('http://');

  const options = ['Available'];

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedVideo(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, type: 'image' | 'video') => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      type === 'image' ? setSelectedImage(file) : setSelectedVideo(file);
    }
  };

  const handleSave = () => {
    const payload = {
      selectedOption,
      selectedImage,
      selectedVideo,
      vrLink,
    };
    onSave(payload); // Passing the payload to the onSave function
  };

  return (
    <div className="px-[50px] py-[20px]">
      <label htmlFor="">Status</label>

      {/* Dropdown Section */}
      <div className="relative mt-2">
        <div
          className="flex items-center justify-between bg-[#242424] px-4 py-2 rounded-md cursor-pointer"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          <span>{selectedOption}</span>
          <MdArrowDropDown />
        </div>
        {isDropdownOpen && (
          <div className="absolute top-full left-0 w-full bg-[#242424] rounded-md shadow-lg mt-1 z-10">
            {options.map((option) => (
              <div
                key={option}
                className={`px-4 py-2 hover:bg-gray-100 hover:text-black cursor-pointer ${
                  option === selectedOption ? 'font-bold' : ''
                }`}
                onClick={() => {
                  setSelectedOption(option);
                  setIsDropdownOpen(false);
                }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Image Upload Section */}
      <label className="block mt-4">Add Image</label>
      <div
        className="flex items-center justify-center bg-[#242424] gap-4 h-[200px] rounded-md mt-2 p-4 cursor-pointer"
        onClick={() => document.getElementById('imageInput')?.click()}
        onDragOver={handleDragOver}
        onDrop={(event) => handleDrop(event, 'image')}
      >
        <input
          type="file"
          id="imageInput"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
        {selectedImage && (
          <div className="mt-4">
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              className="w-32 h-32 object-cover border border-gray-300 rounded"
            />
          </div>
        )}
        <p className="flex items-center gap-2">
          <BiUpload />
          Click or drop image
        </p>
      </div>

      {/* Video Upload Section */}
      <label className="block mt-4">Add Video</label>
      <div
        className="flex items-center justify-center bg-[#242424] gap-4 h-[200px] rounded-md mt-2 p-4 cursor-pointer"
        onClick={() => document.getElementById('videoInput')?.click()}
        onDragOver={handleDragOver}
        onDrop={(event) => handleDrop(event, 'video')}
      >
        <input
          type="file"
          id="videoInput"
          accept="video/*"
          style={{ display: 'none' }}
          onChange={handleVideoUpload}
        />
        {selectedVideo && (
          <div className="mt-4">
            <video
              src={URL.createObjectURL(selectedVideo)}
              controls
              className="w-32 h-32 border border-gray-300 rounded"
            />
          </div>
        )}
        <p className="flex items-center gap-2">
          <BiUpload />
          Click or drop video
        </p>
      </div>

      {/* VR Link Section */}
      <label className="block mt-4">Add VR Link</label>
      <input
        type="text"
        value={vrLink}
        onChange={(e) => setVrLink(e.target.value)}
        className="w-full px-4 py-2 bg-[#242424] text-white rounded-md mt-2"
        placeholder="Enter VR Link"
      />

      <div className="flex justify-end gap-4 mt-4">
        <button className="border border-[#DEA838] bg-transparent rounded-[5px] text-[#DEA838] py-2 px-4" onClick={onPrevious}>
          Previous
        </button>
        <button className="bg-[#DEA838] text-black rounded-[5px] py-2 px-4" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default OtherInformation;
