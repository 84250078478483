import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io"; // Correct arrow down icon

interface OverviewProps {
  overview: any; // Flexible data structure
}

const RecentTransaction: React.FC<OverviewProps> = ({ overview }) => {
  const [showTransactions, setShowTransactions] = useState(true);
  const [currency, setCurrency] = useState("£"); // Default currency symbol
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown visibility

  const getStatusColor = (
    status: "Pending" | "Approved" | "Declined"
  ): { textColor: string; bgColor: string } => {
    switch (status) {
      case "Approved":
        return { textColor: "#30FF51", bgColor: "rgba(48, 255, 81, 0.3)" };
      case "Pending":
        return { textColor: "#ECBA2F", bgColor: "rgba(236, 186, 47, 0.3)" };
      case "Declined":
        return { textColor: "#FF5F00", bgColor: "rgba(255, 95, 0, 0.3)" };
      default:
        return { textColor: "#9A9FA5", bgColor: "rgba(154, 159, 165, 0.3)" };
    }
  };

  const handleCurrencyClick = (currencySymbol: string) => {
    setCurrency(currencySymbol);
    setShowDropdown(false); // Close dropdown after selection
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };

  return (
    <div className="my-5 w-[100%] bg-[#1E1E1E] font-jakarta">
      <div className="w-full px-4 pt-3 flex justify-between my-4">
        <p className="font-jakarta text-[14px]">RECENT PAYMENT</p>
        <div className="inline-block text-left cursor-pointer">
          <div
            className="flex items-center bg-[#1E1E1E] border border-[#6F767E] py-[4px] rounded-[10px] px-[10px]"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <p className="text-[white] text-[14px] font-jakarta">{currency}</p>
            <IoIosArrowDown className="ml-2 text-[white]" />
          </div>
          {showDropdown && (
            <div className="absolute mt-2 right-[60px] rounded-md shadow-lg bg-[#1E1E1E] ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                <button
                  className="block px-4 py-2 text-sm text-[white]"
                  onClick={() => handleCurrencyClick("£")}
                >
                  £ Pound
                </button>
                <button
                  className="block px-4 py-2 text-sm text-[white]"
                  onClick={() => handleCurrencyClick("$")}
                >
                  $ Dollar
                </button>
                <button
                  className="block px-4 py-2 text-sm text-[white]"
                  onClick={() => handleCurrencyClick("₦")}
                >
                  ₦ Naira
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full bg-[#1E1E1E] rounded-[10px]">
        {showTransactions ? (
          <div className="container mx-auto">
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead className="border-b border-[#323030] bg-[#2e2e2e]">
                  <tr>
                    <th className="py-2 px-4 text-[#9A9FA5] font-jakarta text-left text-sm font-[600]">
                      NAME
                    </th>
                    <th className="py-2 px-4 text-[#9A9FA5] font-jakarta text-left text-sm font-[600]">
                      LOCATION
                    </th>
                    <th className="py-2 px-4 text-[#9A9FA5] font-jakarta text-left text-sm font-[600]">
                      SLOT
                    </th>
                    <th className="py-2 px-4 text-[#9A9FA5] font-jakarta text-left text-sm font-[600]">
                      AMOUNT
                    </th>
                    <th className="py-2 px-4 text-[#9A9FA5] font-jakarta text-left text-sm font-[600]">
                      STATUS
                    </th>
                    <th className="py-2 px-4 text-[#9A9FA5] font-jakarta text-left text-sm font-[600]">
                      DATE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {overview?.map((item: any, index: any) => {
                    const { textColor, bgColor } = getStatusColor(item.status);
                    return (
                      <tr key={index} className="border-b border-[#323030]">
                        <td className="py-4 px-4 text-[12px] font-jakarta">{item.user.first_name}</td>
                        <td className="py-4 px-4 text-[12px] font-jakarta">{item.user.country}</td>
                        <td className="py-4 px-4 text-[12px] font-jakarta">{item.slot}</td>
                        <td className="py-4 px-4 text-[12px] font-jakarta">
                          {item?.amount?.replace("£", currency)}
                        </td>
                        <td className="py-4 px-4 text-[12px] font-jakarta">
                          <span
                            className="px-4 py-2 rounded-[10px]"
                            style={{ color: textColor, backgroundColor: bgColor }}
                          >
                            {item.status}
                          </span>
                        </td>
                        <td className="py-4 px-4 text-[12px] font-jakarta">{formatDate(item.created_at)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center mt-4 p-16">
            <img
              src="/assets/images/empty.svg"
              className="h-16 w-16"
              alt="No properties"
            />
            <p className="text-[#B6B7B9]">No transactions yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentTransaction;
