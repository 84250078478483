import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FaRegHandshake } from 'react-icons/fa';
import { LuHeart } from 'react-icons/lu';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { LiaTimesSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import { userRequest } from '../requestMethod';
import { toast } from 'react-toastify';
import { BiSolidUser } from "react-icons/bi";

// import Whishlist from './Whishlist';
import Cookies from 'js-cookie';

interface TopBarProps {
  activeLink: string;
}

const TopBar: React.FC<TopBarProps> = ({ activeLink }) => {
  const [notificationList, setNotificationList] = React.useState<any[]>([]);
  const [unreadNotificationsIds, setUnreadNotificationsIds] = React.useState<any[]>([]);
  const [unreadNotificationLength, setUnreadNotificationLength] = React.useState(0);
  const [userCartLength, setUserCartLength] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [handshakeIconColor, setHandshakeIconColor] = React.useState<string>('#9A9FA5');
  const navigate = useNavigate();

  React.useEffect(() => {
    const getUserCartLength = async () => {
      try {
        const res = await userRequest('/user/carts/items');
        setUserCartLength(res?.data?.data?.length);
      } catch (err: any) {
        toast.error(err.response.data.message);
      }
    };

    getUserCartLength();
  }, []);

  const [userData, setUserData] = React.useState<any>({});
  React.useEffect(() => {
    const res = Cookies.get('userData');
    if (res) {
      setUserData(JSON.parse(res));
    }
  }, []);

  React.useEffect(() => {
    const getUnreadNotificationsLength = async () => {
      try {
        const res = await userRequest('/notifications');
        let data = res.data.data.data;
        let count = 0;
        for (let element of data) {
          if (element.is_read === false) {
            count++;
          } else {
            break;
          }
        }
        setUnreadNotificationLength(count);
      } catch (err) {
        console.log(err);
      }
    };
    getUnreadNotificationsLength();
  }, []);

  const handleHandshakeIconClick = () => {
    setHandshakeIconColor('red');
    setTimeout(() => {
      navigate('/cart');
    }, 0);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  console.log(activeLink, "activvvvvvvvvv")

  return (
    <Box sx={{ borderBottom: '1px solid #787879' }}>
      <AppBar position="static" sx={{ background: '#06080A', py: 4, px: 6 }}>
        <div className="flex justify-between items-center">
          <Typography
            variant="h2"
            noWrap
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
                fontSize: '25px',
                fontWeight: '300',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
              },
            }}
          >
            {activeLink === 'overview' ? `Welcome` : activeLink.charAt(0).toUpperCase() + activeLink.slice(1)} {/* Capitalize the first letter */}
          </Typography>
          <div className="flex gap-6 pr-4">
            {/* <FaRegHandshake
              className={`h-[20px] w-[20px] cursor-pointer`}
              style={{ color: handshakeIconColor }}
              onClick={handleHandshakeIconClick}
            />
            <LuHeart className="text-[#9A9FA5] h-[20px] w-[20px] cursor-pointer" onClick={toggleModal} /> */}
            <IoMdNotificationsOutline className="text-white h-[20px] w-[20px] cursor-pointer" />
            <BiSolidUser className="text-white h-[20px] w-[20px] cursor-pointer"/>
          </div>
        </div>
      </AppBar>

      {/* {isModalOpen && (
        <div className="fixed top-0 right-0 h-full w-[30%] p-5 bg-black border-l border-l-[gray] shadow-lg z-50">
          <div className="bg-black z-10 h-[10vh]">
            <div className="flex pr-3 pt-3 flex-row justify-end text-end w-full">
              <LiaTimesSolid onClick={toggleModal} className="bg-[gray] text-white text-[25px] cursor-pointer" />
            </div>
            <p className="text-[20px] font-jakarta">Whishlist</p>
          </div>
          <div className="custom-scroll-content pt-7">
           
          </div>
        </div>
      )} */}
    </Box>
  );
};

export default TopBar;
