import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  InputLabel,
  Select,
  FormControl,
  Divider,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import Wrapper from "../../components/Wrapper";
import InfoIcon from "@mui/icons-material/Info";
import MenuItem from "@mui/material/MenuItem";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { useEffect, useState } from "react";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ProfileNav from "./ProfileNav";
import TopBar from "../../components/topNav";
import { userRequest } from "../../requestMethod";
import Cookies from "js-cookie";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, "Current password must have at least 6 characters")
    .required("Current password is required"),
  newPassword: Yup.string()
    .min(6, "New password must have at least 6 characters")
    .required("Password is required"),
  newPasswordConfirmed: Yup.string().oneOf(
    [Yup.ref("newPassword")],
    "Passwords must match"
  ),
});

const Settings = () => {
  const [currency, setCurrency] = useState("gbp");
  const [language, setLanguage] = useState("eng");
  const navigate = useNavigate();
  // const [userData, setUserData] = useState<any>(null);
  const [toggleState_two_factor_auth, setToggleState_two_factor_auth] =
    useState(false);
  const [toggleState_hide_balance, setToggleState_hide_balance] =
    useState(false);
  const [
    toggleState_receive_email_notifications,
    setToggleState_receive_email_notifications,
  ] = useState(false);
  const [
    toggleState_receive_text_notifications,
    setToggleState_receive_text_notifications,
  ] = useState(false);
  const [userSettings, setUserSettings] = useState<any>(null);

  useEffect(() => {
    const res = Cookies.get("adminUserSettings_maihomm") || "";
    const storedSettings = JSON.parse(res);
    setUserSettings(storedSettings);
    const state_two_factor_auth =
      storedSettings.two_factor_auth === 1 ? true : false;
    setToggleState_two_factor_auth(state_two_factor_auth);
    const state_hide_balance = storedSettings.hide_balance === 1 ? true : false;
    setToggleState_hide_balance(state_hide_balance);
    const state_receive_email_notifications =
      storedSettings.receive_email_notifications === 1 ? true : false;
    setToggleState_receive_email_notifications(
      state_receive_email_notifications
    );
    const state_receive_text_notifications =
      storedSettings.receive_text_notifications === 1 ? true : false;
    setToggleState_receive_text_notifications(state_receive_text_notifications);
    // console.log(storedSettings);
  }, []);

  const handleCurrency = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };
  const handleLang = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };

  const handleToggle = async (
    toggler: any,
    toggleState: boolean,
    toggleName: string
  ) => {
    toggler(!toggleState);
    // console.log(!toggleState_two_factor_auth);
    try {
      await userRequest.post("/user/profile/settings/field-update", {
        field: toggleName,
        enabled: !toggleState ? 1 : 0,
      });
    } catch (err) {
      console.log(err);
    }
    const settings = { ...userSettings, [toggleName]: !toggleState ? 1 : 0 };
    Cookies.set("adminUserSettings_maihomm", JSON.stringify(settings));
  };

  const textFieldStyle = {
    fontSize: "12px",
    marginBottom: "15px",
    outline: "none",
    backgroundColor: "#1E1E1E",
    borderRadius: "10px",
    paddingLeft: "12px",
  };

  const handlePasswordChange = async (
    values: {
      currentPassword: string;
      newPassword: string;
      newPasswordConfirmed: string;
    },
    setSubmitting: any
  ) => {
    try {
      setSubmitting(true);
      await userRequest.post(
        "user/profile/settings/change-password",
        {
          current_password: values.currentPassword,
          new_password: values.newPassword,
        }
      );
      toast.success("Password updated Successful");
      Cookies.remove('adminUserData_maihomm');
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 2000);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <Box sx={{ width: "95%", height: "100%" }}>
        <Grid container>
          <ProfileNav />
          <Grid
            item
            xs={12}
            md={7}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h5" style={{ marginBottom: "1.7rem" }}>
              General
            </Typography>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <InputLabel
                  htmlFor="name"
                  style={{ color: "#fff", marginBottom: "5px" }}
                >
                  Currency
                </InputLabel>
                <small style={{ color: "#B3B3B3" }}>
                  Choose your prefered currency
                </small>
              </Box>
              <FormControl sx={{ minWidth: 120, marginY: 1 }} size="small">
                <Select
                  labelId="currency"
                  id="currency"
                  value={currency}
                  // label="Naira(NGN)"
                  onChange={handleCurrency}
                >
                  <MenuItem value={"usd"}>$ Dollar(USD)</MenuItem>
                  <MenuItem value={"gbp"}>£ Pounds(GBP)</MenuItem>
                  <MenuItem value={"ngn"}>₦ Naira(NGN)</MenuItem>
                </Select>
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                  mt: 3,
                }}
              >
                <InputLabel
                  htmlFor="language"
                  style={{ color: "#fff", marginBottom: "5px" }}
                >
                  Language
                </InputLabel>
                <small style={{ color: "#B3B3B3" }}>
                  Choose your prefered language
                </small>
              </Box>
              <FormControl sx={{ marginY: 1, minWidth: 120 }} size="small">
                {/* <InputLabel>La</InputLabel> */}

                <Select
                  labelId="Language"
                  id="language"
                  // label="Language"
                  value={language}
                  onChange={handleLang}
                >
                  <MenuItem value={"eng"}>English</MenuItem>
                  {/* <MenuItem value={"port"}>Portugues</MenuItem> */}
                </Select>
              </FormControl>
              <div>
                <Divider sx={{ my: 4, borderBottom: "1px solid #B6B7B9" }} />
              </div>
              <Grid>
                <Typography style={{ marginBottom: "30px" }}>
                  Security{" "}
                </Typography>
                <Typography variant="body1">Password</Typography>

                <Formik
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    newPasswordConfirmed: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handlePasswordChange(values, setSubmitting);
                  }}
                >
                  {({ values, errors, isSubmitting }) => (
                    <Form style={{ width: "100%" }}>
                      <div className="form-control">
                        <InputLabel
                          htmlFor="password"
                          style={{ marginBottom: "10px" }}
                        >
                          Current Password
                        </InputLabel>
                        <Field
                          as={TextField}
                          type="password"
                          name="currentPassword"
                          variant="standard"
                          size="medium"
                          style={textFieldStyle}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        <ErrorMessage name="currentPassword" component="div" />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="newpassword"
                              style={{ marginBottom: "10px" }}
                            >
                              New Password
                            </InputLabel>
                            <Field
                              as={TextField}
                              type="password"
                              name="newPassword"
                              variant="standard"
                              size="medium"
                              style={textFieldStyle}
                              InputProps={{ disableUnderline: true }}
                              fullWidth
                            />
                            <ErrorMessage name="newPassword" component="div" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="newpasswordconfirm"
                              style={{ marginBottom: "10px" }}
                            >
                              Confirm New Password
                            </InputLabel>
                            <Field
                              as={TextField}
                              type="password"
                              name="newPasswordConfirmed"
                              variant="standard"
                              size="medium"
                              style={textFieldStyle}
                              InputProps={{ disableUnderline: true }}
                              fullWidth
                            />
                            <ErrorMessage
                              name="newPasswordConfirmed"
                              component="div"
                            />
                          </Grid>
                        </Grid>
                        <div style={{ marginTop: "20px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                            style={{
                              width: "50%",
                              backgroundColor: "   #1E1E1E",
                              color: "#F5F5F5",
                            }}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              "Update Password"
                            )}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid>

              <Typography variant="body1" style={{ marginTop: "3rem" }}>
                2 Factor Authentication
              </Typography>
              <small style={{ color: "#B3B3B3", marginBottom: "20px" }}>
                Enable to add an extra layer of security to your account
              </small>

              <Box display="flex" alignItems="center">
                <div style={{ flex: 1 }}>
                  <Box display="flex" alignItems="center">
                    <span>Email</span>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </div>
                {toggleState_two_factor_auth ? (
                  <ToggleOnIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_two_factor_auth,
                        toggleState_two_factor_auth,
                        "two_factor_auth"
                      )
                    }
                    sx={{ color: "#2A85FF", fontSize: 32 }}
                  />
                ) : (
                  <ToggleOffIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_two_factor_auth,
                        toggleState_two_factor_auth,
                        "two_factor_auth"
                      )
                    }
                    sx={{ color: "#fff", fontSize: 32 }}
                  />
                )}
              </Box>
              <div>
                <Divider sx={{ my: 4, borderBottom: "1px solid #B6B7B9" }} />
              </div>
              <Typography style={{ marginBottom: "30px" }}>Privacy </Typography>
              <Box display="flex" alignItems="center">
                <div style={{ flex: 1 }}>
                  <Box alignItems="center">
                    <Typography variant="body1" style={{ marginTop: "20px" }}>
                      Hide Balance
                    </Typography>
                    <small style={{ color: "#B3B3B3", margin: "1.5rem 0" }}>
                      Hides your portfolio and wallet balance
                    </small>
                  </Box>
                </div>
                {toggleState_hide_balance ? (
                  <ToggleOnIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_hide_balance,
                        toggleState_hide_balance,
                        "hide_balance"
                      )
                    }
                    sx={{ color: "#2A85FF", fontSize: 32 }}
                  />
                ) : (
                  <ToggleOffIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_hide_balance,
                        toggleState_hide_balance,
                        "hide_balance"
                      )
                    }
                    sx={{ color: "#fff", fontSize: 32 }}
                  />
                )}
              </Box>
              <div>
                <Divider sx={{ my: 4, borderBottom: "1px solid #B6B7B9" }} />
              </div>
              <Typography style={{ marginBottom: "30px" }}>
                Notification
              </Typography>

              <Box display="flex" alignItems="center">
                <div style={{ flex: 1 }}>
                  <Box display="flex" alignItems="center">
                    <span>Text Message</span>
                  </Box>
                </div>
                {toggleState_receive_text_notifications ? (
                  <ToggleOnIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_receive_text_notifications,
                        toggleState_receive_text_notifications,
                        "receive_text_notifications"
                      )
                    }
                    sx={{ color: "#2A85FF", fontSize: 32 }}
                  />
                ) : (
                  <ToggleOffIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_receive_text_notifications,
                        toggleState_receive_text_notifications,
                        "receive_text_notifications"
                      )
                    }
                    sx={{ color: "#fff", fontSize: 32 }}
                  />
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "50px" }}
              >
                <div style={{ flex: 1 }}>
                  <Box display="flex" alignItems="center">
                    <span>E-Mail</span>
                  </Box>
                </div>
                {toggleState_receive_email_notifications ? (
                  <ToggleOnIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_receive_email_notifications,
                        toggleState_receive_email_notifications,
                        "receive_email_notifications"
                      )
                    }
                    sx={{ color: "#2A85FF", fontSize: 32 }}
                  />
                ) : (
                  <ToggleOffIcon
                    onClick={() =>
                      handleToggle(
                        setToggleState_receive_email_notifications,
                        toggleState_receive_email_notifications,
                        "receive_email_notifications"
                      )
                    }
                    sx={{ color: "#fff", fontSize: 32 }}
                  />
                )}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default Settings;
