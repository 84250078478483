const Map = () => {
  return (
    <>
      <iframe
        title="uniqueId"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9517.520697978318!2d-2.5939998!3d53.3901389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b02392f9e7f79%3A0x9d9c13fca51394f8!2sThe%20Works!5e0!3m2!1sen!2sng!4v1686726832334!5m2!1sen!2sng"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default Map;
