import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';

const BookingManagement: React.FC<{ onSave: (payload: any) => void; onPrevious: () => void }> = ({ onSave, onPrevious }) => {
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [bookingCost, setBookingCost] = useState<string>(''); // State for booking cost input
  const [isAvailable, setIsAvailable] = useState<boolean>(false); // State for availability toggle

  const amenities = ['Free WiFi', 'Fully Furnished', 'Nearby City', 'Free Parking on Premises'];

  const toggleAmenity = (amenity: string) => {
    setSelectedAmenities((prev) =>
      prev.includes(amenity) ? prev.filter((item) => item !== amenity) : [...prev, amenity]
    );
  };

  const toggleAvailability = () => {
    setIsAvailable((prev) => !prev);
  };

  // Effect to send the payload whenever a change occurs
  useEffect(() => {
    const payload = {
      selectedAmenities,
      bookingCost,
      isAvailable,
    };

    onSave(payload); // Call onSave with the updated data
  }, [selectedAmenities, bookingCost, isAvailable, onSave]); // Only re-run if these values change

  return (
    <div className="px-[50px] py-[20px]">
      <label htmlFor="">Amenities</label>
      <div className="flex flex-row flex-wrap gap-4 mt-4">
        {amenities.map((amenity) => (
          <div
            key={amenity}
            className={`flex items-center gap-2 p-2 rounded-md cursor-pointer`}
            onClick={() => toggleAmenity(amenity)}
          >
            <div
              className={`w-4 h-4 border rounded-sm ${
                selectedAmenities.includes(amenity) ? 'bg-[#DEA838]' : 'bg-white'
              }`}
            />
            <span>{amenity}</span>
          </div>
        ))}
      </div>

      <div className="flex items-center gap-5 mt-4">
        <div>
          <label htmlFor="bookingCost">Bookings cost/night</label>
          <div className="flex items-center mt-2">
            <span className="text-gray-600 p-2 bg-[#242424] rounded-l-md">$</span>
            <input
              id="bookingCost"
              type="tel"
              value={bookingCost}
              onChange={(e) => setBookingCost(e.target.value)}
              className="p-2 w-full bg-[#242424] outline-none rounded-r-md"
              placeholder="Enter amount"
            />
          </div>
        </div>
        <div>
          <label htmlFor="">Availability</label>
          <div className="flex items-center gap-2 mt-2">
            <div
              onClick={toggleAvailability}
              className={`w-4 h-4 border cursor-pointer rounded-[2px] flex justify-center items-center ${
                isAvailable ? 'bg-[#DEA838]' : 'bg-white'
              }`}
            >
              {isAvailable && <div className="w-3 h-3 bg-[#DEA838] rounded-full" />}
            </div>
            <span>Available for booking</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingManagement;
