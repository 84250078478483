import React, { useState } from 'react';
import { FaChevronDown, FaCalendarAlt } from 'react-icons/fa';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AdminWrapper from '../../components/AdminWrapper';
import CongratulationsModal from './Modal';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const PortfolioForm: React.FC = () => {
    const [activePortfolio, setActivePortfolio] = useState('');
    const [property, setProperty] = useState('');
    const [currency, setCurrency] = useState('');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [amount, setAmount] = useState<number | null>(null);
    const [note, setNote] = useState('');
  
    const [errors, setErrors] = useState({
      activePortfolio: '',
      property: '',
      currency: '',
      amount: '',
      selectedDate: '',
      note: '',
    });
  
    const [activeDropdown, setActiveDropdown] = useState(false);
    const [propertyDropdown, setPropertyDropdown] = useState(false);
    const [currencyDropdown, setCurrencyDropdown] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleDropdown = (dropdownType: string) => {
      if (dropdownType === 'active') setActiveDropdown(!activeDropdown);
      if (dropdownType === 'property') setPropertyDropdown(!propertyDropdown);
      if (dropdownType === 'currency') setCurrencyDropdown(!currencyDropdown);
    };
  
    const selectOption = (dropdownType: string, value: string) => {
      if (dropdownType === 'active') {
        setActivePortfolio(value);
        setActiveDropdown(false);
      }
      if (dropdownType === 'property') {
        setProperty(value);
        setPropertyDropdown(false);
      }
      if (dropdownType === 'currency') {
        setCurrency(value);
        setCurrencyDropdown(false);
      }
    };
  
    const focusDatePicker = () => {
      const dateInput = document.querySelector('.react-datepicker__input-container input') as HTMLInputElement;
      if (dateInput) {
        dateInput.focus();
      }
    };
  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      let newErrors = {
        activePortfolio: '',
        property: '',
        currency: '',
        amount: '',
        selectedDate: '',
        note: '',
      };
      let isValid = true;
  
      if (!activePortfolio) {
        newErrors.activePortfolio = 'Please select a portfolio';
        isValid = false;
      }
      if (!property) {
        newErrors.property = 'Please select a property';
        isValid = false;
      }
      if (!currency) {
        newErrors.currency = 'Please select a currency';
        isValid = false;
      }
      if (!amount) {
        newErrors.amount = 'Please enter an amount';
        isValid = false;
      }
      if (!selectedDate) {
        newErrors.selectedDate = 'Please select a date';
        isValid = false;
      }
      if (!note.trim()) {
        newErrors.note = 'Please enter a note';
        isValid = false;
      }
  
      setErrors(newErrors);
  
      // Clear error messages after 2 seconds
      setTimeout(() => {
        setErrors({
          activePortfolio: '',
          property: '',
          currency: '',
          amount: '',
          selectedDate: '',
          note: '',
        });
      }, 2000);
  
      if (isValid) {
        // Submit form data
        console.log('Form submitted successfully');
        setIsModalOpen(true);
      }
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
      };
      const navigate = useNavigate();
  return (
    <AdminWrapper>
      <div>
      <p className='flex items-center text-[14px] cursor-pointer font-jakarta rounded-[5px] p-2 w-[100px] gap-3' onClick={() => navigate('/admin/roipayroll')}> <IoIosArrowBack />
      Back</p>
      </div>
      <div className="w-[90%] mt-4 font-jakarta">
        <form onSubmit={handleSubmit}>
          <div className="flex space-x-4 mb-4">
            <div className="w-[40%] relative">
              <label className="block text-sm font-medium text-white">Active Portfolio</label>
              <div className="relative">
                <input
                  type="text"
                  value={activePortfolio}
                  className="mt-1 block w-full bg-[#1B1818] px-3 py-2 border border-[#33383F] rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  readOnly
                />
                <FaChevronDown
                  className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                  onClick={() => toggleDropdown('active')}
                />
              </div>
              {activeDropdown && (
                <div className="absolute z-10 mt-1 w-full bg-[#1B1818] rounded-md shadow-lg">
                  <ul className="py-1">
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-[#DEA838] hover:text-white"
                      onClick={() => selectOption('active', 'Option 1')}
                    >
                      Option 1
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-[#DEA838] hover:text-white"
                      onClick={() => selectOption('active', 'Option 2')}
                    >
                      Option 2
                    </li>
                  </ul>
                </div>
              )}
              {errors.activePortfolio && <p className="text-red-500 text-sm mt-1">{errors.activePortfolio}</p>}
            </div>

            <div className="w-[60%] relative font-jakarta">
              <label className="block text-sm font-medium text-white">Property</label>
              <div className="relative">
                <input
                  type="text"
                  value={property}
                  className="mt-1 block w-full bg-[#1B1818] px-3 py-2 border border-[#33383F] rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  readOnly
                />
                <FaChevronDown
                  className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                  onClick={() => toggleDropdown('property')}
                />
              </div>
              {propertyDropdown && (
                <div className="absolute z-10 mt-1 w-full bg-[#1B1818] rounded-md shadow-lg">
                  <ul className="py-1">
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-[#DEA838] hover:text-white"
                      onClick={() => selectOption('property', 'Property 1')}
                    >
                      Property 1
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-[#DEA838] hover:text-white"
                      onClick={() => selectOption('property', 'Property 2')}
                    >
                      Property 2
                    </li>
                  </ul>
                </div>
              )}
              {errors.property && <p className="text-red-500 text-sm mt-1">{errors.property}</p>}
            </div>
          </div>

          {/* Currency, Amount, and Month Input */}
          <div className="flex space-x-4 mb-4 font-jakarta">
            <div className="w-1/3 relative">
              <label className="block text-sm font-medium text-white">Currency</label>
              <div className="relative">
                <input
                  type="text"
                  value={currency}
                  className="mt-1 block w-full bg-[#1B1818] px-3 py-2 border border-[#33383F] rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  readOnly
                />
                <FaChevronDown
                  className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                  onClick={() => toggleDropdown('currency')}
                />
              </div>
              {currencyDropdown && (
                <div className="absolute z-10 mt-1 w-full bg-[#1B1818] rounded-md shadow-lg">
                  <ul className="py-1">
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-[#DEA838] hover:text-white"
                      onClick={() => selectOption('currency', 'USD')}
                    >
                      USD
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-[#DEA838] hover:text-white"
                      onClick={() => selectOption('currency', 'EUR')}
                    >
                      EUR
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-[#DEA838] hover:text-white"
                      onClick={() => selectOption('currency', 'GDP')}
                    >
                      GDP
                    </li>
                  </ul>
                </div>
              )}
              {errors.currency && <p className="text-red-500 text-sm mt-1">{errors.currency}</p>}
            </div>

            <div className="w-1/3">
              <label className="block text-sm font-medium text-white">Amount</label>
              <input
                type="number"
                value={amount || ''}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
                className="mt-1 block w-full px-3 py-2 border bg-[#1B1818] border-[#33383F] rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {errors.amount && <p className="text-red-500 text-sm mt-1">{errors.amount}</p>}
            </div>

            <div className="w-1/3 font-jakarta">
              <label className="block text-sm font-medium text-white">Month</label>
              <div className="flex items-center justify-between mt-1 border bg-[#1B1818] border-[#33383F] rounded-md shadow-sm">
                <ReactDatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  className="w-full bg-[#1B1818] text-white px-3 py-2 focus:outline-none"
                />
                <FaCalendarAlt className="text-gray-400 mr-2" onClick={focusDatePicker} />
              </div>
              {errors.selectedDate && <p className="text-red-500 text-sm mt-1">{errors.selectedDate}</p>}
            </div>
          </div>

          {/* Note Input */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-white">Note</label>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border bg-[#1B1818] border-[#33383F] rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.note && <p className="text-red-500 text-sm mt-1">{errors.note}</p>}
          </div>

          {/* Submit Button */}
          <div className='flex justify-end items-end'>
            <button
              type="submit"
              className=" bg-[#DEA838] hover:bg-[#DEA838] text-black font-bold py-2 px-7 rounded focus:outline-none focus:shadow-outline"
            >
              Done
            </button>
          </div>
        </form>
      </div>
      <CongratulationsModal isOpen={isModalOpen} onClose={closeModal} />
    </AdminWrapper>
  );
};

export default PortfolioForm;
