import React from 'react';
import { authImage, logo } from "../../assets";

const SideInfo = () => {
  return (
    <div className="w-full relative min-h-screen">
     
     
      <div className="absolute top-0 left-0 w-full h-full z-0">
        <img
          src={authImage}
          alt=""
          className="absolute top-0 left-0 w-full h-full"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"></div>
      </div>
    </div>
  );
};

export default SideInfo;
