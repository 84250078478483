// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Quill Styles */
.custom-quill .ql-toolbar {
    background-color: #000000; /* White background */
    border: 1px solid #d1d5db; /* Light gray border */
    color: #fff;
}

.custom-quill .ql-toolbar button {
    color: #000000; /* Black icons */
}

.custom-quill .ql-toolbar button:hover {
    color: #1d4ed8; /* Blue on hover */
}

.custom-quill .ql-container {
    background-color: #000000; /* White editor background */
    color: #fff; /* Black text color */
    border: 1px solid #d1d5db; /* Light gray border */
    border-top: none; /* Remove top border */
}

.custom-quill .ql-container.ql-snow {
    font-family: Arial, sans-serif; /* Optional font customization */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Support/index.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,yBAAyB,EAAE,qBAAqB;IAChD,yBAAyB,EAAE,sBAAsB;IACjD,WAAW;AACf;;AAEA;IACI,cAAc,EAAE,gBAAgB;AACpC;;AAEA;IACI,cAAc,EAAE,kBAAkB;AACtC;;AAEA;IACI,yBAAyB,EAAE,4BAA4B;IACvD,WAAW,EAAE,qBAAqB;IAClC,yBAAyB,EAAE,sBAAsB;IACjD,gBAAgB,EAAE,sBAAsB;AAC5C;;AAEA;IACI,8BAA8B,EAAE,gCAAgC;AACpE","sourcesContent":["/* Custom Quill Styles */\n.custom-quill .ql-toolbar {\n    background-color: #000000; /* White background */\n    border: 1px solid #d1d5db; /* Light gray border */\n    color: #fff;\n}\n\n.custom-quill .ql-toolbar button {\n    color: #000000; /* Black icons */\n}\n\n.custom-quill .ql-toolbar button:hover {\n    color: #1d4ed8; /* Blue on hover */\n}\n\n.custom-quill .ql-container {\n    background-color: #000000; /* White editor background */\n    color: #fff; /* Black text color */\n    border: 1px solid #d1d5db; /* Light gray border */\n    border-top: none; /* Remove top border */\n}\n\n.custom-quill .ql-container.ql-snow {\n    font-family: Arial, sans-serif; /* Optional font customization */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
