import React, { useState } from "react";
import { Link } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { EditOutlined, RemoveRedEyeOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function GUserTable(props: any) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set default rows per page to 10
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userToDeleteId, setUserToDeleteId] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const deleteUserFromDb = async () => {
    try {
      await userRequest.delete(`/admin/users/delete/${userToDeleteId}`);
      toast.success("User successfully deleted", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
      });
    } catch (err) {
      toast.error("Error occurred while deleting user", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
    setShowDeleteDialog(false);
    props.setTriggerReload(!props.triggerReload);
  };

  const handleDelete = (user: any) => {
    setShowDeleteDialog(true);
    setUserToDeleteId(user);
  };

  const handleClose = () => {
    setShowDeleteDialog(false);
  };

  const handleHeaderCheckboxChange = () => {
    if (selectedRows.length === props.usersData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(props.usersData.map((user: any) => user.id));
    }
  };

  const handleRowCheckboxChange = (id: number) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const filteredData = props.usersData.filter((row: any) => row.user_type === "Guest");
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index);

  const startItemIndex = page * rowsPerPage + 1;
  const endItemIndex = Math.min((page + 1) * rowsPerPage, filteredData.length);

  return (
    <div className="w-full overflow-hidden bg-transparent font-jakarta rounded-lg border border-[#6F767E]">
      <Dialog open={showDeleteDialog} onClose={handleClose}>
        <DialogTitle>Delete User?</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this user?</p>
        </DialogContent>
        <DialogActions>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#DEA838] text-black rounded hover:bg-red-600"
            onClick={deleteUserFromDb}
          >
            DELETE
          </button>
        </DialogActions>
      </Dialog>
        <div className="overflow-x-auto font-jakarta">
      <table className="min-w-full divide-y divide-[#353945]">
        <thead className="bg-[#242424] text-white">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider border-b-10 border-[#6F767E]">
              <div
                className={`w-5 h-5 border-2 rounded-full flex items-center justify-center cursor-pointer ${
                  selectedRows.length === filteredData.length ? "bg-yellow-500" : "border-gray-500"
                }`}
                onClick={handleHeaderCheckboxChange}
              >
                {selectedRows.length === filteredData.length && (
                  <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                )}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-[14px] font-medium uppercase tracking-wider border-b-10 border-[#6F767E]">Name</th>
            <th className="px-6 py-3 text-left text-[14px] font-medium uppercase tracking-wider border-b-10 border-[#6F767E]">Country</th>
            <th className="px-6 py-3 text-left text-[14px] font-medium uppercase tracking-wider border-b-10 border-[#6F767E]">Phone no</th>
            <th className="px-6 py-3 text-left text-[14px] font-medium uppercase tracking-wider border-b-10 border-[#6F767E]">Date Joined</th>
            <th className="px-6 py-3 border-b-10 border-[#6F767E]"></th>
          </tr>
        </thead>
        <tbody className="bg-transparent divide-y divide-[#6F767E]">
          {filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row: any) => {
            let statusStyle = "";

            if (row.status === "Active") {
              statusStyle = "bg-[#30FF51] bg-opacity-30 text-[#30FF51]";
            } else if (row.status === "Declined") {
              statusStyle = "bg-[#FF5F00] bg-opacity-30 text-[#FF5F00]";
            } else if (row.status === "Pending") {
              statusStyle = "bg-[#ECBA2F] bg-opacity-30 text-[#ECBA2F]";
            }

            return (
              <tr key={row.id}>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] border-b-10 border-[#6F767E]">
                  <div
                    className={`w-5 h-5 border-2 rounded-full flex items-center justify-center cursor-pointer ${
                      selectedRows.includes(row.id) ? "bg-yellow-500" : "border-gray-500"
                    }`}
                    onClick={() => handleRowCheckboxChange(row.id)}
                  >
                    {selectedRows.includes(row.id) && (
                      <div className="w-3 h-3 bg-yellow-500 rounded-[10px]"></div>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap flex gap-2 items-center border-b-10 border-[#6F767E]">
                  <img src={row?.profile_pic} className="w-[40px] h-[40px]" alt="profile" />
                  <div className="flex flex-col text-[14px]">
                    {row?.first_name + " " + row?.last_name}
                    <p>{row?.email}</p>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] border-b-10 border-[#6F767E]">
                  {row?.country}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] border-b-10 border-[#6F767E]">
                 {row?.phone_no}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] border-b-10 border-[#6F767E]">
                  {row?.date_joined}
                </td>
               
                <td className="px-6 py-4 whitespace-nowrap text-[14px] border-b-10 border-[#6F767E]">
                  <div className="flex items-center space-x-4">
                    <Link to={`/admin/users/${row.id}`}>
                      <EditOutlined className="text-[#FCFCFC] cursor-pointer text-[14px]" />
                    </Link>
                    <RemoveRedEyeOutlined className="text-[#FCFCFC] cursor-pointer text-[14px]" />
                    <DeleteOutlineOutlined
                      className="text-[#FC8A8A] cursor-pointer text-[14px]"
                      onClick={() => handleDelete(row.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

        </div>
      <div className="flex justify-between items-center p-4 bg-transparent text-[#6F767E] border-t border-[#6F767E]">
        <div>
          <span className="text-[14px]">
            Showing {startItemIndex} to {endItemIndex} of {filteredData.length} users
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => page > 0 && setPage(page - 1)}
            className="p-2 rounded-full text-[#DEA838] bg-[#353945] disabled:opacity-50"
            disabled={page === 0}
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              className={`px-3 py-1 rounded-full ${
                page === pageNumber ? "bg-[#DEA838]" : "bg-[#353945]"
              }`}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button
            onClick={() => page < totalPages - 1 && setPage(page + 1)}
            className="p-2 rounded-full text-[#DEA838] bg-[#353945] disabled:opacity-50"
            disabled={page >= totalPages - 1}
          >
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}
