import { useState } from "react";
import TicketDetail from "./TicketDetail";
import Message from "./Message";

export default function SupportDetails({ selectedBooking }: { selectedBooking: any }) {
    const [activeButton, setActiveButton] = useState<string>("ticket");

    const handleClick = (buttonName: string) => {
        setActiveButton(buttonName);
    };

    // Determine the height based on the active button
    const containerStyle = {
        height: activeButton === "message" ? "80vh" : "auto",
    };

    return (
        <div style={containerStyle}>
            <p className="text-[25px]">Ticket Detail</p>
            <div className="flex space-x-4">
                <button
                    onClick={() => handleClick("ticket")}
                    className={`p-2 text-[14px] font-jakarta border-b-4 ${
                        activeButton === "ticket" ? "border-white" : "border-transparent"
                    } bg-transparent text-white`}
                >
                    Ticket Detail
                </button>
                <button
                    onClick={() => handleClick("message")}
                    className={`p-2 text-[14px] font-jakarta border-b-4 ${
                        activeButton === "message" ? "border-white" : "border-transparent"
                    } bg-transparent text-white`}
                >
                    Send Message
                </button>
            </div>
            <div className="mt-4">
                {activeButton === "ticket" && <TicketDetail booking={selectedBooking} />}
                {activeButton === "message" && <Message booking={selectedBooking} />}
            </div>
        </div>
    );
}
