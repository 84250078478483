import React, { useEffect, useState } from 'react';
import TopBar from './topNav';
import Cookies from 'js-cookie';
import { IoMdMenu, IoMdClose } from 'react-icons/io'; // Import the close icon
import SideBar from './adminSide';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
  const [userData, setUserData] = useState<any>({});
  const [route, setRoute] = useState<string>('');
  const [activeLink, setActiveLink] = useState<string>('overview');
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false); // Sidebar toggle state for mobile

  useEffect(() => {
    // Fetch userData from cookies
    try {
      const res = Cookies.get('userData');
      if (res) {
        setUserData(JSON.parse(res));
      }
    } catch (err) {
      console.error('Failed to parse userData cookie:', err);
    }
  }, []);

  useEffect(() => {
    // Fetch saved route from localStorage
    const savedRoute = localStorage.getItem('selectedRoute');
    setRoute(savedRoute || '');
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); // Toggle the sidebar
  };

  return (
    <>
      <div className="flex font-sans h-screen overflow-hidden">
        {/* Sidebar for both desktop and mobile */}
        <div
          className={`fixed top-0 left-0 h-full w-[200px] lg:translate-x-0 bg-gray-100 shadow-lg transition-transform transform ${
            sidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } lg:block lg:w-[300px] lg:static z-50`} // Sidebar is hidden on mobile by default and shown on lg screens
        >
          <SideBar setActiveLink={setActiveLink} />
        </div>

        {/* Main Content */}
        <div className="flex-1 ml-0 overflow-y-auto relative">
          {/* TopBar */}
          <div className="fixed top-0 lg:left-[300px] left-0 lg:w-[calc(100%-300px)] w-full z-[1000] bg-white">
            {/* Mobile Menu Icon to toggle sidebar */}
            {sidebarOpen ? (
              <IoMdClose
              
                className="absolute lg:hidden top-7 right-5 text-white cursor-pointer"
                onClick={toggleSidebar} // Close sidebar
              />
            ) : (
              <IoMdMenu
                
                className="absolute lg:hidden top-7 right-5 text-white cursor-pointer"
                onClick={toggleSidebar} // Open sidebar
              />
            )}
            <TopBar activeLink={activeLink} />
          </div>

          {/* Overlay for mobile to close sidebar when clicked outside */}
          {sidebarOpen && (
            <div
              className="fixed inset-0 bg-black opacity-50 z-40 lg:hidden"
              onClick={toggleSidebar} // Clicking outside sidebar closes it
            ></div>
          )}

          {/* Page Content */}
          <div className="lg:pt-[130px] pt-[100px] lg:px-[50px] px-2">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Wrapper;
