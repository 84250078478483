import { useState } from "react";
import { FiMoreVertical } from "react-icons/fi"; // Import the vertical three-dots icon
import UpdateBooking from "./UpdateBooking";
import { FaTimes } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

// Dummy data for the table
const dummyBookings = [
  {
    id: 1,
    user: "John Doe",
    bankName: "Bank of America",
    accountNo: "1234567890",
    amount: "£1,000",
    date: "2024-08-15",
    status: "Approved",
  },
  {
    id: 2,
    user: "Jane Smith",
    bankName: "Chase Bank",
    accountNo: "0987654321",
    amount: "£500",
    date: "2024-08-12",
    status: "Pending",
  },
  {
    id: 3,
    user: "Alice Johnson",
    bankName: "Wells Fargo",
    accountNo: "1122334455",
    amount: "£750",
    date: "2024-08-10",
    status: "Decline",
  },
  {
    id: 4,
    user: "Bob Brown",
    bankName: "Citi Bank",
    accountNo: "6677889900",
    amount: "£1,250",
    date: "2024-08-08",
    status: "Approved",
  },
  {
    id: 5,
    user: "Eve Davis",
    bankName: "US Bank",
    accountNo: "5566778899",
    amount: "£900",
    date: "2024-08-06",
    status: "Pending",
  },
];

export default function BookingsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set initial rows per page to 5
  const [popupVisible, setPopupVisible] = useState(false); // State to manage popup visibility
  const [selectedBooking, setSelectedBooking] = useState<any>(null); // State to manage the selected booking for the popup

  const totalPages = Math.ceil(dummyBookings.length / rowsPerPage);

  const handleChangePage = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const togglePopup = (booking: any) => {
    setSelectedBooking(booking); // Set the selected booking
    setPopupVisible(!popupVisible); // Toggle the popup visibility
  };

  // Calculate the starting and ending index of the items being displayed
  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min((page + 1) * rowsPerPage, dummyBookings.length);

  // Function to determine the background color class based on the status
  const getStatusStyles = (status: string) => {
    switch (status) {
      case "Approved":
        return "bg-[#30FF51] bg-opacity-30 text-[#30FF51]";
      case "Decline":
        return "bg-[#FF5F00] bg-opacity-30 text-[#FF5F00]";
      case "Pending":
      default:
        return "bg-[#ECBA2F] bg-opacity-30 text-[#ECBA2F]";
    }
  };

  return (
    <div className="w-full overflow-hidden bg-black shadow-md rounded-lg">
        <div className="flex items-center justify-between px-4 py-2 bg-black">
        <div>
          <span className="font-jakarta">
            Showing {startIndex}-{endIndex} of {dummyBookings.length}
          </span>
        </div>
        <div className="text-[#DEA838] font-jakarta border border-[#DEA838] px-6 py-2 cursor-pointer rounded-[10px]">
        Export
        </div>
       
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full rounded-lg bg-[#242424] border border-[#353945]">
          <thead className="">
            <tr className="bg-transparent h-12">
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">User</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Bank Name</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Account No</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Amount</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Date</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Status</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]"></th>
            </tr>
          </thead>
          <tbody>
            {dummyBookings
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => (
                <tr
                onClick={() => togglePopup(row)}
                  key={row.id}
                  className={`${
                    index % 2 === 0 ? "bg-[#242424]" : "bg-[#242424]"
                  } h-10 cursor-pointer`}
                >
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">{row.user}</td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">{row.bankName}</td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">{row.accountNo}</td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">{row.amount}</td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                    {row.date.replaceAll("-", "/")}
                  </td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                  <span
                    className={`px-3 py-1 font-jakarta rounded-full ${getStatusStyles(
                      row.status
                    )}`}
                  >
                    {row.status}
                  </span>
                </td>
                 
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    

      {/* Popup */}
      {popupVisible && (
        <>
          <div
            className="fixed inset-0  bg-gray-500 opacity-20 z-10"
            onClick={() => setPopupVisible(false)} // Close popup when clicking outside
          />
          <div className="absolute top-[100px] right-0 w-full z-20 flex justify-end">
            <div className="bg-[#06080A] w-[40%] border-l border-[#B6B7B9] h-full p-4 rounded shadow-lg">
              <div className="flex justify-end cursor-pointer">
                <FaTimes onClick={() => setPopupVisible(false)} />
              </div>
              <UpdateBooking />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
