import React from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

interface RowData {
  user: string;
  reference: string;
  amount: string;
  date: string;
  status: string;
}

function createData(user: string, reference: string, amount: string, date: string, status: string): RowData {
  return { user, reference, amount, date, status };
}

const rows: RowData[] = [
  createData('12345678909876', '£200,000', 'pending', '01/04/2023', 'pend'),
  createData('12345678909876', '£200,000', 'pending', '01/04/2023', 'pending'),
  createData('12345678909876', '£200,000', 'pending', '01/04/2023', 'pending'),
  createData('12345678909876', '£200,000', 'pending', '01/04/2023', 'pending'),
  createData('12345678909876', '£200,000', 'pending', '01/04/2023', 'pending'),
  createData('12345678909876', '£200,000', 'pending', '01/04/2023', 'pending'),
];

export default function Payroll() {
  return (
    <div className="bg-[#1E1E1E] shadow rounded-lg p-6 font-jakarta">
      <div className="flex justify-between items-center mb-4">
        <span className="text-white text-lg font-medium">Payout</span>
        <button className="bg-[#323030] flex items-center gap-2 text-white py-2 px-4 rounded-[10px]">
          View all payout
          <MdOutlineKeyboardArrowRight />
        </button>
      </div>
      <div className="overflow-x-auto font-jakarta">
        <table className="min-w-full bg-[#1E1E1E]  rounded-lg">
          <thead>
            <tr className="bg-[#1E1E1E] border-b border-[#B3B3B3]">
              <th className="px-4 py-2 text-left text-[#B6B7B9]">Reference</th>
              <th className="px-4 py-2 text-left text-[#B6B7B9]">Amount</th>
              <th className="px-4 py-2 text-left text-[#B6B7B9]">Status</th>
              <th className="px-4 py-2 text-left text-[#B6B7B9]">Date</th>
              <th className="px-4 py-2 text-left text-[#B6B7B9]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index} className="border-b border-[#B3B3B3]">
                <td className="px-4 py-2 text-[#B6B7B9]">{row.user}</td>
                <td className="px-4 py-2 text-[#B6B7B9]">{row.reference}</td>
                <td className="px-4 py-2 text-[#B6B7B9]">{row.amount}</td>
                <td className="px-4 py-2 text-[#B6B7B9]">{row.date}</td>
                <td className="px-4 py-2 text-[#B6B7B9]">
                  <div className="flex space-x-2">
                    <button className="bg-[#DEA838] text-black py-0 px-3 rounded-[15px] hover:bg-[#DEA838]">
                      Approve
                    </button>
                    <button className="bg-transparent text-[#FCFCFC] border border-[#FCFCFC] py-0 px-3 rounded-[15px]">
                      Decline
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
