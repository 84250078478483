import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import AdminWrapper from "../../../components/AdminWrapper";
import { RiArrowDropDownLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";

interface User {
  id: string;
  first_name: string;
  email: string;
  phone_no: string;
  address: string;
  status: string;
  profile_pic: string;
  home_address: string;
  user_type: string;
  country: string;
}

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [formData, setFormData] = useState({
    phone_no: "",
    country: "",
    home_address: "",
  });

  useEffect(() => {
    const fetchDetails = async () => {
      const data = await userRequest(`admin/users`);
      const myResult: User[] = data.data.data.data;
      const filteredData = myResult.filter((res: User) => String(res.id) === String(id));
      const userData = filteredData[0] || null;
      setUser(userData);
      if (userData) {
        setStatus(userData.status || "");
        setFormData({
          phone_no: userData.phone_no || "",
          country: userData.country || "",
          home_address: userData.home_address || "",
        });
      }
    };
    fetchDetails();
  }, [id]);

  const goBackToPreviousPage = () => {
    navigate(-1);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleStatusChange = (newStatus: string) => {
    setStatus(newStatus);
    setDropdownOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (!user) return;
    const updatedData = { ...formData, status };
    try {
      await userRequest.post(`admin/users/update/${user.id}`, updatedData);
      toast.success("User details updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error updating user details:", error);
      toast.error("Failed to update user details.");
    }
  };

  return (
    <AdminWrapper>
       <div className="font-jakarta">
          <p className='flex items-center border text-[14px] cursor-pointer border-gray-200 rounded-[5px] p-2 w-[100px] gap-3' onClick={() => navigate('/admin/users')}> <IoIosArrowBack />
          Back</p>
          <p className='text-[25px] my-5'>
          Add New User
          </p>
        </div>
      <div className="gap-4 w-[70%] font-jakarta">
        <div className="flex items-center justify-between">
          <div className="mb-5 flex items-center gap-3">
            <img src={user?.profile_pic} alt="profile pic" width={100} className="rounded-full" />
            <div>
              <p>{user?.first_name || ""}</p>
              <p>{user?.email || ""}</p>
            </div>
          </div>
          <div className="relative">
            <label htmlFor="">KYC Status</label>
            <div
              className="cursor-pointer bg-[#1E1E1E] mt-3 flex items-center justify-between text-white py-2 px-4 rounded-lg"
              onClick={toggleDropdown}
            >
              <span>{status || "Select Status"}</span>
              <RiArrowDropDownLine />
            </div>
            {dropdownOpen && (
              <div className="absolute top-full left-0 bg-[#1E1E1E] border border-[#33383F] rounded-lg w-full z-10">
                {["Active", "Pending", "Decline"].map((option) => (
                  <div
                    key={option}
                    className={`p-2 hover:bg-[#33383F] cursor-pointer ${
                      status === option ? "bg-[#DEA838] text-black" : ""
                    }`}
                    onClick={() => handleStatusChange(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-5">
          <div className="w-full">
            <label htmlFor="phone_no" className="mb-2 block">Phone</label>
            <input
              id="phone_no"
              name="phone_no"
              type="text"
              className="text-sm mb-4 bg-[#1E1E1E] border border-[#33383F] rounded-lg w-full p-4"
              value={formData.phone_no}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <label htmlFor="country" className="mb-2 block">Nationality</label>
            <input
              id="country"
              name="country"
              type="text"
              className="text-sm mb-4 bg-[#1E1E1E] border border-[#33383F] rounded-lg w-full p-4"
              value={formData.country}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex items-center gap-5">
          <div className="w-full">
            <label htmlFor="home_address" className="mb-2 block">Address</label>
            <input
              id="home_address"
              name="home_address"
              type="text"
              className="text-sm mb-4 bg-[#1E1E1E] border border-[#33383F] rounded-lg w-full p-4"
              value={formData.home_address}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <div className="flex items-center mb-2">
              <label htmlFor="address" className="mr-1">Date Joined</label>
            </div>
            <input
              id="address"
              type="text"
              className="text-sm mb-4 bg-[#1E1E1E] border border-[#33383F] rounded-lg w-full p-4"
              value={user?.home_address || ""}
            />
          </div>
        </div>
        {
          user?.user_type === "Co-Owner" ? (<>
        <hr className="my-6 border-[#33383F]" />
        <label htmlFor="address" className="mr-1">KYC Data</label>
        <div className="flex items-center mt-3 gap-4">
          <div className="w-full flex items-center justify-between bg-[#333] p-2 rounded-[5px]">
            <div className="flex items-center gap-2">
            <img
              src={user?.profile_pic || ""}
              alt="Preview"
              className="rounded-lg h-[40px] w-[40px] border border-[#33383F]"
            />
  <p>Front</p>
            </div>
            <p  className="bg-black text-white rounded-lg py-2 px-4"
              onClick={() => window.open(user?.profile_pic, "_blank")}>View</p>
          </div>

          <div className="w-full flex items-center justify-between bg-[#333] p-2 rounded-[5px]">
            <div className="flex items-center gap-2">
            <img
              src={user?.profile_pic || ""}
              alt="Preview"
              className="rounded-lg h-[40px] w-[40px] border border-[#33383F]"
            />
  <p>Back</p>
            </div>
            <p  className="bg-black text-white rounded-lg py-2 px-4"
              onClick={() => window.open(user?.profile_pic, "_blank")}>View</p>
          </div>
         
        </div>
          
          </>):("")
        }
        <div className="mt-6">
          <div className="flex justify-between gap-4 items-center">
            <button
              className="border border-[#DEA838] text-[#DEA838] rounded-lg w-full py-4"
              onClick={goBackToPreviousPage}
            >
              Cancel
            </button>
            <button
              className="bg-[#DEA838] text-black rounded-lg w-full py-4 mr-2"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default EditUser;
