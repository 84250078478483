import React from 'react';

interface ProposProps {
  topLeftText: string;
  middleLeftText: string;
  bottomLeftText: string;
  icon: React.ReactNode;
}

const Propos: React.FC<ProposProps> = ({ topLeftText, middleLeftText, bottomLeftText, icon }) => {
  return (
    <div className="flex flex-col w-full bg-[#1E1E1E] items-start font-jakarta space-y-1 p-4 rounded-[10px]">
      <div className="flex flex-row items-center space-x-2">
        <span className="font-[500] font-jakarta text-[#B6B7B9] text-[16px]">{topLeftText}</span>
      </div>
      <div className="flex flex-row w-full items-center justify-between space-x-2">
        <span className="font-[500] font-jakarta text-[#B6B7B9] text-[22px]">{middleLeftText}</span>
        <div className="flex items-center space-x-1">
          {icon}
          <span className="text-green-500 font-jakarta">+80%</span>
        </div>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <span className="font-[500] text-[#B6B7B9] font-jakarta text-[14px]">{bottomLeftText}</span>
      </div>
    </div>
  );
};

export default Propos;
