// loginSlice.js
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  userData: Cookies.get('userData')
    ? JSON.stringify(Cookies.get('userData'))
    : null,
  error: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginUserSuccess: (state, action) => {
      state.userData = action.payload.userData;
      state.error = null;
    },
    loginUserFailure: (state, action) => {
      state.userData = null;
      state.error = action.payload;
    },
    logoutUser: (state) => {
      state.userData = null;
      state.error = null;
    },
  },
});

export const { loginUserSuccess, loginUserFailure, logoutUser } =
  loginSlice.actions;

export default loginSlice.reducer;
