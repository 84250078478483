import AdminWrapper from "../../components/AdminWrapper";
import SearchIcon from "@mui/icons-material/Search";
import PaymentTable from "./PaymentTable";
import { paymentData } from "./paymentData";
import SlotTable from "./SlotTable";
export default function SlotDetail (){
    return(
        <AdminWrapper>
            <p>Slot sold</p>
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center gap-2.5">
            <div className="relative">
              <input
                type="text"
                className="border rounded-lg border-[#353945] text-sm py-2 px-3 outline-none  bg-transparent"
                placeholder="Search"
              />
              <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600" />
            </div>
          
          </div>
          <div>
            <button className="border border-[#B3B3B3] font-jakarta text-gray-600 bg-[#1E1E1E] py-1 px-5 rounded-lg">
              Export
            </button>
          </div>
        </div>

        <div className="my-5">
          <SlotTable paymentData={paymentData} />
        </div>
      </AdminWrapper>
    )
}