import React, { useState } from "react";
import { toast } from "react-toastify";
import { userRequest } from "../../requestMethod";
import { AiOutlineArrowDown } from "react-icons/ai"; // Import the icon from react-icons

export default function UpdateBooking(props: any) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Pending");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const updateBookingsState = async (status: string) => {
    try {
      await userRequest.post("/admin/bookings/approve", {
        booking_id: props.userBooking.id,
        status,
      });
      toast.success(`User bookings have been ${status.toLowerCase()}`, {
        hideProgressBar: true,
        autoClose: 2000,
      });
      props.setTriggerReload();
      setTimeout(() => {
        props.showListingspage();
      }, 2000);
    } catch (err) {
      toast.error("An error occurred", {
        hideProgressBar: true,
        autoClose: 1000,
      });
    }
  };

  return (
    <div className="font-jakarta">
      <p className="text-white pb-4 text-[20px] font-jakarta">Booking Details</p>

      <div className="flex gap-2 p-4 rounded-[6px] bg-[#1E1E1E] border border-[#33383F]">
        <div>
          <img src="/assets/images/Ellipse 3.svg" alt="userimg" />
        </div>
        <div>
          <p>Kenneth Morgan</p>
          <p>kennmorg@gmail.com</p>
        </div>
      </div>
      <div className="p-4 rounded-[6px] mt-5 flex flex-col gap-3 bg-[#1E1E1E] border border-[#33383F]">
        <div className="flex justify-between">
          <div>
            <p className="text-[#6F767E]">PROPERTY NAME</p>
            <p>Studio Apartment</p>
          </div>
          <div>
            <p className="text-[#6F767E]">HABITABLE DAYS</p>
            <p>Nil</p>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <p className="text-[#6F767E]">CHECK-IN DATE</p>
            <p>01/04/2023</p>
          </div>
          <div>
            <p className="text-[#6F767E]">CHECK-OUT DATE</p>
            <p>01/04/2023</p>
          </div>
        </div>
        <div className="flex justify-start">
          <div>
            <p className="text-[#6F767E]">AMOUNT PAID</p>
            <p>£200,000</p>
          </div>
        </div>
      </div>
      <p className="mt-3">Payment proof</p>
    <div className="flex justify-between items-center bg-[#33383F] p-4 rounded-[6px]">
      <div className="flex gap-2">
        <div>
        <img src="/assets/images/bxs_image.svg" alt="userimage" />
        </div>
        <div>
          <p>imagess.jpg</p>
          <p>5.1mb</p>
        </div>
      </div>
      <div>
        <p className="bg-[#DEA838] text-black px-10 py-2 rounded-[6px] cursor-pointer">Download</p>
      </div>
    </div>
      <div className="relative mt-5 ">
        <label htmlFor="">Approval Status</label>
        <div
          className="bg-[#33383F] p-2 mt-1 flex justify-between rounded-[6px] items-center cursor-pointer"
          onClick={toggleDropdown}
        >
          <p>{selectedStatus}</p>
          <AiOutlineArrowDown /> {/* Use the icon here */}
        </div>
        {dropdownOpen && (
          <div className="absolute top-full left-0 w-full bg-[#33383F] z-10">
            <div
              className="p-2 hover:bg-[#444A53] cursor-pointer"
              onClick={() => {
                setSelectedStatus("Pending");
                setDropdownOpen(false);
              }}
            >
              Pending
            </div>
            <div
              className="p-2 hover:bg-[#444A53] cursor-pointer"
              onClick={() => {
                setSelectedStatus("Approved");
                setDropdownOpen(false);
              }}
            >
              Approved
            </div>
            <div
              className="p-2 hover:bg-[#444A53] cursor-pointer"
              onClick={() => {
                setSelectedStatus("Rejected");
                setDropdownOpen(false);
              }}
            >
              Rejected
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between mt-4">
        <button className="bg-transparent border border-[#DEA838] text-[#DEA838] px-10 py-2 rounded" onClick={() => updateBookingsState("Cancelled")}>
          Cancel
        </button>
        <button className="bg-[#DEA838] text-black px-10 py-2 rounded" onClick={() => updateBookingsState(selectedStatus)}>
          Save
        </button>
      </div>
    </div>
  );
}
