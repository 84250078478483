import * as React from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../../utils/helpers";
import { MdArrowUpward, MdMoreVert, MdArrowBack, MdArrowForward } from "react-icons/md";
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from "react-icons/md";


export default function InvestmentTable(props: any) {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 5; // Fixed to 5 items per page
  const [openRowId, setOpenRowId] = React.useState<string | null>(null);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleToggleMore = (id: string) => {
    setOpenRowId((prev) => (prev === id ? null : id));
  };

 

  const dataToDisplay =
    props.investmentsData && props.investmentsData.length > 0
      ? props.investmentsData
      : [];

  // Pagination logic
  const totalPages = Math.ceil(dataToDisplay.length / rowsPerPage);
  const currentPageData = dataToDisplay.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  return (
    <div className="w-full border border-[#353945] font-jakarta">
      <table className="w-full border-collapse rounded-lg">
        <thead className="bg-[#242424] text-[#9A9FA5]">
          <tr>
            <th className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] whitespace-nowrap">Name</th>
            <th className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] whitespace-nowrap">Property</th>
            <th className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] whitespace-nowrap">Amount</th>
            <th className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] whitespace-nowrap">Return on Investment</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row: any, index: number) => (
            <tr
              key={row.id}
              className={`h-16 border-b border-b-[#33383F] ${
                index % 2 === 0 ? "bg-transparent" : "bg-transparent"
              }`}
            >
              <td className="px-4 py-2 text-left text-white whitespace-nowrap">
                {row.user.first_name} {row.user.last_name}
              </td>
              <td className="px-4 py-2 text-left text-white whitespace-nowrap">
                {row.property}
              </td>
              <td className="px-4 py-2 text-left text-white whitespace-nowrap">
                {formatPrice(row.value)}
              </td>
              <td className="px-4 pt-5 flex justify-between items-center text-white whitespace-nowrap">
                <div className="flex items-center ">
                  <span className="mr-2">{row.roi}</span>
                  <MdArrowUpward className="mr-1 text-green-500" />
                  <span className="text-green-500">{row.roiPercentage}</span>
                </div>
                <div className="relative ml-4">
                  <MdMoreVert
                    className="text-white cursor-pointer"
                    onClick={() => handleToggleMore(row.id)}
                  />
                  {openRowId === row.id && (
                    <div
                      className="absolute right-10 w-34 top-0 z-100 p-2 bg-gray-800 border border-gray-600 rounded-lg flex flex-col items-center"
                      onClick={(e) => e.stopPropagation()} // Prevent popup from closing when clicking inside
                    >
                      <Link
                        to="/admin/investments/34"
                        className="text-white mb-2"
                      >
                        👁️
                      </Link>
                      <span className="cursor-pointer text-red-500">🗑️</span>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between p-2 items-center mt-4">
        <div className="text-gray-500">
          Showing {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, dataToDisplay.length)} of {dataToDisplay.length}
        </div>
        <div className="flex items-center">
          <button
            className="p-2 text-gray-500 hover:text-gray-700"
            onClick={() => handleChangePage(page > 0 ? page - 1 : 0)}
            disabled={page === 0}
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`mx-1 px-3 py-1 rounded-full ${
                page === index ? "bg-[#DEA838] text-white" : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => handleChangePage(index)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="p-2 text-gray-500 hover:text-gray-700"
            onClick={() => handleChangePage(page < totalPages - 1 ? page + 1 : totalPages - 1)}
            disabled={page === totalPages - 1}
          >
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}
