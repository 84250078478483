import { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, InputLabel } from "@mui/material";
import Wrapper from "../../components/Wrapper";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import ProfileNav from "./ProfileNav";
import TopBar from "../../components/topNav";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const textFieldStyle = {
  fontSize: "12px",
  backgroundColor: "#1E1E1E",
  borderRadius: "10px",
  marginTop: "5px",
  padding: "7px 15px",
  marginBottom: "20px",
};

const Profile = () => {
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    const res = Cookies.get("adminUserData_maihomm") || "";
    if (res) {
      setUserData(JSON.parse(res));
      // console.log(JSON.parse(res));
    }
  }, []);

  return (
    <Wrapper>
      <Box sx={{ width: "90%", height: "100%", px: "16px" }}>
        <Grid container>
          <ProfileNav />

          <Grid
            item
            xs={12}
            md={7}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <form style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <InputLabel htmlFor="name">Name</InputLabel>
                <InfoIcon style={{ marginLeft: "5px" }} />
              </div>
              <TextField
                id="name"
                type="text"
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={textFieldStyle}
                value={
                  userData && userData.first_name + " " + userData.last_name
                }
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <InputLabel htmlFor="email">Email</InputLabel>
                <InfoIcon style={{ marginLeft: "5px" }} />
              </div>
              <TextField
                id="email"
                type="text"
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                style={textFieldStyle}
                value={userData && userData.email}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <InputLabel htmlFor="phonenumber">Phone Number</InputLabel>
                <InfoIcon style={{ marginLeft: "5px" }} />
              </div>
              <TextField
                id="phonenumber"
                type="number"
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                style={textFieldStyle}
                value={userData && userData.phone_no}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                <span>Need help updating information?</span>
                <Link to="/support">
                  <Button
                    style={{
                      backgroundColor: "#272B30",
                      color: "#FCFCFC",
                      border: "2px solid #272B30",
                      borderRadius: "10px",
                    }}
                    startIcon={<HelpIcon />}
                  >
                    Support
                  </Button>
                </Link>
              </div>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default Profile;
