import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import default Quill styles
import './index.css'; // Import your custom styles

export default function Message({ booking }: { booking: any }) {
    const [message, setMessage] = useState<string>('');
    const [subject, setSubject] = useState<string>('');

    const handleChange = (value: string) => {
        setMessage(value);
    };

    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value);
    };

    const handleSubmit = async () => {
        const payload = {
            subject,
            message,
        };

        try {
            const response = await fetch('/api/messages', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            console.log('Message sent successfully');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <div>
                <p className='font-jakarta'>Subject</p>
                <input
                    type="text"
                    value={subject}
                    onChange={handleSubjectChange}
                    placeholder="The Subject"
                    className="bg-[#515151] border border-[#515151] p-3 rounded-[10px] w-full outline-none "
                />
            </div>
            <div className="mt-4">
                <p className='font-jakarta'>Message</p>
                <ReactQuill
                    value={message}
                    onChange={handleChange}
                    placeholder="Write your message here..."
                    className="bg-[#515151] border border-[#515151] rounded-[10px] custom-quill"
                />
            </div>
            <div className="mt-4 flex justify-end">
                <button
                    onClick={handleSubmit}
                    className="bg-[#DEA838] font-jakarta text-black px-4 py-2 rounded-[10px] hover:bg-blue-600"
                >
                    Send
                </button>
            </div>
        </div>
    );
}
