import { useState, useEffect } from 'react';
import AdminWrapper from '../../../components/AdminWrapper';
import { FaChevronDown } from 'react-icons/fa';
import { IoIosArrowBack, IoMdInformationCircleOutline } from 'react-icons/io';
import { userRequest } from '../../../requestMethod';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddNew = () => {
  const [status, setStatus] = useState<string>(''); // Initially empty
  const [dropdownOpen, setDropdownOpen] = useState(false); // To toggle the dropdown
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({}); // For error messages

  const statuses = ['Unverified', 'Verified', 'Pending', 'Active', 'Inactive'];

  const handleSelect = (value: string) => {
    setStatus(value);
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);

      // Create a preview URL using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    }

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required';
    }

    if (!status) {
      newErrors.status = 'Status is required';
    }

    if (!file) {
      newErrors.file = 'Profile picture is required';
    }

    setErrors(newErrors);

    // Set a timeout to clear the errors after 2 seconds
    if (Object.keys(newErrors).length > 0) {
      setTimeout(() => {
        setErrors({});
      }, 2000);
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm()) {
      // Form data preparation
      const payload = {
        first_name: name,
        last_name: name,
        phone_no: phoneNumber,
        email: email,
        file: file?.name,
        status: status
      }
      console.log(payload, "user payload");
      try {
        await userRequest.post(`admin/users`, payload);
        toast.success("User details updated successfully!");
      } catch (error) {
        console.error("Error updating user details:", error);
        toast.error("Failed to update user details.");
      }
    }
  };

  const navigate = useNavigate();

  return (
    <AdminWrapper>
      <form onSubmit={handleSubmit} className='font-jakarta'>
        <div>
          <p className='flex items-center text-[14px] cursor-pointer rounded-[5px] p-2 w-[100px] gap-3' onClick={() => navigate('/admin/users')}> <IoIosArrowBack />
          Back</p>
          <p className='text-[25px] my-5'>
          Add New User
          </p>
        </div>
        <div className="flex flex-wrap gap-4 font-jakarta">
          <div className="lg:flex-1 flex-col w-full lg:min-w-[45%]">
            <label htmlFor="name" className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-300">
              Name
              <IoMdInformationCircleOutline />
            </label>
            <input
              id="name"
              type="text"
              className="w-full px-3 py-4 text-sm bg-[#1E1E1E] text-white rounded-md"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
          </div>

          <div className="flex-1 min-w-[45%]">
            <label htmlFor="email" className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-300">
              Email
              <IoMdInformationCircleOutline />
            </label>
            <input
              id="email"
              type="text"
              className="w-full px-3 py-4 text-sm bg-[#1E1E1E] text-white rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>

          <div className="flex-1 min-w-[45%]">
            <label htmlFor="phonenumber" className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-300">
              Phone Number
              <IoMdInformationCircleOutline />
            </label>
            <input
              id="phonenumber"
              type="number"
              className="w-full px-3 py-4 text-sm bg-[#1E1E1E] text-white rounded-md"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
          </div>

          <div className="flex-1 min-w-[35%] relative">
            <label htmlFor="status" className=" items-center gap-2 mb-2 flex text-sm font-medium text-gray-300">
              Status
              <IoMdInformationCircleOutline />
            </label>
            <div
              className="w-[35%] px-3 py-4 text-sm bg-[#1E1E1E] text-white rounded-md cursor-pointer flex justify-between items-center"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {status || 'Select Status'}
              <FaChevronDown className={`transition-transform ${dropdownOpen ? 'rotate-180' : ''}`} />
            </div>
            {dropdownOpen && (
              <div className="absolute top-full left-0 w-full bg-[#1E1E1E] text-white rounded-md mt-1 shadow-lg z-10">
                {statuses.map((item) => (
                  <div
                    key={item}
                    className="px-3 py-2 hover:bg-[#333] cursor-pointer"
                    onClick={() => handleSelect(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
            {errors.status && <p className="text-red-500 text-sm mt-1">{errors.status}</p>}
          </div>

          <div className="w-full flex justify-start gap-[36%]">
            <button
              type="submit"
              className="w-[15%] h-[40px] text-black bg-[#DEA838] border border-[#DEA838] rounded-md hover:bg-[#DEA838]"
            >
              Create User
            </button>
            <label className={`flex flex-col items-center justify-center w-[15%] border-2  ${errors.file ? "border-red-500":"border-[#33383F]"} bg-[#1E1E1E] rounded-lg`}>
              <div className="flex flex-col items-center justify-center">
                <div className={`flex flex-row gap-5 items-center border-[#33383F] bg-[#1E1E1E] ${errors.file ? "border-red-500":"border-[#33383F]"}`}>
                  <p className="text-[16px] text-white mb-2">
                    {file ? (
                      <div className="mt-4">
                        {file.type.includes('image/') ? (
                          <img
                            src={preview}
                            alt="Uploaded File"
                            className="max-w-full h-[10vh] rounded-lg"
                          />
                        ) : (
                          <p className="text-white">File type not supported for preview.</p>
                        )}
                      </div>
                    ) : (
                      <p className='pt-2 text-[14px]'> + Add Profile Picture</p>
                    )}
                  </p>
                  <input
                    id="id_card"
                    name="id_card"
                    type="file"
                    accept="application/pdf, image/png, image/jpeg"
                    className="hidden"
                    onChange={handleChanges}
                  />
                </div>
              </div>
            </label>
          </div>
        </div>
      </form>
    </AdminWrapper>
  );
};

export default AddNew;
