import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const Costing: React.FC<{ onSave: (costingData: object) => void; onPrevious: () => void }> = ({ onSave, onPrevious }) => {
  const [cost, setCost] = useState('');
  const [currencyVisible, setCurrencyVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [propertyPrice, setPropertyPrice] = useState('');
  const [averageRentalIncome, setAverageRentalIncome] = useState('');
  const [rentalCost, setRentalCost] = useState('');
  const [averageOccupancy, setAverageOccupancy] = useState('');
  const [capitalAppreciation, setCapitalAppreciation] = useState('');
  const [totalFlats, setTotalFlats] = useState('');
  const [availableSlots, setAvailableSlots] = useState('');

  const handleCurrencyToggle = () => {
    setCurrencyVisible(!currencyVisible);
  };

  const handleCurrencySelect = (currency: string) => {
    setSelectedCurrency(currency);
    setCurrencyVisible(false);
  };

  const handleSave = () => {
    const costingData = {
      cost,
      currency: selectedCurrency,
      propertyPrice,
      averageRentalIncome,
      rentalCost,
      averageOccupancy,
      capitalAppreciation,
      totalFlats,
      availableSlots,
    };

    // Pass data to the parent component
    onSave(costingData);
  };

  return (
    <div className='px-[50px] py-[20px]'>
      {/* Currency Selector */}
      <div>
        <label htmlFor="">Currency</label>
        <div className='w-full p-2'>
          <p onClick={handleCurrencyToggle} className='flex py-2 px-4 items-center bg-[#242424] justify-between'>
            {selectedCurrency} 
            <FaChevronDown />
          </p>
          {currencyVisible && (
            <div className="absolute bg-[#242424] border mt-1 rounded shadow-lg z-10">
              <div onClick={() => handleCurrencySelect('USD')} className="cursor-pointer hover:bg-gray-200 p-2">USD</div>
              <div onClick={() => handleCurrencySelect('EUR')} className="cursor-pointer hover:bg-gray-200 p-2">EUR</div>
              <div onClick={() => handleCurrencySelect('GBP')} className="cursor-pointer hover:bg-gray-200 p-2">GBP</div>
            </div>
          )}
        </div>
      </div>

      {/* Property Price Input with Currency */}
      <div className="mt-4 relative">
        <label>Property Price</label>
        <div className="flex items-center mt-2">
          <p onClick={handleCurrencyToggle} className="cursor-pointer bg-[#242424] py-2 px-2">{selectedCurrency}</p>
          <input
            type="number"
            placeholder="Property Price"
            value={propertyPrice}
            onChange={(e) => setPropertyPrice(e.target.value)}
            className=" pl-8 w-full p-2 bg-[#242424]"
          />
        </div>
      </div>

      {/* Average Rental Income and Rental Cost Inputs */}
      <div className="flex space-x-4 mt-4">
        <div className="flex-1">
          <label>Average Rental Income</label>
          <div className="flex items-center mt-2">
            <p onClick={handleCurrencyToggle} className="cursor-pointer py-2 bg-[#242424] px-2">{selectedCurrency}</p>
            <input
              type="number"
              placeholder="Average Rental Income"
              value={averageRentalIncome}
              onChange={(e) => setAverageRentalIncome(e.target.value)}
              className=" pl-8 w-full p-2 bg-[#242424]"
            />
          </div>
        </div>
        <div className="flex-1">
          <label>Rental Cost</label>
          <div className="flex items-center mt-2">
            <p onClick={handleCurrencyToggle} className="cursor-pointer py-2 bg-[#242424] px-2">{selectedCurrency}</p>
            <input
              type="number"
              placeholder="Rental Cost"
              value={rentalCost}
              onChange={(e) => setRentalCost(e.target.value)}
              className=" pl-8 w-full p-2 bg-[#242424]"
            />
          </div>
        </div>
      </div>

      {/* Average Occupancy Figure and Capital Appreciation Inputs */}
      <div className="flex space-x-4 mt-4">
        <div className="flex-1">
          <label>Average Occupancy Figure</label>
          <input
            type="number"
            placeholder="Average Occupancy Figure"
            value={averageOccupancy}
            onChange={(e) => setAverageOccupancy(e.target.value)}
            className="mt-2 w-full p-2 bg-[#242424]"
          />
        </div>
        <div className="flex-1">
          <label>Capital Appreciation</label>
          <input
            type="number"
            placeholder="Capital Appreciation"
            value={capitalAppreciation}
            onChange={(e) => setCapitalAppreciation(e.target.value)}
            className="mt-2 w-full p-2 bg-[#242424]"
          />
        </div>
      </div>

      {/* Total Number of Flats and Available Slots Inputs */}
      <div className="flex space-x-4 mt-4">
        <div className="flex-1">
          <label>Total Number of Flats</label>
          <input
            type="number"
            placeholder="Total Number of Flats"
            value={totalFlats}
            onChange={(e) => setTotalFlats(e.target.value)}
            className="mt-2 w-full p-2 bg-[#242424]"
          />
        </div>
        <div className="flex-1">
          <label>Number of Available Slots</label>
          <input
            type="number"
            placeholder="Number of Available Slots"
            value={availableSlots}
            onChange={(e) => setAvailableSlots(e.target.value)}
            className="mt-2 w-full p-2 bg-[#242424]"
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <button className="border border-[#DEA838] bg-transparent rounded-[5px] text-[#DEA838] py-2 px-4" onClick={onPrevious}>
          Previous
        </button>
        <button className="bg-[#DEA838] text-black rounded-[5px] py-2 px-4" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default Costing;
