import React, { useEffect, useState } from 'react';
import { FaSearch, FaChevronDown } from 'react-icons/fa';
import { BsCalendar4Range } from 'react-icons/bs';
import DatePicker from 'react-datepicker'; // Ensure this package is installed
import 'react-datepicker/dist/react-datepicker.css';
import BookingsTable from './BookingsTable';
import { userRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import { MdOutlineContentCopy } from 'react-icons/md';

export default function Habitable() {
    const [userBookings, setUserBookings] = useState<any[]>([]);
    const [isBookingsListingsPage, setIsBookingsListingsPage] = useState(true);
    const [isUpdateBookingPage, setIsUpdateBookingPage] = useState(false);
    const [userBookingToUpdate, setUserBookingToUpdate] = useState(null);
    const [triggerReload, setTriggerReload] = useState(false);
    const [showStatusOptions, setShowStatusOptions] = useState(false);

    const [checkInDate, setCheckInDate] = useState<Date | null>(null);
    const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
    const [showCheckInCalendar, setShowCheckInCalendar] = useState(false);
    const [showCheckOutCalendar, setShowCheckOutCalendar] = useState(false);


    const showListingspage = () => {
        setIsBookingsListingsPage(true);
        setIsUpdateBookingPage(false);
        setUserBookingToUpdate(null);
    };

    const showUpdatePage = (booking: any) => {
        setIsBookingsListingsPage(false);
        setIsUpdateBookingPage(true);
        setUserBookingToUpdate(booking);
    };

    useEffect(() => {
        const getBookings = async () => {
            try {
                const res = await userRequest("/admin/bookings/list");
                setUserBookings(res?.data?.data);
            } catch (err) {
                toast.error("There was an error fetching user bookings");
            }
        };
        getBookings();
    }, [triggerReload]);
    return (
        <>
                <p className='mt-5 font-jakarta'>Generate Habitable code</p>
            <div className="flex justify-between items-center my-5">
                <div className="flex items-center gap-6">
                   

                    <div className="relative">
                        <p className='text-[#9A9FA5] font-jakarta'>User above 5000fx</p>
                        <button
                            className="bg-[#323030] text-white py-2 px-4 rounded-lg flex items-center space-x-2"
                            onClick={() => setShowStatusOptions(!showStatusOptions)}
                        >
                            <span className='text-gray-500 font-jakarta'>All users</span>
                            <FaChevronDown className='text-gray-500' />
                        </button>
                        {showStatusOptions && (
                            <div className="absolute bg-[#323030] z-10 border border-[#323030] rounded-lg shadow-lg mt-2">
                                <div className="py-2 px-4 font-jakarta cursor-pointer">
                                    Status Option 1
                                </div>
                                <div className="py-2 px-4 font-jakarta cursor-pointer">
                                    Status Option 2
                                </div>
                                <div className="py-2 px-4 font-jakarta cursor-pointer">
                                    Status Option 3
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="relative">
                        <p className='text-[#9A9FA5] font-jakarta'>User below 5000fx</p>
                        <button
                            className="bg-[#323030] text-white py-2 px-4 rounded-lg flex items-center space-x-2"
                            onClick={() => setShowStatusOptions(!showStatusOptions)}
                        >
                            <span className='text-gray-500 font-jakarta'>All users</span>
                            <FaChevronDown className='text-gray-500' />
                        </button>
                        {showStatusOptions && (
                            <div className="absolute bg-[#323030] z-10 border border-[#323030] rounded-lg shadow-lg mt-2">
                                <div className="py-2 font-jakarta px-4 cursor-pointer">
                                    Status Option 1
                                </div>
                                <div className="py-2 font-jakarta px-4 cursor-pointer">
                                    Status Option 2
                                </div>
                                <div className="py-2 font-jakarta px-4 cursor-pointer">
                                    Status Option 3
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="relative">
                        <p className='text-[#9A9FA5] font-jakarta'>Day</p>
                        <button
                            className="bg-[#323030] text-white py-2 px-4 rounded-lg flex items-center space-x-2"
                            onClick={() => setShowStatusOptions(!showStatusOptions)}
                        >
                            <span className='text-gray-500 font-jakarta'>All users</span>
                            <FaChevronDown className='text-gray-500' />
                        </button>
                        {showStatusOptions && (
                            <div className="absolute bg-[#323030] font-jakarta z-10 border border-[#323030] rounded-lg shadow-lg mt-2">
                                <div className="py-2 px-4 font-jakarta cursor-pointer">
                                    Status Option 1
                                </div>
                                <div className="py-2 px-4 font-jakarta cursor-pointer">
                                    Status Option 2
                                </div>
                                <div className="py-2 px-4 font-jakarta cursor-pointer">
                                    Status Option 3
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <p className='text-[#9A9FA5] font-jakarta'>Start date</p>
                        <button
                            className="bg-[#323030] flex gap-5 items-center text-gray-500 py-2 px-4 rounded-lg relative"
                            onClick={() => setShowCheckInCalendar(!showCheckInCalendar)}
                        >
                            <BsCalendar4Range />
                            {checkInDate ? checkInDate.toLocaleDateString() : "01/04/2023"}
                            {showCheckInCalendar && (
                                <div className="absolute z-10 top-full mt-2">
                                    <DatePicker
                                        selected={checkInDate}
                                        onChange={(date) => {
                                            setCheckInDate(date);
                                            setShowCheckInCalendar(false);
                                        }}
                                        inline
                                    />
                                </div>
                            )}
                        </button>
                    </div>
                    <div>
                        <p className='text-[#9A9FA5] font-jakarta'>Expiry date</p>
                        <button
                            className="bg-[#323030] flex gap-5 items-center text-gray-500 py-2 px-4 rounded-lg relative"
                            onClick={() => setShowCheckOutCalendar(!showCheckOutCalendar)}
                        >
                            <BsCalendar4Range />
                            {checkOutDate ? checkOutDate.toLocaleDateString() : "01/04/2023"}
                            {showCheckOutCalendar && (
                                <div className="absolute z-10 top-full mt-2">
                                    <DatePicker
                                        selected={checkOutDate}
                                        onChange={(date) => {
                                            setCheckOutDate(date);
                                            setShowCheckOutCalendar(false);
                                        }}
                                        inline
                                    />
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <div className='my-5'>
                <div className=" flex  w-[40%] ">
                    <button className='bg-[#DEA838] font-jakarta py-2 text-black rounded-[3px] px-3'>
                        Generate Code
                    </button>
                    <div className='relative rounded-l-none rounded-r-[10px] border border-[#DEA838]'>
                        <input
                            type="text"
                            className="rounded-lg bg-transparent  text-sm py-2 pl-10 pr-2 focus:outline-none"
                            placeholder="3425716RDFSE"
                        />
                        <MdOutlineContentCopy className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                    </div>
                </div>
            </div>

            <div className='w-[82%] flex justify-end'>
                <button className='bg-[#DEA838] px-10 py-2 font-jakarta rounded-[10px] text-black'>Save</button>
            </div>

            <div className='flex justify-start my-5'>
            <div className="relative">
                <input
                  type="text"
                  className="rounded-lg border bg-transparent border-[#353945] text-sm py-2 pl-2 pr-2 focus:outline-none"
                  placeholder="Search"
                />
                <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              </div>
            </div>

            <BookingsTable
                userBookings={userBookings}
                setTriggerReload={setTriggerReload}
                showUpdatePage={showUpdatePage}
            />
        </>
    );
}
