import { FaChevronDown } from "react-icons/fa";
import { useState } from "react";

export default function TicketDetail({ booking }: { booking: any }) {
    const [showStatusOptions, setShowStatusOptions] = useState(false);
    const [showPriorityOptions, setShowPriorityOptions] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("Opened");
    const [selectedPriority, setSelectedPriority] = useState("High");

    const toggleStatusOptions = () => {
        setShowStatusOptions((prev) => !prev);
    };

    const togglePriorityOptions = () => {
        setShowPriorityOptions((prev) => !prev);
    };

    const handleStatusSelect = (status: any) => {
        setSelectedStatus(status);
        setShowStatusOptions(false);
    };

    const handlePrioritySelect = (priority: any) => {
        setSelectedPriority(priority);
        setShowPriorityOptions(false);
    };

    return (
        <>
            <div className="flex gap-3 items-center">
                <div className="flex flex-col gap-2">
                    <p className="text-[14px] font-jakarta">USER</p>
                    <p className="text-[14px] font-jakarta">John Doe</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-[14px] font-jakarta">EMAIL</p>
                    <p className="text-[14px] font-jakarta">johndoe@gmail.com</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-[14px] font-jakarta">TICKET ID</p>
                    <p className="text-[14px] font-jakarta">TK-123456</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-[14px] font-jakarta">DATE</p>
                    <p className="text-[14px] font-jakarta">01/04/2023</p>
                </div>
            </div>
            <div className="flex gap-3 items-center my-5">
                {/* Status Section */}
                <div>
                    <p className="text-[14px] font-jakarta">Status</p>
                    <div className="relative">
                        <button
                            className="bg-[#323030] text-white font-jakarta text-[14px] py-2 px-4 rounded-lg flex items-center space-x-2"
                            onClick={toggleStatusOptions}
                        >
                            <span>{selectedStatus}</span>
                            <FaChevronDown />
                        </button>
                        {showStatusOptions && (
                            <div className="absolute bg-[#323030] font-jakarta border border-[#323030] rounded-lg shadow-lg mt-2">
                                {["New", "Opened", "Resolved", "Postponed"].map((status) => (
                                    <div
                                        key={status}
                                        className="py-2 px-4 font-jakarta cursor-pointer text-[14px]"
                                        onClick={() => handleStatusSelect(status)}
                                    >
                                        {status}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Priority Section */}
                <div>
                    <p className="text-[14px] font-jakarta">Priority</p>
                    <div className="relative">
                        <button
                            className="bg-[#323030] text-white font-jakarta text-[14px] py-2 px-4 rounded-lg flex items-center space-x-2"
                            onClick={togglePriorityOptions}
                        >
                            <span>{selectedPriority}</span>
                            <FaChevronDown />
                        </button>
                        {showPriorityOptions && (
                            <div className="absolute bg-[#323030] border border-[#323030] rounded-lg shadow-lg mt-2">
                                {["High", "Medium", "Low"].map((priority) => (
                                    <div
                                        key={priority}
                                        className="py-2 px-4 cursor-pointer text-[14px]"
                                        onClick={() => handlePrioritySelect(priority)}
                                    >
                                        {priority}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Category Section */}
                <div>
                    <p className="text-[14px] font-jakarta">Category</p>
                    <div className="relative">
                        <p className="bg-[#323030] font-jakarta text-white text-[14px] py-2 px-4 rounded-lg flex items-center space-x-2">
                            <span>Investment</span>
                        </p>
                    </div>
                </div>
            </div>
            <p className="my-4 text-[14px] font-jakarta">MESSAGE</p>
            <div className="bg-[#515151] p-4 rounded-[10px]">
                <p className="text-[#DEA838] font-jakarta text-[14px]">Pending payment</p>
                <p className="text-[14px] font-jakarta">
                    {booking?.message}
                </p>
            </div>
            <p className="my-4 text-[14px] font-jakarta">ATTACHED FILE</p>
            <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center bg-[#33383F] p-4 rounded-[6px]">
                    <div className="flex gap-2">
                        <div>
                            <img src="/assets/images/bxs_image.svg" alt="userimage" />
                        </div>
                        <div>
                            <p className="text-[14px] font-jakarta">imagess.jpg</p>
                            <p className="text-[14px] font-jakarta">5.1mb</p>
                        </div>
                    </div>
                    <div>
                        <p className="bg-[#DEA838] font-jakarta text-black text-[14px] px-10 py-2 rounded-[6px] cursor-pointer">
                            Download
                        </p>
                    </div>
                </div>
                <div className="flex justify-between items-center bg-[#33383F] p-4 rounded-[6px]">
                    <div className="flex gap-2">
                        <div>
                            <img src="/assets/images/bxs_image.svg" alt="userimage" />
                        </div>
                        <div>
                            <p className="text-[14px] font-jakarta">Document.jpg</p>
                            <p className="text-[14px] font-jakarta">5.1mb</p>
                        </div>
                    </div>
                    <div>
                        <p className="bg-[#DEA838] font-jakarta text-black text-[14px] px-10 py-2 rounded-[6px] cursor-pointer">
                            Download
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
