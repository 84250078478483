import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import AdminWrapper from "../../components/AdminWrapper";
import UpdateBooking from "./UpdateBooking";
import { FaSearch, FaChevronDown } from "react-icons/fa";
import { BsCalendar4Range } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PromoBookingsTable from "./Promo";
import Promo from "./Promo";
import Habitable from "./Habitable";
import BookingsTable from "./BookingsTable";
import { LiaTimesSolid } from "react-icons/lia";


const Withdraw = () => {
  const [userBookings, setUserBookings] = useState<any[]>([]);
  const [isBookingsListingsPage, setIsBookingsListingsPage] = useState(true);
  const [isUpdateBookingPage, setIsUpdateBookingPage] = useState(false);
  const [userBookingToUpdate, setUserBookingToUpdate] = useState(null);
  const [triggerReload, setTriggerReload] = useState(false);
  const [activeTab, setActiveTab] = useState("guestList");
  const [showStatusOptions, setShowStatusOptions] = useState(false);

  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
  const [showCheckInCalendar, setShowCheckInCalendar] = useState(false);
  const [showCheckOutCalendar, setShowCheckOutCalendar] = useState(false);

  const showListingspage = () => {
    setIsBookingsListingsPage(true);
    setIsUpdateBookingPage(false);
    setUserBookingToUpdate(null);
  };

  const showUpdatePage = (booking: any) => {
    setIsBookingsListingsPage(false);
    setIsUpdateBookingPage(true);
    setUserBookingToUpdate(booking);
  };

  useEffect(() => {
    const getBookings = async () => {
      try {
        const res = await userRequest("/admin/bookings/list");
        setUserBookings(res?.data?.data);
      } catch (err) {
        toast.error("There was an error fetching user bookings");
      }
    };
    getBookings();
  }, [triggerReload]);
  const [open, setOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const toggleClose = () => {
    setOpen(false);
  };

  const handleViewRequest = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <AdminWrapper>
      {
        open && (
          <div className="bg-white absolute z-100 top-20 left-0 w-full p-5 flex justify-between items-center">
            <div>
              <p className="text-black font-[600] text-[14px] font-jakarta">Withdrawal Request</p>
              <p className="text-[#33383F] text-[13px] font-jakarta">Kenneth Morgan requested for a withdrawal</p>
            </div>
            <div className="flex items-center gap-5">
              <p onClick={handleViewRequest} className="text-black font-jakarta bg-[#DEA838] font-[500] py-2 px-5 rounded-[6px] cursor-pointer border border-[#DEA838]">View request</p>
              <p onClick={toggleClose} className="text-black py-2 font-jakarta font-[500] px-5 rounded-[6px] cursor-pointer border border-[#DEA838]">Cancel</p>
            </div>
          </div>
        )
      }
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#1E1E1E] p-8 rounded-lg shadow-lg max-w-md mt-10 w-full">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold mb-4 font-jakarta">Withdrawal Request</h2>
              <button
                onClick={closeModal}
                className="cursor-pointer"
              >
               <LiaTimesSolid />
              </button>
            </div>
            <div className="my-5">
              <div className="border border-[#33383F] p-5 rounded-[10px]">
                <p className="text-[16px] font-jakarta">£200,000</p>
                <p className="text-[14px] text-[#6F767E] font-jakarta">Available balance</p>
              </div>
              <div className="border border-[#33383F] p-5 rounded-[10px] mt-5">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-[#6F767E] font-jakarta">USERNAME</p>
                    <p className="font-jakarta">Kenneth Morgan</p>
                  </div>
                  <div>
                    <p className="text-[#6F767E] font-jakarta">BANK NAME</p>
                    <p className="font-jakarta">HSBC Holdings</p>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-5">
                  <div>
                    <p className="text-[#6F767E] font-jakarta">AMOUNT TO WITHDRAW</p>
                    <p className="font-jakarta">£200,000</p>
                  </div>
                  <div>
                    <p className="text-[#6F767E] font-jakarta">ACCOUNT NO</p>
                    <p className="font-jakarta">2343748574</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <p className="bg-[#DEA838] text-black cursor-pointer font-jakarta py-2 px-8 rounded-[6px]">Accept</p>
              <p className="border border-[#DEA838] text-[#DEA838] font-jakarta py-2 px-8 cursor-pointer rounded-[6px]">Decline</p>
            </div>
          </div>
        </div>
      )}


      {activeTab === "guestList" && (
        <>
          <div className={`flex justify-between items-center ${open ? "mt-20" : " mt-0"} mb-5`}>
            <div className="flex items-center space-x-2">
              <div className="relative">
                <input
                  type="text"
                  className="rounded-lg border bg-transparent border-[#353945] text-sm py-2 pl-10 pr-2 focus:outline-none"
                  placeholder="Search"
                />
                <FaSearch className="absolute right-3 top-1/2 transform font-jakarta -translate-y-1/2 text-gray-500" />
              </div>
              <div>
                <p>Filter by:</p>
              </div>
              <div className="relative">
                <button
                  className="bg-[#323030] text-white py-2 px-4 rounded-lg flex items-center space-x-2"
                  onClick={() => setShowStatusOptions(!showStatusOptions)}
                >
                  <span>Status</span>
                  <FaChevronDown />
                </button>
                {showStatusOptions && (
                  <div className="absolute bg-[#323030] font-jakarta border border-[#323030] rounded-lg shadow-lg mt-2">
                    <div className="py-2 px-4 font-jakarta cursor-pointer">
                      Status Option 1
                    </div>
                    <div className="py-2 px-4 font-jakarta cursor-pointer">
                      Status Option 2
                    </div>
                    <div className="py-2 px-4 font-jakarta cursor-pointer">
                      Status Option 3
                    </div>
                  </div>
                )}
              </div>

              <button
                className="bg-[#323030] flex gap-5 items-center text-white py-2 px-4 rounded-lg relative"
                onClick={() => setShowCheckInCalendar(!showCheckInCalendar)}
              >
                {checkInDate ? checkInDate.toLocaleDateString() : "Date"}
                <BsCalendar4Range />
                {showCheckInCalendar && (
                  <div className="absolute z-10 top-full mt-2">
                    <DatePicker
                      selected={checkInDate}
                      onChange={(date) => {
                        setCheckInDate(date);
                        setShowCheckInCalendar(false);
                      }}
                      inline
                    />
                  </div>
                )}
              </button>
              
             
            </div>
          </div>

          <BookingsTable
            // userBookings={userBookings}
            // setTriggerReload={setTriggerReload}
            // showUpdatePage={showUpdatePage}
          />
        </>
      )}

      

      {isUpdateBookingPage && (
        <UpdateBooking
          userBooking={userBookingToUpdate}
          showListingspage={showListingspage}
          setTriggerReload={setTriggerReload}
        />
      )}
    </AdminWrapper>
  );
};

export default Withdraw;
