import React, { useEffect, useState } from 'react';
import { FaSearch, FaChevronDown } from 'react-icons/fa';
import { BsCalendar4Range } from 'react-icons/bs';
import DatePicker from 'react-datepicker'; // Ensure this package is installed
import 'react-datepicker/dist/react-datepicker.css';
import { userRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import SupportTable from './SuportTable';
import AdminWrapper from "../../components/AdminWrapper";

export default function Support() {
  const [userBookings, setUserBookings] = useState<any[]>([]);
  const [isBookingsListingsPage, setIsBookingsListingsPage] = useState(true);
  const [isUpdateBookingPage, setIsUpdateBookingPage] = useState(false);
  const [userBookingToUpdate, setUserBookingToUpdate] = useState(null);
  const [triggerReload, setTriggerReload] = useState(false);

  // Dropdown states
  const [showPriorityOptions, setShowPriorityOptions] = useState(false);
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);

  // Calendar states
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [showCheckInCalendar, setShowCheckInCalendar] = useState(false);

  const togglePriorityDropdown = () => {
    setShowPriorityOptions(!showPriorityOptions);
    setShowStatusOptions(false); // Close others
    setShowCategoryOptions(false);
  };

  const toggleStatusDropdown = () => {
    setShowStatusOptions(!showStatusOptions);
    setShowPriorityOptions(false);
    setShowCategoryOptions(false);
  };

  const toggleCategoryDropdown = () => {
    setShowCategoryOptions(!showCategoryOptions);
    setShowPriorityOptions(false);
    setShowStatusOptions(false);
  };

  const toggleCheckInCalendar = () => {
    setShowCheckInCalendar(!showCheckInCalendar);
  };

  const showListingspage = () => {
    setIsBookingsListingsPage(true);
    setIsUpdateBookingPage(false);
    setUserBookingToUpdate(null);
  };

  const showUpdatePage = (booking: any) => {
    setIsBookingsListingsPage(false);
    setIsUpdateBookingPage(true);
    setUserBookingToUpdate(booking);
  };

  useEffect(() => {
    const getBookings = async () => {
      try {
        const res = await userRequest("/admin/supports/list");
        console.log(res?.data?.data, "support response");
        setUserBookings(res?.data?.data);
      } catch (err) {
        toast.error("There was an error fetching user bookings");
      }
    };
    getBookings();
  }, [triggerReload]);

  return (
    <AdminWrapper>
      <div className="flex justify-between items-center my-5">
        <div className="flex items-center space-x-2">
          <div className="relative">
            <input
              type="text"
              className="rounded-lg border bg-transparent border-[#353945] text-sm py-2 pl-10 pr-2 focus:outline-none"
              placeholder="Search"
            />
            <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          </div>
          <div>
            <p className='font-jakarta'>Filter by:</p>
          </div>

          {/* Priority Dropdown */}
          <div className="relative">
            <button
              className="bg-[#323030] text-white py-2 px-4 font-jakarta rounded-lg flex items-center space-x-2"
              onClick={togglePriorityDropdown}
            >
              <span>Priority</span>
              <FaChevronDown />
            </button>
            {showPriorityOptions && (
              <div className="absolute bg-[#323030] w-[110%] border border-[#323030] rounded-lg shadow-lg mt-2">
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">High</div>
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Low</div>
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Medium</div>
              </div>
            )}
          </div>

          {/* Status Dropdown */}
          <div className="relative">
            <button
              className="bg-[#323030] text-white py-2 px-4 font-jakarta rounded-lg flex items-center space-x-2"
              onClick={toggleStatusDropdown}
            >
              <span>Status</span>
              <FaChevronDown />
            </button>
            {showStatusOptions && (
              <div className="absolute bg-[#323030] border w-[110%] border-[#323030] rounded-lg shadow-lg mt-2">
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">New</div>
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Opened</div>
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Resolved</div>
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Postponed</div>
              </div>
            )}
          </div>

          {/* Calendar */}
          <button
            className="bg-[#323030] flex gap-5 items-center text-white py-2 px-4 rounded-lg relative"
            onClick={toggleCheckInCalendar}
          >
            {checkInDate ? checkInDate.toLocaleDateString() : "Date"}
            <BsCalendar4Range />
            {showCheckInCalendar && (
              <div className="absolute z-10 top-full mt-2">
                <DatePicker
                  selected={checkInDate}
                  onChange={(date) => {
                    setCheckInDate(date);
                    setShowCheckInCalendar(false);
                  }}
                  inline
                />
              </div>
            )}
          </button>

          {/* Category Dropdown */}
          <div className="relative">
            <button
              className="bg-[#323030] text-white py-2 px-4 rounded-lg font-jakarta flex items-center space-x-2"
              onClick={toggleCategoryDropdown}
            >
              <span>Category</span>
              <FaChevronDown />
            </button>
            {showCategoryOptions && (
              <div className="absolute bg-[#323030] w-[110%] border border-[#323030] rounded-lg shadow-lg mt-2">
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Category 1</div>
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Category 2</div>
                <div className="py-2 px-4 font-jakarta text-[14px] cursor-pointer">Category 3</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='flex justify-between items-center mb-5'>
        <div className='flex gap-3 items-center'>
          <p className='font-jakarta'>Show</p>
          <p className="border font-jakarta px-3 rounded-[10px] py-0">10</p>
          <p className='font-jakarta'>entries</p>
        </div>
        <div>
          <p className='text-[#DEA838] border font-jakarta border-[#DEA838] px-7 py-2 rounded-[10px] cursor-pointer'>Export</p>
        </div>
      </div>

      <SupportTable
        userBookings={userBookings}
        setTriggerReload={setTriggerReload}
        showUpdatePage={showUpdatePage}
      />
    </AdminWrapper>
  );
}
