import * as React from "react";
import { formatPrice } from "../../utils/helpers";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { LiaTimesSolid } from "react-icons/lia";
import { FaChevronDown } from "react-icons/fa";

export default function SlotTable(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
console.log(props, "props data")
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row: any) => {
    setSelectedRow(row);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
  };

  // Calculate the starting and ending index of the items being displayed
  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min((page + 1) * rowsPerPage, props.paymentData.length);
  const [showStatusOptions, setShowStatusOptions] = React.useState(false);
  const paymentData = props.paymentData?.data || [];
  return (
    <div className="w-full overflow-hidden font-jakarta">
      <div className="overflow-x-auto">
        <table className="w-full text-left border-collapse border border-[#353945] rounded-lg">
          <thead className="bg-[#242424]">
            <tr className="h-12 text-[14px]">
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Property</th>
              <th className="px-4 py-2">Slot</th>
              <th className="px-4 py-2">Date</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            
            {paymentData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => (
                <tr
                  key={row.id}
                  className={`h-20 bg-[#1E1E1E] border-b text-[14px] border-[#353945] ${index % 2 === 0 ? "bg-[#1E1E1E]" : ""}`}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: 'pointer' }}
                >
                  <td className="px-4 py-2 text-[14px] text-white">{row.user_id}</td>
                  <td className="px-4 py-2 text-[14px] text-white">{row.reference}</td>
                  <td className="px-4 py-2 text-[14px] text-white">
                    {formatPrice(row.amount)}
                  </td>
                  <td className="px-4 py-2 text-[14px] text-white">{row.time}</td>
                  <td className={`px-4 my-2 text-[14px] rounded-[10px] ${row.status === 'approved' ? "text-[#30FF51] bg-[#30FF51] bg-opacity-[30%]" : "text-white"}`}>{row.status}</td>
                  <td className="px-4 py-2 text-[14px] text-white">{row.created_at}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between bg-black p-4">
        <div>
          <span className="text-white">
            Showing {startIndex}-{endIndex} of {props.paymentData.length}
          </span>
        </div>
        <div className="flex items-center">
          <button
            onClick={(e) => handleChangePage(e, page - 1)}
            disabled={page === 0}
            className={`text-white ${page === 0 ? "opacity-50" : ""}`}
          >
            <IoIosArrowBack />
          </button>
          <span className="mx-2 text-white">
            {page + 1} of {Math.ceil(props.paymentData.length / rowsPerPage)}
          </span>
          <button
            onClick={(e) => handleChangePage(e, page + 1)}
            disabled={page >= Math.ceil(props.paymentData.length / rowsPerPage) - 1}
            className={`text-white ${page >= Math.ceil(props.paymentData.length / rowsPerPage) - 1 ? "opacity-50" : ""}`}
          >
            <IoIosArrowForward />
          </button>
        </div>
      </div>

      {/* Modal to display row details */}
      {selectedRow && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center font-jakarta justify-center">
          <div className="absolute bg-[black] h-[90vh] right-0 top-[12%] z-10 p-6 rounded-lg w-[30%]">
            <div className="flex justify-end">
              <LiaTimesSolid onClick={handleCloseModal} className="cursor-pointer"/>
            </div>
            <h2 className="text-[14px] font-bold mb-4">Payment Details</h2>
                <p>Status</p>
            <div className="flex justify-between items-center mb-5">
              <div>
                <div className="relative">
                  <button
                    className="bg-[#323030] text-[14px] text-white py-2 px-4 rounded-lg flex items-center space-x-2"
                    onClick={() => setShowStatusOptions(!showStatusOptions)}
                  >
                    <span className="text-[14px]">Approved</span>
                    <FaChevronDown />
                  </button>
                  {showStatusOptions && (
                    <div className="absolute bg-[#323030] text-[14px] border border-[#323030] rounded-lg shadow-lg mt-2">
                      <div className="py-2 px-4 text-[14px] cursor-pointer">
                        Status Option 1
                      </div>
                      <div className="py-2 px-4 text-[14px] cursor-pointer">
                        Status Option 2
                      </div>
                      <div className="py-2 px-4 text-[14px] cursor-pointer">
                        Status Option 3
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="relative">
                  <p
                    className="bg-[#DEA838] text-black text-[14px] py-2 px-4 rounded-lg flex items-center space-x-2"
                  >
                    <span>Export Resort</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[12px]">AMOUNT</p>
                <p className="text-[20px]"> {formatPrice(selectedRow.amount)}</p>
              </div>
              <div>
                <p className="text-[12px]">REFERENCE ID</p>
                <p> {selectedRow.transactionId}</p>
              </div>
            </div>
            <div className="flex justify-between items-center my-5">
              <div>
                <p className="text-[12px]">USER</p>
                <p>{selectedRow.user}</p>
              </div>
              <div>
                <p className="text-[12px]">EMAIL</p>
                <p>Maihomm@gmail.com</p>
              </div>
              <div>
                <p className="text-[14px]">DATE</p>
                <p> {selectedRow.date}</p>
              </div>
            </div>
            <div className="flex gap-4 items-center mb-5">
              <div>
                <p className="text-[12px]">PROPERTY NAME</p>
                <p className="text-[14px]">Maihomm</p>
              </div>
              <div>
                <p className="text-[12px]">NUMBER OF SLOTS</p>
                <p>2</p>
              </div>
            </div>

            <div className="flex justify-between items-center bg-[#33383F] p-4 rounded-[6px]">

              <div className="flex gap-2">
                <div>
                  <img src="/assets/images/bxs_image.svg" alt="userimage" />
                </div>
                <div>
                  <p>imagess.jpg</p>
                  <p>5.1mb</p>
                </div>
              </div>
              <div>
                <p className="bg-[#DEA838] text-black px-10 py-2 rounded-[6px] cursor-pointer">Download</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
