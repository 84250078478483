// export {default as Home} from './Home';
export {default as Profile } from './Profile/Profile';
// export {default as Properties } from './Properties';
export {default as Register } from '././Authentication/Registar'
export {default as Login} from './Authentication/Login'
export {default as ForgotPassword} from './Authentication/ForgotPassword';
export {default as Account} from './Authentication/Account'
export {default as Settings} from './Profile/Settings'
export {default as PropertyDetails} from './PropertyDetails'
// export {default as Portfolio} from './Portfolio/Portfolio'
// export {default as IncomeHistory } from './Portfolio/IncomeHistory'
// export {default as Loan} from './Portfolio/Loan'
export {default as Payment} from './Payment'
export {default as Booking} from './Booking'
// export {default as BookingDetails} from './BookingDetails'
// export {default as Rewards} from './Rewards/Rewards'
export {default as Support} from './Support/Support'
// export {default as Overview} from './Overview/Overview'
// export {default as Wallet} from './Wallet'
export {default as AdminInvestments} from './Investment'
export {default as AdminOverview} from './Overview'
export {default as AdminUsers} from './Users'
export {default as EditUser} from './Users/EditUser'
export {default as AddNew} from './Users/AddNew'
export {default as ManageProperties} from './Properties'
export {default as ManageInvestments} from './Investment/ManageInvestments'
