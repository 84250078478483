import React, { useEffect, useRef } from 'react';
import Chart, { ChartOptions } from 'chart.js/auto';

interface BarChartProps {
  data: number[];
  labels: string[];
  backgroundColors: string[];
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  labels,
  backgroundColors,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');

    if (!ctx) {
      return;
    }

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Stats',
            data: data,
            backgroundColor: "#DEA838", // Use the provided backgroundColors
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            ticks: {
              color: 'white', // Set the x-axis labels to red
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: 'white', // Set the y-axis labels to red
            },
          },
        },
      } as ChartOptions,
    });
  }, [data, labels, backgroundColors]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
