// import React from "react";
import { Box, 
  // SelectChangeEvent
 } from "@mui/material";
// import { styled, alpha } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import Payroll from "../payroll";
import TopBar from "../../../components/topNav";
import InvestmentsReturn from "../../Overview/investments";
import InterpolationLineChart from "./chart";
import AdminWrapper from "../../../components/AdminWrapper";
const ManageInvestments = () => {
  // const [age, setAge] = React.useState("");

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value);
  // };
  // const Search = styled("div")(({ theme }) => ({
  //   position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  //   marginLeft: 0,
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(1),
  //     width: "auto",
  //   },
  // }));

  // const SearchIconWrapper = styled("div")(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  //   height: "100%",
  //   position: "absolute",
  //   pointerEvents: "none",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   color: "inherit",
  //   "& .MuiInputBase-input": {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create("width"),
  //     width: "100%",
  //   },
  // }));

  return (
    <AdminWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></Box>
      <Box sx={{ my: 5 }}>
        <InterpolationLineChart />
      </Box>
      <Box sx={{ my: 5 }}>
        <InvestmentsReturn />
      </Box>
      <Box sx={{ my: 5 }}>
        <Payroll />
      </Box>
    </AdminWrapper>
  );
};

export default ManageInvestments;
