import axios from 'axios';
import Cookies from 'js-cookie';


//const BASE_URL = "https://api.maihomm.com/api"
// const BASE_URL = "http://34.201.102.149:8080/"
//const BASE_URL = "http://13.50.74.27:8080/api"
const BASE_URL = "https://api.maihomm.com/api"; 
const user = Cookies.get("adminUserData_maihomm") || Cookies.get("pendingAdminUser_maihomm");
const currentUser = user && JSON.parse(user);
const TOKEN = currentUser?.token;

console.log(TOKEN, "USER TOKEN")

export const publicRequest = axios.create({
    baseURL: BASE_URL,
})

export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: {Authorization: `Bearer ${TOKEN}`, "Content-Type": "multipart/form-data"}
})
