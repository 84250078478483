import { useState, useEffect } from "react";
import AdminWrapper from "../../components/AdminWrapper";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InvestmentTable from "./investmentTable";
import { userRequest } from "../../requestMethod";
import { BiSliderAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";


// Define types for the Modal props
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [selectedExportType, setSelectedExportType] = useState<string | null>(null);

  const handleExport = () => {
    if (selectedExportType) {
      // Handle the export logic here based on the selectedExportType
      console.log(`Exporting as ${selectedExportType}`);
      // For demonstration, you can add logic to export data as Excel, CSV, or PDF here

      // Close the modal after export
      onClose();
    } else {
      alert("Please select an export type.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-jakarta bg-black bg-opacity-50">
      <div className="bg-[#1E1E1E] py-5 rounded-lg w-[450px] shadow-lg">
        <div className="px-10">
          <div className="flex justify-end items-end">
          <LiaTimesSolid onClick={onClose}/>
          </div>
        <h2 className="text-xl font-bold mb-4">Export Data</h2>
        <p className="mb-4">Select the format to export the data:</p>
        </div>
        <div className="flex flex-row justify-center items-center gap-2">
          <button
            onClick={() => setSelectedExportType("Excel")}
            className={` p-3 rounded-[5px] text-[14px] ${
              selectedExportType === "Excel" ? "bg-[#323030] border border-[#DEA838] text-white" : "bg-[#323030] border border-[#6F767E]"
            }`}
          >
            Export as Excel
          </button>
          <button
            onClick={() => setSelectedExportType("CSV")}
            className={` p-3 rounded-[5px] text-[14px] ${
              selectedExportType === "CSV" ? "bg-[#323030] border border-[#DEA838] text-white" : "bg-[#323030] border border-[#6F767E]"
            }`}
          >
            Export as CSV
          </button>
          <button
            onClick={() => setSelectedExportType("PDF")}
            className={` p-3 rounded-[5px] text-[14px] ${
              selectedExportType === "PDF" ? "bg-[#323030] border border-[#DEA838] text-white" : "bg-[#323030] border border-[#6F767E]"
            }`}
          >
            Export as PDF
          </button>
        </div>
        <div className="mt-4 w-full px-10">
          <button
            onClick={handleExport}
            className="px-4 w-full py-2 bg-[#DEA838] text-black rounded-lg"
          >
            Import
          </button>
        </div>
      </div>
    </div>
  );
};

const Index = () => {
  const [inputValue, setInputValue] = useState("");
  const [investmentsData, setInvestmentData] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkedItem, setCheckedItem] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleInputChange = (e: any) => {
    console.log(e.target.value);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    const getInvestments = async () => {
      try {
        const investments = await userRequest("/admin/investments/list");
        setInvestmentData(investments.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getInvestments();
  }, []);

  const handleSearch = async () => {
    try {
      const res = await userRequest(
        `admin/investments/list?search=${inputValue}`
      );
      setInvestmentData(res?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCheckboxChange = (item: string) => {
    setCheckedItem((prev) => (prev === item ? null : item));
  };

  const handleExportClick = () => {
    setIsModalOpen(true); // Open the modal when Export is clicked
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <AdminWrapper>
      <div className="flex justify-between items-center font-jakarta">
        <div className="flex items-center space-x-2">
          <div className="relative w-full bg-transparent">
            <input
              className="border border-[#353945] rounded-lg pl-3 pr-10 py-3 outline-none text-sm w-full bg-transparent"
              value={inputValue}
              onChange={handleInputChange}
              id="habitable"
              type="text"
              placeholder="Search"
            />
            <SearchIcon
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={handleSearch}
            />
          </div>
          <div className="relative">
            <p
              className="flex items-center bg-[#1E1E1E] border border-[#6F767E] p-2 rounded-lg space-x-2 text-gray-700 cursor-pointer"
              onClick={toggleDropdown}
            >
              <BiSliderAlt className="text-white" />
              <span className="text-white">Property</span>
              <ArrowDropDownIcon className="text-white" />
            </p>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-[200%] bg-[#1E1E1E] font-jakarta border border-[#6F767E] rounded-lg shadow-lg">
                {["Unit 3, The works, Warrington", "Koko Station", "Studio Apartment"].map((item) => (
                  <div
                    key={item}
                    className="flex items-center p-2 cursor-pointer"
                    onClick={() => handleCheckboxChange(item)}
                  >
                    <div
                      className={`w-5 h-5 border border-gray-300 rounded-md mr-2 flex items-center justify-center ${
                        checkedItem === item ? "" : ""
                      }`}
                    >
                      {checkedItem === item && (
                        <div className="w-3 h-3 bg-yellow-600 rounded-md"></div>
                      )}
                    </div>
                    <span className="text-[14px]">{item}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-4">
          <p
            onClick={handleExportClick} // Add onClick handler to open modal
            className="bg-[#1E1E1E] border border-[#6F767E] font-jakarta py-2 px-7 rounded-lg text-white cursor-pointer"
          >
            Export
          </p>
          <Link to={"/admin/roipayroll"} className="border font-jakarta border-[#DEA838] bg-[#DEA838] rounded-lg px-4 py-2 font-[700] text-black">
            ROI Payout
          </Link>
        </div>
      </div>
      <div className="my-5">
        <InvestmentTable
          searchValue={inputValue}
          investmentsData={investmentsData}
        />
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} /> {/* Render the modal */}
    </AdminWrapper>
  );
};

export default Index;
