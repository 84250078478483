

import { useState, useEffect } from "react";
import AdminWrapper from "../../components/AdminWrapper";
import { userRequest } from "../../requestMethod";
import UserTable from "./userTable";
import { BiSliderAlt } from "react-icons/bi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import GUserTable from "./GuestUserTable";
import { LiaTimesSolid } from "react-icons/lia";

type KycStatus = 'Verified' | 'Pending' | 'Rejected';
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [selectedExportType, setSelectedExportType] = useState<string | null>(null);

  const handleExport = () => {
    if (selectedExportType) {
      // Handle the export logic here based on the selectedExportType
      console.log(`Exporting as ${selectedExportType}`);
      // For demonstration, you can add logic to export data as Excel, CSV, or PDF here

      // Close the modal after export
      onClose();
    } else {
      alert("Please select an export type.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-[#1E1E1E] py-5 rounded-lg w-[450px] shadow-lg">
        <div className="px-10">
          <div className="flex justify-end items-end">
          <LiaTimesSolid onClick={onClose}/>
          </div>
        <h2 className="text-xl font-bold mb-4">Export Data</h2>
        <p className="mb-4">Select the format to export the data:</p>
        </div>
        <div className="flex flex-row justify-center items-center gap-2">
          <button
            onClick={() => setSelectedExportType("Excel")}
            className={` p-3 rounded-[5px] text-[14px] ${
              selectedExportType === "Excel" ? "bg-[#323030] border border-[#DEA838] text-white" : "bg-[#323030] border border-[#6F767E]"
            }`}
          >
            Export as Excel
          </button>
          <button
            onClick={() => setSelectedExportType("CSV")}
            className={` p-3 rounded-[5px] text-[14px] ${
              selectedExportType === "CSV" ? "bg-[#323030] border border-[#DEA838] text-white" : "bg-[#323030] border border-[#6F767E]"
            }`}
          >
            Export as CSV
          </button>
          <button
            onClick={() => setSelectedExportType("PDF")}
            className={` p-3 rounded-[5px] text-[14px] ${
              selectedExportType === "PDF" ? "bg-[#323030] border border-[#DEA838] text-white" : "bg-[#323030] border border-[#6F767E]"
            }`}
          >
            Export as PDF
          </button>
        </div>
        <div className="mt-4 w-full px-10">
          <button
            onClick={handleExport}
            className="px-4 w-full py-2 bg-[#DEA838] text-black rounded-lg"
          >
            Import
          </button>
        </div>
      </div>
    </div>
  );
};
const Index = () => {
  const [inputValue, setInputValue] = useState("");
  const [usersData, setUsersData] = useState<any[]>([]);
  const [triggerReload, setTriggerReload] = useState(false);
  const [selectedRole, setSelectedRole] = useState("co-owner");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isKycDropdownOpen, setIsKycDropdownOpen] = useState(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const [checkedKycStatus, setCheckedKycStatus] = useState<Record<KycStatus, boolean>>({
    Verified: false,
    Pending: false,
    Rejected: false,
  });

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await userRequest("/admin/users");
        console.log(users, "users")
        setUsersData(users.data.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, [triggerReload]);

  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
  };

  const toggleKycDropdown = () => {
    setIsKycDropdownOpen(!isKycDropdownOpen);
    setIsCountryDropdownOpen(false); // Close the other dropdown
  };

  const toggleCountryDropdown = () => {
    setIsCountryDropdownOpen(!isCountryDropdownOpen);
    setIsKycDropdownOpen(false); // Close the other dropdown
  };

  const selectKycStatus = (status: KycStatus) => {
    setCheckedKycStatus((prevState) => ({
      ...prevState,
      [status]: !prevState[status],
    }));
  };

  const selectCountry = (country: string) => {
    console.log(`Selected Country: ${country}`);
    setIsCountryDropdownOpen(false);
  };
  const handleExportClick = () => {
    setIsModalOpen(true); // Open the modal when Export is clicked
  };
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  return (
    <AdminWrapper>
      <div className="w-full flex justify-between font-jakarta items-center">
        <div className="flex bg-[#1E1E1E] rounded-[20px]">
          <button
            onClick={() => handleRoleChange("co-owner")}
            className={`px-4 py-2 rounded ${
              selectedRole === "co-owner"
                ? "bg-[#DEA838] rounded-[20px] text-black"
                : "bg-transparent text-white"
            }`}
          >
            Co-owner
          </button>
          <button
            onClick={() => handleRoleChange("guest")}
            className={`px-4 py-2 ml-4 rounded ${
              selectedRole === "guest"
                ? "bg-[#DEA838] rounded-[20px] text-black"
                : "bg-transparent text-white"
            }`}
          >
            Guest
          </button>
        </div>
        <div>
          <button className="border border-[#6F767E] font-jakarta text-white px-4 py-2 rounded hover:bg-[#1E1E1E] bg-[#1E1E1E] hover:text-white">
            Import
          </button>
          <button onClick={handleExportClick} className="mx-4 font-jakarta border border-[#6F767E] text-white px-4 py-2 rounded hover:bg-[#1E1E1E] bg-[#1E1E1E] hover:text-white">
            Export
          </button>
        </div>
      </div>
      <div className="mt-4 font-jakarta">
        {selectedRole === "co-owner" && (
          <div className="font-jakarta">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2.5">
                <div className="relative flex items-center">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="pl-8 pr-3 py-1.5 rounded border bg-transparent border-gray-500 text-sm outline-none"
                    placeholder="Search"
                  />
                   <span className="absolute right-2 text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.9 14.32a8 8 0 111.414-1.414l4.9 4.9a1 1 0 01-1.414 1.414l-4.9-4.9zM8 14a6 6 0 100-12 6 6 0 000 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                
                {/* KYC Status Dropdown */}
                <div className="relative">
                  <div className="flex bg-[#1E1E1E] items-center px-2 rounded-[10px]">
                <BiSliderAlt />
                  <button
                    onClick={toggleKycDropdown}
                    className=" text-white px-4 py-2 rounded hover:bg-[#1E1E1E] flex items-center"
                  >
                    KYC Status
                  </button>

                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {isKycDropdownOpen && (
                    <div className="absolute left-0 mt-2 w-48 bg-[#1E1E1E] border border-[#6F767E] rounded z-10 shadow-lg">
                      <div
                        className="px-4 py-2 cursor-pointer flex items-center"
                        onClick={() => selectKycStatus("Verified")}
                      >
                        <div className="w-4 h-4 mr-2 rounded-full border border-gray-400 flex items-center justify-center">
                          {checkedKycStatus.Verified && (
                            <div className="w-2 h-2 rounded-full bg-yellow-500" />
                          )}
                        </div>
                        Verified
                      </div>
                      <div
                        className="px-4 py-2 cursor-pointer flex items-center"
                        onClick={() => selectKycStatus("Pending")}
                      >
                        <div className="w-4 h-4 mr-2 rounded-full border border-gray-400 flex items-center justify-center">
                          {checkedKycStatus.Pending && (
                            <div className="w-2 h-2 rounded-full bg-yellow-500" />
                          )}
                        </div>
                        Pending
                      </div>
                      <div
                        className="px-4 py-2 cursor-pointer flex items-center"
                        onClick={() => selectKycStatus("Rejected")}
                      >
                        <div className="w-4 h-4 mr-2 rounded-full border border-gray-400 flex items-center justify-center">
                          {checkedKycStatus.Rejected && (
                            <div className="w-2 h-2 rounded-full bg-yellow-500" />
                          )}
                        </div>
                        Rejected
                      </div>
                    </div>
                  )}
                </div>

                {/* Country Dropdown */}
                <div className="relative flex">
                  <div className="flex bg-[#1E1E1E] items-center px-2 rounded-[10px]">
                    <BiSliderAlt />
                    <button
                      onClick={toggleCountryDropdown}
                      className=" text-white px-4 py-2 rounded hover:bg-[#1E1E1E] flex items-center"
                    >
                      Country
                    </button>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {isCountryDropdownOpen && (
                    <div className="absolute left-0 top-10 mt-2 w-48 bg-[#1E1E1E] border border-[#6F767E] z-10 rounded shadow-lg">
                      <div
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => selectCountry("Nigeria")}
                      >
                        Nigeria
                      </div>
                      <div
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => selectCountry("USA")}
                      >
                        USA
                      </div>
                      <div
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => selectCountry("Canada")}
                      >
                        Canada
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex">
                <a
                  href="/admin/users/new"
                  className="bg-[#DEA838] text-black px-4 py-2 rounded hover:bg-[#DEA838]"
                >
                  Add New
                </a>
              </div>
            </div>
            <div className="mt-12">
              <UserTable
                searchValue={inputValue}
                usersData={usersData}
                setTriggerReload={setTriggerReload}
                triggerReload={triggerReload}
              />
            </div>
          </div>
        )}
        {selectedRole === "guest" && <div>
          <div className="flex justify-between font-jakarta items-center">
              <div className="flex items-center gap-2.5">
                <div className="relative flex items-center">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="pl-8 pr-3 py-1.5 rounded border bg-transparent border-gray-500 text-sm outline-none"
                    placeholder="Search"
                  />
                   <span className="absolute right-2 text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.9 14.32a8 8 0 111.414-1.414l4.9 4.9a1 1 0 01-1.414 1.414l-4.9-4.9zM8 14a6 6 0 100-12 6 6 0 000 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                
                {/* KYC Status Dropdown */}
                <div className="relative">
                  <div className="flex bg-[#1E1E1E] items-center px-2 rounded-[10px]">
                <BiSliderAlt />
                  <button
                    onClick={toggleKycDropdown}
                    className=" text-white px-4 py-2 font-jakarta rounded hover:bg-[#1E1E1E] flex items-center"
                  >
                    KYC Status
                  </button>

                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {isKycDropdownOpen && (
                    <div className="absolute left-0 mt-2 w-48 bg-[#1E1E1E] border border-[#6F767E] rounded z-10 shadow-lg">
                      <div
                        className="px-4 py-2 cursor-pointer flex items-center"
                        onClick={() => selectKycStatus("Verified")}
                      >
                        <div className="w-4 h-4 mr-2 rounded-full font-jakarta border border-gray-400 flex items-center justify-center">
                          {checkedKycStatus.Verified && (
                            <div className="w-2 h-2 rounded-full bg-yellow-500" />
                          )}
                        </div>
                        Verified
                      </div>
                      <div
                        className="px-4 py-2 cursor-pointer flex items-center"
                        onClick={() => selectKycStatus("Pending")}
                      >
                        <div className="w-4 h-4 mr-2 rounded-full border border-gray-400 flex items-center justify-center">
                          {checkedKycStatus.Pending && (
                            <div className="w-2 h-2 rounded-full font-jakarta bg-yellow-500" />
                          )}
                        </div>
                        Pending
                      </div>
                      <div
                        className="px-4 py-2 cursor-pointer flex items-center"
                        onClick={() => selectKycStatus("Rejected")}
                      >
                        <div className="w-4 h-4 mr-2 rounded-full border border-gray-400 flex items-center justify-center">
                          {checkedKycStatus.Rejected && (
                            <div className="w-2 h-2 rounded-full bg-yellow-500" />
                          )}
                        </div>
                        Rejected
                      </div>
                    </div>
                  )}
                </div>

                {/* Country Dropdown */}
                <div className="relative flex">
                  <div className="flex bg-[#1E1E1E] items-center px-2 rounded-[10px]">
                    <BiSliderAlt />
                    <button
                      onClick={toggleCountryDropdown}
                      className=" text-white px-4 py-2 rounded font-jakarta hover:bg-[#1E1E1E] flex items-center"
                    >
                      Country
                    </button>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {isCountryDropdownOpen && (
                    <div className="absolute left-0 top-10 mt-2 w-48 bg-[#1E1E1E] border border-[#6F767E] z-10 rounded shadow-lg">
                      <div
                        className="px-4 py-2 hover:bg-gray-100 font-jakarta cursor-pointer"
                        onClick={() => selectCountry("Nigeria")}
                      >
                        Nigeria
                      </div>
                      <div
                        className="px-4 py-2 hover:bg-gray-100 font-jakarta cursor-pointer"
                        onClick={() => selectCountry("USA")}
                      >
                        USA
                      </div>
                      <div
                        className="px-4 py-2 hover:bg-gray-100 font-jakarta cursor-pointer"
                        onClick={() => selectCountry("Canada")}
                      >
                        Canada
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex">
                <a
                  href="/admin/users/new"
                  className="bg-[#DEA838] text-black px-4 py-2 font-jakarta rounded hover:bg-[#DEA838]"
                >
                  Add New
                </a>
              </div>
            </div>
            <div className="mt-5">

          <GUserTable
                searchValue={inputValue}
                usersData={usersData}
                setTriggerReload={setTriggerReload}
                triggerReload={triggerReload}
              />
            </div>
          </div>}

      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </AdminWrapper>
  );
};

export default Index;
