import { useState, useEffect } from "react";
import AdminWrapper from "../../components/AdminWrapper";
import TopBar from "../../components/topNav";
import SearchIcon from "@mui/icons-material/Search"; // Replace this with an icon component, or use an SVG if necessary
import PropTable from "./propTable";
import { userRequest } from "../../requestMethod";
// import { propData } from "./propData";
import AddProperty from "./AddProperty";
import EditProperty from "./EditProperty";
import EditTableProperty from "./Editable";

const AdminPropertiesPage = () => {
  const [propertiesData, setPropertiesData] = useState<any>("");
  const [isListPropertyPage, setIsListPropertyPage] = useState(true);
  const [isAddNewPage, setIsAddNewPage] = useState(false);
  const [isEditPage, setIsEditPage] = useState(false);
  const [propertyToEdit, setPropertyToEdit] = useState("");
  const [title, setTitle] = useState("Manage Properties");
  const [triggerReload, setTriggerReload] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: any) => {
    console.log(e.target.value);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    const getProperties = async () => {
      try {
        const properties = await userRequest("/admin/properties/list");
        setPropertiesData(properties?.data?.data?.data);
        // console.log(properties.data.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getProperties();
  }, [triggerReload]);

  const handleSearch = async () => {
    try {
      const res = await userRequest(`/admin/properties/list?search=${inputValue}`);
      setPropertiesData(res?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const showAddNewPage = () => {
    setIsAddNewPage(true);
    setIsListPropertyPage(false);
    setIsEditPage(false);
    setTitle("Add New Property");
  };

  const showEditPage = (property: any) => {
    setIsEditPage(true);
    setIsAddNewPage(false);
    setIsListPropertyPage(false);
    setPropertyToEdit(property);
    setTitle("Edit Property");
  };

  const showListPropertyPage = () => {
    setIsListPropertyPage(true);
    setIsEditPage(false);
    setIsAddNewPage(false);
    setTitle("Manage Properties");
    setTriggerReload((prev) => !prev);
  };

  return (
    <AdminWrapper>
      {isListPropertyPage && (
        <div className="flex justify-between font-jakarta items-center">
          <div className="flex items-center space-x-2">
            <input
              className="rounded-md border bg-transparent border-solid border-gray-500 text-sm py-2 px-3"
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Search properties"
            />
            <button
              className="bg-[#DEA838] text-[black] px-4 py-2 rounded-md"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          <div className="flex space-x-2">
            <div>
              <button className="border border-gray-500 px-4 py-2 rounded-md">
                Import
              </button>
            </div>
            <div className="mx-2">
              <button className="border border-gray-500 px-4 py-2 rounded-md">
                Export
              </button>
            </div>
            <div>
              <button
                className="bg-[#DEA838] text-[black] px-4 py-2 rounded-md"
                onClick={showAddNewPage}
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      )}

      {isListPropertyPage && (
        <div className="my-5">
          {propertiesData ? (
            <PropTable
              data={propertiesData}
              showEditPage={showEditPage}
              setTriggerReload={setTriggerReload}
              triggerReload={triggerReload}
            />
          ) : (
            <div className="flex justify-center items-center">
              <div className="spinner-border animate-spin" style={{ width: "24px", height: "24px", borderWidth: "3px" }}></div>
            </div>
          )}
        </div>
      )}

      {isAddNewPage && (
        <AddProperty showListPropertyPage={showListPropertyPage} />
      )}
      {
        isEditPage && (
          <EditTableProperty
          property={propertyToEdit}
          showListPropertyPage={showListPropertyPage}
        />
        )
      }
      {/* {isEditPage && (
        <EditProperty
          property={propertyToEdit}
          showListPropertyPage={showListPropertyPage}
        />
      )} */}
    </AdminWrapper>
  );
};

export default AdminPropertiesPage;
