import { useState } from "react";
import { formatPrice } from "../../utils/helpers";
import { FiMoreVertical } from "react-icons/fi"; // Import the vertical three-dots icon
import UpdateBooking from "./UpdateBooking";
import { FaTimes } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function BookingsTable(props: any) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set initial rows per page to 5
  const [popupVisible, setPopupVisible] = useState(false); // State to manage popup visibility
  const [selectedBooking, setSelectedBooking] = useState<any>(null); // State to manage the selected booking for the popup

  const totalPages = Math.ceil(props.userBookings.length / rowsPerPage);

  const handleChangePage = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const togglePopup = (booking: any) => {
    setSelectedBooking(booking); // Set the selected booking
    setPopupVisible(!popupVisible); // Toggle the popup visibility
  };
console.log(props.userBookings, "book")
  // Calculate the starting and ending index of the items being displayed
  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min((page + 1) * rowsPerPage, props.userBookings.length);

  // Function to determine the background color class based on the status
  const getStatusStyles = (status: string) => {
    if (!status) {
      // Fallback for null or empty status
      return "bg-transparent text-transparent";
    }
    switch (status) {
      case "Approved":
        return "bg-[#30FF51] bg-opacity-30 text-[#30FF51]";
      case "Decline":
        return "bg-[#FF5F00] bg-opacity-30 text-[#FF5F00]";
      case "Pending":
      default:
        return "bg-[#ECBA2F] bg-opacity-30 text-[#ECBA2F]";
    }
  };

  return (
    <div className="w-full overflow-hidden bg-black shadow-md rounded-lg">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-black rounded-lg border border-[#353945]">
          <thead className="bg-[#242424]">
            <tr className="bg-transparent h-12">
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Name</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Property</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Cost</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Check In</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Check Out</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]">Status</th>
              <th className="px-6 py-3 font-jakarta text-left text-[#B6B7B9]"></th>
            </tr>
          </thead>
          <tbody>
            {props?.userBookings
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => (
                <tr
                  key={row?.id}
                  className={`${
                    index % 2 === 0 ? "bg-black" : "bg-black"
                  } h-10`}
                >
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                    {row?.user?.first_name + " " + row?.user?.last_name}
                  </td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                    {row?.property?.name}
                  </td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                    {formatPrice(row?.total_price)}
                  </td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                    {row?.check_in?.replaceAll("-", "/")}
                  </td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                    {row?.check_out?.replaceAll("-", "/")}
                  </td>
                  <td className="px-6 py-3 font-jakarta whitespace-nowrap">
                  <span
                    className={`px-3 py-1 font-jakarta rounded-full ${getStatusStyles(
                      row.status
                    )}`}
                  >
                    {row.status}
                  </span>
                </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="relative flex justify-between items-center gap-2.5">
                      <span
                        className="cursor-pointer text-white"
                        onClick={() => togglePopup(row)}
                      >
                        <FiMoreVertical size={20} /> {/* Vertical three-dots icon */}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between px-4 py-2 bg-black">
        <div>
          <span>Showing {startIndex}-{endIndex} of {props.userBookings.length}</span>
        </div>
        <div className="flex items-center">
          <button
            className="p-2 text-[#DEA73A] rounded-md"
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 0}
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
          {Array.from({ length: totalPages }, (_, i) => i).map((pageIndex) => (
            <button
              key={pageIndex}
              className={`ml-2 px-2 py-0 rounded-[100%] ${
                pageIndex === page ? "bg-[#DEA73A] text-black" : ""
              }`}
              onClick={() => handleChangePage(pageIndex)}
            >
              {pageIndex + 1}
            </button>
          ))}
          <button
            className="ml-2 p-2 text-[#DEA73A] rounded-md"
            onClick={() => handleChangePage(page + 1)}
            disabled={page >= totalPages - 1}
          >
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      </div>

      {/* Popup */}
      {popupVisible && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 opacity-20 z-10"
            onClick={() => setPopupVisible(false)} // Close popup when clicking outside
          />
          <div className="absolute top-[100px] right-0 w-full z-20 flex justify-end">
            <div className="bg-[#06080A] w-[40%] border-l border-[#B6B7B9] h-full p-4 rounded shadow-lg">
              <div className="flex justify-end cursor-pointer">
                <FaTimes onClick={() => setPopupVisible(false)} />
              </div>
              <UpdateBooking />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
