import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import applicationLoanReducer from "./applicationLoanSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    
    loan: applicationLoanReducer,
    properties: "",
  },
});
