import React, { useEffect, useState } from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PersonIcon from '@mui/icons-material/Person';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { logo } from "../assets/images";

interface SideBarProps {
  setActiveLink: (link: string) => void;
}

const SideBar: React.FC<SideBarProps> = ({ setActiveLink }) => {
  const [activeLinkLocal, setActiveLinkLocal] = useState<string>('');

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (
      currentPath === "/admin/bookings" ||
      currentPath === "/portfolio/income-history" ||
      currentPath === "/portfolio/details" ||
      /^\/sell\/\d+$/.test(currentPath)
    ) {
      setActiveLinkLocal("bookings");
    } else if (currentPath === "/admin/properties" || /^\/properties\/\d+$/.test(currentPath)) {
      setActiveLinkLocal("properties");
    } else if (
      currentPath === "/admin/investments" || currentPath === "/admin/roipayroll" || currentPath === "/admin/investForm" ||
      /^\/bookings\/\d+$/.test(currentPath) ||
      currentPath === "/bookings/history"
    ) {
      setActiveLinkLocal("investment");
    } else if (currentPath === "/admin/users"  || /^\/admin\/users(\/\d+)?$/.test(currentPath) || currentPath === "/admin/users/new") {
      setActiveLinkLocal("users");
    } else if (currentPath === "/admin/withdraw" || currentPath === "/admin/withdraw") {
      setActiveLinkLocal("withdraw");
    } 
     else if (currentPath === "/admin/payments" || currentPath === "/admin/slotdetail") {
      setActiveLinkLocal("payment");
    } else if (currentPath === "/admin/support") {
      setActiveLinkLocal("support");
    } else {
      setActiveLinkLocal("overview");
    }
    
    // Set the active link in the parent component
    setActiveLink(activeLinkLocal);
  }, [setActiveLink, activeLinkLocal]);

  return (
    <div className="h-full px-[30px] bg-[#06080A] flex flex-col justify-between">
      <div>
        <div className="py-6">
          <img src={logo} alt="logo" className="w-44" />
        </div>
        <div className="flex flex-col mt-7 gap-2">
          <a
            href="/admin/overview"
            onClick={() => setActiveLink("overview")}
            className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'overview' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <GridViewIcon className="text-[14px]" />
            <span className="ml-2 text-[14px] font-jakarta">Overview</span>
          </a>
          <a
            href="/admin/properties"
            onClick={() => setActiveLink("properties")}
            className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'properties' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <WorkOutlineIcon className="text-[14px]" />
            <span className="ml-2 text-[14px] font-jakarta">Properties</span>
          </a>
          <a
            href="/admin/investments"
            onClick={() => setActiveLink("investment")}
            className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'investment' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <ApartmentIcon className="text-[14px]" />
            <span className="ml-2 text-[14px] font-jakarta">Investments</span>
          </a>
          <a
            href="/admin/users"
            onClick={() => setActiveLink("users")}
            className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'users' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <BookOnlineOutlinedIcon className="text-[14px]" />
            <span className="ml-2 text-[14px] font-jakarta">Users</span>
          </a>
          <a
            href="/admin/payments"
            onClick={() => setActiveLink("payment")}
            className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'payment' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <AccountBalanceWalletOutlinedIcon className="text-[14px]" />
            <span className="ml-2 text-[14px] font-jakarta">Payments</span>
          </a>
          <a
            href="/admin/bookings"
            onClick={() => setActiveLink("bookings")}
            className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'bookings' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <PersonIcon className="text-[14px]" />
            <span className="ml-2 text-[14px] font-jakarta">Bookings</span>
          </a>
          <a
            href="/admin/withdraw"
            onClick={() => setActiveLink("withdraw")}
            className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'withdraw' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <PersonIcon className="text-[14px]" />
            <span className="ml-2 text-[14px] font-jakarta">Withdraw</span>
          </a>
        </div>
      </div>
      <div className="mb-4">
        <a
          href="/admin/support"
          onClick={() => setActiveLink("support")}
          className={`w-full p-3 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'support' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
        >
          <HelpOutlineOutlinedIcon className="text-[14px]" />
          <span className="ml-2 text-[14px] font-jakarta">Support</span>
        </a>
      </div>
    </div>
  );
};

export default SideBar;
