import { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import {
  Box,
  Button,
  // IconButton,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
// import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import InvestmentCalculator from "./InvestmentCalculator";
import BarChart from "./BarChart";
import Map from "./Map";
// import Available from "../Properties/available";
import PhotoSlider from "./PhotoSlider";
import TopBar from "../../components/topNav";
import { Link, useParams } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { formatPrice } from "../../utils/helpers";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const PropertyDetails = () => {
  const params = useParams();
  const { id } = params;
  const [property, setProperty] = useState<any>();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPropertyData = async () => {
      const res = await userRequest(`/user/properties/show/${id}`);
      // console.log(res.data.data);
      setProperty(res.data.data);
    };

    getPropertyData();
  }, [id]);

  const data = [
    [60000, 70000, 80000],
    [30000, 40000, 50000],
    [0, 10000, 20000],
  ];
  const labels = ["2021", "2022", "2023"];
  const backgroundColors = [
    ["#DEA838", "#DEA838", "#DEA838"],
    [" #ffffff", " #ffffff", " #ffffff"],
    [" #B6B7B9", " #B6B7B9", " #B6B7B9"],
  ];
  return (
    <Wrapper>
      <div className="font-jakarta">
        <Link to="/admin/properties">
          <Button size="small" color="primary" variant="outlined" disableRipple>
            <ArrowBackIosIcon style={{ width: 10 }} className="font-jakarta"/> Back to Properties
          </Button>
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <p className="text-[26px] font-jakarta">{property?.name}</p>
            <p className="text-[14px] font-jakarta" style={{ color: "#cccccc" }}>{property?.location?.address}</p>
          </div>
        </div>

        <div className="font-jakarta" style={{ margin: "15px 0" }}>
          {property?.files && <PhotoSlider images={property?.files} />}
        </div>


        {/* box occupancy section starts*/}
        <div className="my-[30px]">
          <div className="p-2.5 px-5 bg-[#1E1E1E] rounded shadow-md">
            <div className="flex items-center mb-8">
              <div className="border-2 border-[#353945] rounded-lg p-2 mr-2">
                <LocationOnOutlinedIcon className="text-[#B6B7B9]" />
              </div>
              <div>
                <p className="text-[14px] font-[600] font-jakarta">{property?.location?.country}</p>
                <p className="text-[#9A9FA5] text-[12px] font-jakarta">
                  A mature real estate market with favourable returns.
                </p>
              </div>
            </div>
            <div className="flex items-center mb-8">
              <div className="border-2 border-[#353945] rounded-lg p-2 mr-2">
                <HomeOutlinedIcon className="text-[#B6B7B9] font-jakarta " />
              </div>
              <div>
                <p className="text-[14px] font-[600] font-jakarta">
                  Average occupancy {property?.costings?.average_occupancy || 0}%
                </p>
                <p className="text-[#9A9FA5] text-[12px] font-jakarta">
                  Daily rates and occupancy levels will vary by season
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="border-2 border-[#353945] rounded-lg p-2 mr-2">
                {/* <AutoGraphOutlinedIcon className="text-[#B6B7B9]" /> */}
                <img src="/assets/images/line.svg" alt="line" className="w-[20px] h-[20px]" />
              </div>
              <div>
                <p className="text-[14px] font-[600] font-jakarta">
                  Projected Annual yield {property?.costings?.projected_annual_yield || 0}%
                </p>
                {/* <p className="text-[#B6B7B9]">
                  {property?.costings?.projected_annual_yield_subtext}
                </p> */}
                <p className="text-[#9A9FA5] text-[12px] font-jakarta">
                  Daily rates and occupancy levels will vary by season
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* box occupancy section ends*/}

        <div>
          <div className="flex lg:flex-row flex-col lg:justify-between lg:gap-10 gap-4">
            {/* Facilities section starts*/}
            <div className="lg:w-[65%] w-full">
              <div className="flex justify-start gap-[50px] py-[20px] px-[30px] mb-5 bg-[#1E1E1E] rounded-[10px]">
                <div>
                  <p className="text-[#9A9FA5] text-[14px] pb-[10px] font-jakarta">Bedrooms</p>
                  <div className="flex justify-around items-center">
                    <BedOutlinedIcon className=" text-[#9A9FA5]" />
                    <p className="text-[14px] font-jakarta">{property?.features?.bedrooms}</p>
                  </div>
                </div>
                <div>
                  <p className="text-[#9A9FA5] text-[14px] pb-[10px] font-jakarta">Bathrooms</p>
                  <div className="flex justify-around items-center">
                    <BathtubOutlinedIcon className=" text-[#9A9FA5]" />
                    <p className="text-[14px] font-jakarta">{property?.features?.bathrooms}</p>
                  </div>
                </div>
                <div>
                  <p className="text-[#9A9FA5] text-[14px] pb-[10px] font-jakarta">Square Area</p>
                  <div className="flex justify-around items-center">
                    <SpaceDashboardOutlinedIcon className=" text-[#9A9FA5]" />
                    <p className="text-[14px] font-jakarta">{property?.features?.sqft} Sq.Ft.</p>
                  </div>
                </div>

              </div>

              <div>
                <p className="text-[20px] text-[#FCFCFC] py-[20px] font-jakarta">Properties Overview</p>
                <p className="text-[14px] text-[#9A9FA5] text-justify font-jakarta leading-[30px]">{property?.description}</p>
              </div>
            </div>
            {/* facilities section ends*/}
            <div className="lg:w-[35%] w-full text-center bg-[#1E1E1E] pb-[30px] px-[30px] rounded-[10px]">
              <div>
                <p className="text-[20px] py-[30px] font-jakarta">Financial summary</p>
                <hr className="border-t-[1px] border-[#33383F]" />
              </div>
              <div>
                <div className="py-[20px]">
                  <p className="text-[#9A9FA5] text-[14px] font-jakarta">Actual Property Price</p>
                  <p className="text-[14px] font-jakarta">£ {property?.costings?.price}</p>
                </div>
                <hr className="border-t-[1px] border-[#33383F]" />
              </div>
              <div>
                <div className="py-[20px]">
                  <p className="text-[#9A9FA5] text-[14px] font-jakarta">Actual Per Slot</p>
                  <p className="text-[14px] font-jakarta">£ {property?.costings?.per_Slot}</p>
                </div>
                <hr className="border-t-[1px] border-[#33383F]" />
              </div>
              <p className="text-[#9A9FA5] py-[20px] font-jakarta">{property?.costings?.available_slots}/{property?.costings?.total_slot} Available Slots</p>
              <div className="bg-[#33383F] flex flex-col gap-3 p-[20px] rounded-[10px]">
                <div className="flex justify-between items-center">
                  <p className="text-[#9A9FA5] text-[14px] font-jakarta">first year projection</p>
                  <p className="font-jakarta">£ {property?.financial_summary?.first_year}</p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-[#9A9FA5] text-[14px] font-jakarta">fifth year projection</p>
                  <p className="font-jakarta">£ {property?.financial_summary?.fifth_year}</p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-[#9A9FA5] text-[14px] font-jakarta">tenth year projection</p>
                  <p className="font-jakarta">£ {property?.financial_summary?.tenth_year}</p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col lg:justify-between lg:gap-10 gap-4 py-[40px]">
          <div className="lg:w-1/2 w-full">
            <div>
              <p className="text-[16px] font-jakarta py-[20px]">Financial Details</p>
              <div className="border border-[#33383F] flex flex-col p-[10px] rounded-[10px]">
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] font-jakarta text-[#9A9FA5]">Property Cost</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.price)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] font-jakarta text-[#9A9FA5]">Maihomm fee</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.maihomm_fee)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] font-jakarta text-[#9A9FA5]">Legal and closing cost</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.legal_and_closing_cost)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] font-jakarta text-[#9A9FA5]">Property Acq cost</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.property_acq_cost)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[#DEA838] font-jakarta text-[14px]">Per slot</p>
                  <p className="text-[#DEA838] font-jakarta text-[16px]">{formatPrice(property?.costings?.per_Slot)}</p>
                </div>
              </div>

            </div>

          </div>

          <div className="lg:w-1/2 w-full">
            <div>
              <p className="text-[16px] py-[20px] font-jakarta">Projected Returns</p>
              <div className="border border-[#33383F] flex flex-col p-[10px] rounded-[10px]">
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] text-[#9A9FA5] font-jakarta">Projected Gross Rent</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.projected_gross_rent)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] text-[#9A9FA5] font-jakarta">Management fee</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.management_fees)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] text-[#9A9FA5] font-jakarta">Service Charge</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.service_charge)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[14px] text-[#9A9FA5] font-jakarta">Projected Net Rent</p>
                  <p className="text-[16px] font-jakarta">{formatPrice(property?.costings?.projected_annual_net_rental_income)}</p>
                </div>
                <div className="flex justify-between items-center my-3">
                  <p className="text-[#DEA838] text-[14px] font-jakarta">Per slot</p>
                  <p className="text-[#DEA838] text-[16px] font-jakarta">{formatPrice(property?.costings?.per_Slot)}</p>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* <div className="py-[20px]">
          <p className="text-[16px] font-jakarta">investment Calculator</p>
          <p className="text-[#9A9FA5] text-[14px] font-jakarta">EARNING</p>
          <p className="text-[14px] font-jakarta">{property?.costings?.projected_annual_net_rental_income}</p>
          <div className="mt-5">
            <p className="text-[#9A9FA5] text-[14px] font-jakarta">No of slots</p>
            <NumProgressBar value={property?.costings?.available_slots} />
            <div className="flex justify-between w-[40%]">
              <p className="text-[14px] font-jakarta">1</p>
              <p className="text-[14px] font-jakarta">{property?.costings?.total_slot}</p>
            </div>
          </div>
          <div className="my-5">
            <p className="text-[#9A9FA5] text-[14px] font-jakarta">Amount</p>
          <ProgressBar value={20000} />
            <div className="flex justify-between w-[40%]">
              <p className="text-[14px] font-jakarta">4,000</p>
              <p className="text-[14px] font-jakarta">50,000</p>
            </div>
          </div>
          <div>
            <p className="text-[#9A9FA5] text-[14px] font-jakarta">Year</p>
          <YearProgressBar year={currentYear + 3} />
            <div className="flex justify-between w-[40%]">
              <p className="text-[14px] font-jakarta">2024</p>
              <p className="text-[14px] font-jakarta">2028</p>
            </div>
          </div>
        </div> */}
        <div>
          <div>
            <p className="py-[20px] text-[14px] font-jakarta">Map</p>
            <Map />
            <div className="flex items-center text-[#DEA838]">
              <p className="py-[20px] text-[14px] font-jakarta">See more properties in warrington</p>
              <MdOutlineKeyboardArrowRight />
            </div>
          </div>
         
          {/* <FutureProperty/> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default PropertyDetails;
