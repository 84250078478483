import React from 'react';

interface CardProps {
  imageSrc: string;
  title: string;
  address: string;
  price: string;
  handshakeIconSrc: string;
  vectorIconSrc: string;
  bedrooms: string;
  bathrooms: string;
  area: string;
  bedIconSrc: string;
  bathIconSrc: string;
  dashboardIconSrc: string;
}

const Card: React.FC<CardProps> = ({
  imageSrc,
  title,
  address,
  price,
  handshakeIconSrc,
  vectorIconSrc,
  bedrooms,
  bathrooms,
  area,
  bedIconSrc,
  bathIconSrc,
  dashboardIconSrc,
}) => {
  return (
    <div className="flex flex-col w-full bg-[#1E1E1E]">
      <img src={imageSrc} alt="Placeholder" className="w-full h-full object-cover mb-2" />
      <div className="p-4">
        <p className="text-[20px] leading-[30px]">{title}</p>
        <p className="text-[14px] leading-[30px] text-[#FCFCFC]">{address}</p>
      </div>
      <div className="p-4">
        <div className="flex justify-between">
          <p className="text-[16px]">{price}</p>
          <div className="flex gap-2">
            <img src={handshakeIconSrc} className="h-[20px] w-[20px]" alt="Handshake Icon" />
            <img src={vectorIconSrc} className="h-[20px] w-[20px]" alt="Vector Icon" />
          </div>
        </div>
        <hr className="border-t-2 border-[#B6B7B9] mt-2" />
        <div className="flex justify-between my-4">
          <div className="flex gap-2 items-center">
            <img src={bedIconSrc} className="h-[20px] w-[20px]" alt="Bed Icon" />
            <p className="text-[#FCFCFC]">{bedrooms}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={bathIconSrc} className="h-[20px] w-[20px]" alt="Bath Icon" />
            <p className="text-[#FCFCFC]">{bathrooms}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={dashboardIconSrc} className="h-[20px] w-[20px]" alt="Dashboard Icon" />
            <p className="text-[#FCFCFC]">{area}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
