import React, { useState } from 'react';
import { Button } from '@mui/material';
import BasicInformation from './BasicInformation';
import Location from './Location';
import Costing from './Costing';
import OtherInformation from './OtherInformation';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BookingManagement from './BookingManage';

interface AddPropertyProps {
  showListPropertyPage: () => void; // Define the function type for showListPropertyPage
  property: any
}

const EditTableProperty: React.FC<AddPropertyProps> = ({ showListPropertyPage, property}) => {
  const [sectionsState, setSectionsState] = useState({
    basicInformation: { enabled: true, filled: false, open: true },
    location: { enabled: true, filled: false, open: false },
    costing: { enabled: true, filled: false, open: false },
    otherInformation: { enabled: true, filled: false, open: false },
    bookingManagement: { enabled: true, filled: false, open: false },
  });

  const enableNextSection = (section: keyof typeof sectionsState) => {
    setSectionsState((prev) => ({
      ...prev,
      [section]: { ...prev[section], filled: true },
      [getNextSection(section)]: { ...prev[getNextSection(section)], enabled: true, open: true },
    }));
  };

  const getNextSection = (currentSection: keyof typeof sectionsState) => {
    const sections = Object.keys(sectionsState) as Array<keyof typeof sectionsState>;
    const currentIndex = sections.indexOf(currentSection);
    return sections[currentIndex + 1] || 'bookingManagement';
  };

  const getPreviousSection = (currentSection: keyof typeof sectionsState) => {
    const sections = Object.keys(sectionsState) as Array<keyof typeof sectionsState>;
    const currentIndex = sections.indexOf(currentSection);
    return sections[currentIndex - 1] || 'basicInformation';
  };

  const toggleSection = (section: keyof typeof sectionsState) => {
    setSectionsState((prev) => ({
      ...prev,
      [section]: { ...prev[section], open: !prev[section].open },
    }));
  };

  const handlePrevious = (section: keyof typeof sectionsState) => {
    const prevSection = getPreviousSection(section);
    setSectionsState((prev) => ({
      ...prev,
      [section]: { ...prev[section], open: false },
      [prevSection]: { ...prev[prevSection], open: true },
    }));
  };

  const [propertyData, setPropertyData] = useState({
    propertyName: '',
    bedroom: '',
    bathroom: '',
    squareArea: '',
    description: ''
  });

  const handleSaveBasicInformation = (data: any) => {
    setPropertyData(data);
    enableNextSection('basicInformation');
  };

  const [locationData, setLocationData] = useState<any>(null);

  const handleSaveLocation = (data: any) => {
    setLocationData(data);
    enableNextSection('location');
    console.log('Location Data Saved:', data);
  };

  const handleSaveCosting = (costingData: object) => {
    enableNextSection('costing');
    console.log('Costing Data:', costingData);
  };

  console.log(propertyData, "data");
  console.log(locationData, "ldata");

  return (
    <div className="p-4 font-jakarta">
      <Button
        onClick={showListPropertyPage}
        size="small"
        color="primary"
        variant="outlined"
        disableRipple
      >
        <ArrowBackIosIcon style={{ width: 10 }} /> Back to Properties
      </Button>
      <div>
        {/* Basic Information Section */}
        <div>
          <button
            className={`flex justify-between items-center w-full p-2 rounded mt-2 ${
              sectionsState.basicInformation.open ? 'bg-[#242424]' : 'bg-[#242424]'
            } ${sectionsState.basicInformation.filled ? 'text-yellow-500' : 'text-white'}`}
            onClick={() => toggleSection('basicInformation')}
          >
            <span>Basic Information</span>
            {sectionsState.basicInformation.open ? <FaChevronUp className="text-white" /> : <FaChevronDown className="text-white" />}
          </button>
          {sectionsState.basicInformation.open && (
            <BasicInformation onSave={handleSaveBasicInformation} />
          )}
        </div>

        {/* Location Section */}
        <div>
          <button
            className={`flex justify-between items-center w-full p-2 rounded mt-2 ${
              sectionsState.location.open ? 'bg-[#242424]' : 'bg-[#242424]'
            } ${sectionsState.location.filled ? 'text-yellow-500' : sectionsState.basicInformation.filled ? 'text-white' : 'text-gray-400'}`}
            onClick={() => toggleSection('location')}
          >
            <span>Location</span>
            {sectionsState.location.open ? <FaChevronUp className="text-white" /> : <FaChevronDown className="text-white" />}
          </button>
          {sectionsState.location.open && (
            <Location
              onSave={handleSaveLocation}
              onPrevious={() => handlePrevious('location')}
            />
          )}
        </div>

        {/* Costing Section */}
        <div>
          <button
            className={`flex justify-between items-center w-full p-2 rounded mt-2 ${
              sectionsState.costing.open ? 'bg-[#242424]' : 'bg-[#242424]'
            } ${sectionsState.costing.filled ? 'text-yellow-500' : sectionsState.location.filled ? 'text-white' : 'text-gray-400'}`}
            onClick={() => toggleSection('costing')}
          >
            <span>Costing</span>
            {sectionsState.costing.open ? <FaChevronUp className="text-white" /> : <FaChevronDown className="text-white" />}
          </button>
          {sectionsState.costing.open && (
            <Costing
              onSave={handleSaveCosting}
              onPrevious={() => handlePrevious('costing')}
            />
          )}
        </div>

        {/* Other Information Section */}
        <div>
          <button
            className={`flex justify-between items-center w-full p-2 rounded mt-2 ${
              sectionsState.otherInformation.open ? 'bg-[#242424]' : 'bg-[#242424]'
            } ${sectionsState.otherInformation.filled ? 'text-yellow-500' : sectionsState.costing.filled ? 'text-white' : 'text-gray-400'}`}
            onClick={() => toggleSection('otherInformation')}
          >
            <span>Other Information</span>
            {sectionsState.otherInformation.open ? <FaChevronUp className="text-white" /> : <FaChevronDown className="text-white" />}
          </button>
          {sectionsState.otherInformation.open && (
            <OtherInformation
              onSave={() => enableNextSection('otherInformation')}
              onPrevious={() => handlePrevious('otherInformation')}
            />
          )}
        </div>

        {/* Booking Management Section */}
        <div>
          <button
            className={`flex justify-between items-center w-full p-2 rounded mt-2 ${
              sectionsState.bookingManagement.open ? 'bg-[#242424]' : 'bg-[#242424]'
            } ${sectionsState.bookingManagement.filled ? 'text-yellow-500' : sectionsState.otherInformation.filled ? 'text-white' : 'text-gray-400'}`}
            onClick={() => toggleSection('bookingManagement')}
          >
            <span>Booking Management</span>
            {sectionsState.bookingManagement.open ? <FaChevronUp className="text-white" /> : <FaChevronDown className="text-white" />}
          </button>
          {sectionsState.bookingManagement.open && (
            <BookingManagement
              onSave={() => enableNextSection('bookingManagement')}
              onPrevious={() => handlePrevious('bookingManagement')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTableProperty;
