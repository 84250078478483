// import React, { useEffect, useRef } from 'react';
// import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { Container, Paper } from '@mui/material';

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Interpolation Line',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      tension: 0.4, // Set tension for interpolation
      borderColor: '#83BF6E',
      color: 'white',
      borderWidth: 2,
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const InterpolationLineChart = () => {
  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <p>Real time investments</p>
        <p>£120,000.00 </p>
        <Line data={data} options={options} />
      </Paper>
    </Container>
  );
};

export default InterpolationLineChart;
