// src/components/FilterBar.tsx
import React, { useState } from 'react';
import All from './all';

const FilterBar: React.FC = () => {
  const [selected, setSelected] = useState<string>('All');

  return (
    <div className="p-4 text-white">
      <div className="flex items-center justify-between">
        <div className="flex bg-[#1E1E1E] p-2 items-center space-x-4">
          <button
            onClick={() => setSelected('All')}
            className={`flex items-center space-x-1 px-3 py-1 rounded-full ${
              selected === 'All' ? 'bg-yellow-500 text-black' : ''
            }`}
          >
            <span>All</span>
          </button>
          <button
            onClick={() => setSelected('Available')}
            className={`flex items-center space-x-1 px-3 py-1 rounded-full ${
              selected === 'Available' ? 'bg-yellow-500 text-black' : ''
            }`}
          >
            <span className='text-green-500 text-[20px]'>•</span>
            <span>Available</span>
          </button>
          <button
            onClick={() => setSelected('Sold out')}
            className={`flex items-center space-x-1 px-3 py-1 rounded-full ${
              selected === 'Sold out' ? 'bg-yellow-500 text-black' : ''
            }`}
          >
            <span className='text-red-500 text-[20px]'>•</span>
            <span>Sold out</span>
          </button>
          <button
            onClick={() => setSelected('Coming soon')}
            className={`flex items-center space-x-1 px-3 py-1 rounded-full ${
              selected === 'Coming soon' ? 'bg-yellow-500 text-black' : ''
            }`}
          >
            <span className='text-yellow-500 text-[20px]'>•</span>
            <span>Coming soon</span>
          </button>
        </div>
        <div className="relative">
          <input
            type="text"
            className="border border-[#33383F] bg-black text-white rounded-[10px] px-4 py-2 pl-8 focus:outline-none"
            placeholder="Search"
          />
          <svg
            className="w-4 h-4 text-white absolute right-3 top-1/2 transform -translate-y-1/2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-4.35-4.35m0 0a7 7 0 10-9.9 0 7 7 0 009.9 0z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="mt-4">
        {selected === 'All' && <All />}
        {selected === 'Available' && <p>Why</p>}
        {selected === 'Sold out' && <p>Love</p>}
        {selected === 'Coming soon' && <p>Coming Soon</p>}
      </div>
    </div>
  );
};

export default FilterBar;
