import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import {
    Grid,
    IconButton,
    Link,
} from '@mui/material';
import Cookies from 'js-cookie';

const ProfileNav = () => {
    return (
        <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
            <Link
                color="inherit"
                href='/profile'
                sx={{
                    '&:hover': {
                        backgroundColor: '#1E1E1E',
                        color: '#FCFCFC',
                        borderRadius: 2,
                    },
                }}
                style={{ width: '80%', padding: '5px 10px' }}
            >

                <IconButton>
                    <PersonIcon />
                </IconButton>
                Profile
            </Link>

            <Link
                color="inherit"
                href='/settings'
                sx={{
                    '&:hover': {
                        backgroundColor: '#1E1E1E',
                        color: '#FCFCFC',
                        borderRadius: 2,

                    },
                }}
                style={{ width: '80%', padding: '5px 10px' }}
            >
                <IconButton>
                    <SettingsIcon />
                </IconButton>
                Settings
            </Link>
            <Link
                onClick={()=>Cookies.remove('adminUserData_maihomm')}
                color="inherit"
                href="/"
                sx={{
                    '&:hover': {
                        backgroundColor: '#1E1E1E',
                        color: '##FCFCFC',
                        borderRadius: 2,
                        cursor: 'pointer'

                    },
                }}
                style={{ width: '80%', padding: '5px 10px' }}
            >

                <IconButton>
                    <LogoutIcon />
                </IconButton>
                Log Out
            </Link>
        </Grid>
    )
}

export default ProfileNav