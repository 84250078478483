import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Button, CircularProgress, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUserSuccess, loginUserFailure } from "../../services/loginSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { publicRequest } from "../../requestMethod";
import SideInfo from "./SideInfo";
import { authImage, logo } from "../../assets";
import { FiEye, FiEyeOff } from "react-icons/fi"; // Import icons from react-icons

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
  const [inputStyles, setInputStyles] = useState<{ email: string; password: string }>({
    email: "border-[#33383F]",
    password: "border-[#33383F]"
  });
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const user = Cookies.get("adminUserData_maihomm");
  //   if (user) {
  //     navigate("/admin/overview");
  //     window.location.reload();
  //   }
  // }, [navigate]);

  const validate = () => {
    const validationErrors: { email?: string; password?: string } = {};
    const newInputStyles = { email: "border-[#33383F]", password: "border-[#33383F]" };

    if (!formData.email) {
      validationErrors.email = "Email is required";
      newInputStyles.email = "border-red-500"; // Change border color to red
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Invalid email";
      newInputStyles.email = "border-red-500"; // Change border color to red
    }

    if (!formData.password) {
      validationErrors.password = "Password is required";
      newInputStyles.password = "border-red-500"; // Change border color to red
    } else if (formData.password.length < 6) {
      validationErrors.password = "Password must have at least 6 characters";
      newInputStyles.password = "border-red-500"; // Change border color to red
    }

    setErrors(validationErrors);
    setInputStyles(newInputStyles);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;
    console.log("Login Request Body:", formData);
    try {
      setLoading(true);
      const response = await publicRequest.post("/login", {
        email: formData.email,
        password: formData.password,
      });
      const userData = response.data;
      Cookies.set('adminUserSettings_maihomm', JSON.stringify(userData.data.settings));
      if (userData.data.settings.two_factor_auth === 1) {
        Cookies.set("pendingAdminUser_maihomm", JSON.stringify(userData.data));
        navigate("/auth/twofactor");
        window.location.reload();
      } else {
        dispatch(loginUserSuccess(userData));
        Cookies.set("adminUserData_maihomm", JSON.stringify(userData.data));
        toast.success("Login Successful");
        setTimeout(() => {
          navigate("/admin/overview");
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      toast.error("Invalid email or password");
      dispatch(loginUserFailure(error));
    } finally {
      setLoading(false);
    }
  };

  // Clear error messages after 2 seconds
  useEffect(() => {
    if (errors.email || errors.password) {
      const timer = setTimeout(() => {
        setErrors({});
        setInputStyles({ email: "border-[#33383F]", password: "border-[#33383F]" }); // Reset input styles
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errors]);

  return (
    <>
      <div className="flex">
        <SideInfo />
        <div className="flex-1 flex justify-center absolute w-full  items-center pt-10 pb-10">
          <img
            src={logo}
            alt=""
            className="absolute top-0 z-10 h-[76px] pt-[30px] w-[230px]"
          />
          <form onSubmit={handleSubmit} className="max-w-md mt-[4%] rounded-[30px] border border-[#6F767E] custom-scroll-content w-full p-[30px]" style={{ background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)" }}>
            <div className="mb-6">
              <p className="font-semibold text-[30px]">
                Log in to your account
              </p>
              <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">Welcome back! Sign into your account to continue</p>
             
              <p className="mb-2">Email</p>
              <input
                type="email"
                placeholder="Enter your email address"
                name="email"
                className={`text-white bg-[#1E1E1E] rounded-[10px] font-jakarta border ${inputStyles.email} outline-none mt-1 px-4 py-[12px] w-full`}
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
              {errors.email && <p className="text-red-500 mb-2">{errors.email}</p>}
            </div>
            <div className="mb-6 relative">
              <p className="mb-2">Password</p>
              <input
                type={showPassword ? "text" : "password"} // Toggle password visibility
                name="password"
                className={`text-white bg-[#1E1E1E] rounded-[10px] font-jakarta border ${inputStyles.password} outline-none mt-1 px-4 py-[12px] w-full pr-12`} // Added pr-12 for icon space
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex top-8 items-center pr-3"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff className="text-[#6F767E]" /> : <FiEye className="text-[#6F767E]" />} {/* Toggle icon */}
              </button>
              {errors.password && <p className="text-red-500 mb-2">{errors.password}</p>}
            </div>
            <div className="flex justify-end my-2">
              <Link href="/resetpassword" className="text-[14px]"> Forgot your password? </Link>
            </div>

            <div className="text-center mt-4">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="w-full"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Login"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
