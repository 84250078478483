import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { publicRequest } from "../../requestMethod";
import {
  registerUserFailure,
  registerUserSuccess,
} from "../../services/registerSlice";
import { useDispatch } from "react-redux";
import SideInfo from "./SideInfo";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Name must have at least 2 characters")
    .required("required"),
  last_name: Yup.string()
    .min(2, "Name must have at least 2 characters")
    .required("required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must have at least 6 characters")
    .required("Password is required"),
  passwordConfirmed: Yup.string().oneOf(
    [Yup.ref("password")],
    "Password must match"
  ),
  phone_no: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid phone number")
    .required("Phone number is required"),
});

const Register: React.FC = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const user = Cookies.get("adminUserData_maihomm");
    if (user) {
      navigate("/admin/overview");
      window.location.reload();
    }
  }, [navigate]);
  const handleRegister = async (
    values: {
      first_name: string;
      last_name: string;
      email: string;
      phone_no: string;
      password: string;
      passwordConfirmed: string;
    },
    setSubmitting: any
  ) => {
    try {
      if (checked) {
        setSubmitting(true);
        // setIsLoading(true);
        console.log(values);
        const res = await publicRequest.post("/register", {
          name: values.first_name + " " + values.last_name,
          email: values.email,
          phone_no: String(values.phone_no),
          password: values.password,
        });
        const userData = res.data; // Update this based on your API response
        dispatch(registerUserSuccess(userData));
        Cookies.set("adminUserData_maihomm", JSON.stringify(userData.data));
        // Cookies.set("userData", JSON.stringify(userData.data));
        Cookies.set("adminUserSettings_maihomm", JSON.stringify(userData.data.settings));
        toast.success("Account created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
        setTimeout(() => {
          navigate("/admin/overview");
          window.location.reload();
        }, 2000);
      } else {
        toast.error(
          "agree to Maihomm's Terms of Use & Privacy Policy to continue",
          {
            // position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
            hideProgressBar: true,
          }
        );
      }
    } catch (err: any) {
      toast.error(err.response.data.message);
      dispatch(registerUserFailure(err));
      // console.log(err);
    } finally {
      // setIsLoading(false);
      setSubmitting(false);
    }
  };

  const handleChecked = (event: any) => {
    setChecked(event.target.checked);
  };

  const textFieldStyle = {
    fontSize: "12px",
    backgroundColor: "#1E1E1E",
    borderRadius: "10px",
    marginTop: "5px",
    padding: "10px 15px",
  };

  return (
    <>
      <Grid container>
        <SideInfo />
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: { xs: "40px", md: 0 },
            paddingBottom: { xs: "40px", md: 0 },
          }}
        >
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              phone_no: "",
              password: "",
              passwordConfirmed: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleRegister(values, setSubmitting);
            }}
          >
            {({ values, errors, isSubmitting }) => (
              <Form style={{ maxWidth: "600px", width: "85%" }}>
                <div className="form-control">
                  <Typography
                    mb="30px"
                    variant="subtitle1"
                    sx={{ fontWeight: 600, fontSize: "24px" }}
                  >
                    Create your account
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        First Name
                      </Typography>
                      <Field
                        as={TextField}
                        type="text"
                        // label="email"
                        name="first_name"
                        size="small"
                        variant="standard"
                        style={textFieldStyle}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        fullWidth
                      />
                      <ErrorMessage name="first_name" component="div" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Last Name
                      </Typography>
                      <Field
                        as={TextField}
                        type="text"
                        // label="email"
                        name="last_name"
                        size="small"
                        variant="standard"
                        style={textFieldStyle}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        fullWidth
                      />
                      <ErrorMessage name="last_name" component="div" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Email
                      </Typography>
                      <Field
                        as={TextField}
                        type="email"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        // label="email"
                        variant="standard"
                        name="email"
                        size="small"
                        style={textFieldStyle}
                        fullWidth
                      />
                      <ErrorMessage name="email" component="div" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Phone Number
                      </Typography>
                      <Field
                        as={TextField}
                        type="number"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        // label="email"
                        variant="standard"
                        name="phone_no"
                        size="small"
                        style={textFieldStyle}
                        fullWidth
                      />
                      <ErrorMessage name="phone_no" component="div" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Password
                      </Typography>
                      <Field
                        as={TextField}
                        type="password"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        // label="email"
                        variant="standard"
                        name="password"
                        size="small"
                        style={textFieldStyle}
                        fullWidth
                      />
                      <ErrorMessage name="password" component="div" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Confirm Password
                      </Typography>
                      <Field
                        as={TextField}
                        type="password"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        // label="email"
                        variant="standard"
                        name="passwordConfirmed"
                        size="small"
                        style={textFieldStyle}
                        fullWidth
                      />
                      <ErrorMessage name="passwordConfirmed" component="div" />
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={handleChecked}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="I agree to Maihomm's Terms of Use & Privacy Policy"
                  />
                </div>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                  <p>
                    Already have an account?{" "}
                    <Link href="/auth/login"> log in</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
