import { Link } from "react-router-dom";
import AdminWrapper from "../../components/AdminWrapper";
import { MdOutlineArrowBackIos, MdSearch } from "react-icons/md";
import { LuPencil } from "react-icons/lu";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { GoTrash } from "react-icons/go";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";


export default function RoiPayroll() {
  const [investmentsData, setInvestmentData] = useState<any[]>([]);

  useEffect(() => {
    const getInvestments = async () => {
      try {
        const investments = await userRequest("/admin/payouts/list");
        console.log(investments, "data"); // Check the structure of the response
        setInvestmentData(investments.data.data); // Assuming this is the correct path
      } catch (err) {
        console.log(err);
      }
    };
    getInvestments();
  }, []);
  
  return (
    <AdminWrapper>
      <div className="">
        <Link to={"/admin/investments"} className="flex gap-2 items-center mb-4">
          <MdOutlineArrowBackIos />
          <p>ROI Payout</p>
        </Link>

        {/* Flexbox container with input and button */}
        <div className="flex justify-between items-center mb-4">
          <div className="relative w-[25%]">
            <input 
              type="text" 
              placeholder="Enter ROI amount" 
              className="bg-[#2E2E2E] text-white p-2 pr-10 outline-none rounded-md w-full border-none"
            />
            <MdSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-white" />
          </div>
          <Link to={"/admin/investForm"} 
            className="bg-[#DEA838] text-black py-2 px-4 rounded-md ml-4 hover:bg-[#CFA02C]"
          >
            Create ROI
          </Link>
        </div>

      </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-[#1E1E1E] rounded-lg">
            <thead>
              <tr className="bg-[#242424]">
                <th className="px-4 py-2 text-left text-[#B6B7B9]">User</th>
                <th className="px-4 py-2 text-left text-[#B6B7B9]">Property</th>
                <th className="px-4 py-2 text-left text-[#B6B7B9]">Month</th>
                <th className="px-4 py-2 text-left text-[#B6B7B9]">Amount</th>
                <th className="px-4 py-2 text-left text-[#B6B7B9]">Date</th>
                <th className="px-4 py-2 text-left text-[#B6B7B9]"></th>
              </tr>
            </thead>
            <tbody>
            {Array.isArray(investmentsData) && investmentsData.map((row, index) => (
                <tr key={index} className="border-b border-[#353945]">
                  <td className="px-4 py-2 text-[#B6B7B9]">{row.user}</td>
                  <td className="px-4 py-2 text-[#B6B7B9]">{row.reference}</td>
                  <td className="px-4 py-2 text-[#B6B7B9]">{row.amount}</td>
                  <td className="px-4 py-2 text-[#B6B7B9]">{row.date}</td>
                  <td className="px-4 py-2 text-[#B6B7B9]">{row.status}</td>
                  <td className="px-4 py-2 text-[#B6B7B9]">
                    <div className="flex space-x-2">
                    <LuPencil />
                    <MdOutlineRemoveRedEye />
                    <GoTrash className="text-[#FC8A8A]"/>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </AdminWrapper>
  );
}
