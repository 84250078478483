import React, { useEffect, useState } from 'react';
import TopBar from '../../components/topNav';
import AdminWrapper from '../../components/AdminWrapper';
import BarChart from './chart';
import { toast } from 'react-toastify';
import { userRequest } from '../../requestMethod';
import Propos from './card';
import { IoIosTrendingUp } from "react-icons/io";
import RecentTransaction from './recentTransaction';

const Overview = () => {
  const [monthFigures, setMonthFigures] = useState<number[]>([]);
  const [monthLabels, setMonthLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [overview, setOverview] = useState<null | any>({});
  const backgroundColors = [
    'rgba(255, 99, 132, 0.6)', // Section 1 background color
    'rgba(54, 162, 235, 0.6)', // Section 2 background color
    'rgba(75, 192, 192, 0.6)', // Section 3 background color
  ];

  useEffect(() => {
    const getOverviewReport = async () => {
      try {
        setLoading(true);
        const res = await userRequest("/admin/overview");
        const chartData = res.data.data.registered_users_chart;
        populateChart(chartData);
        setOverview(res.data);
      } catch (err: any) {
        toast.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    getOverviewReport();
  }, []);

  function populateChart(usersChart: Record<string, { amount_joined: number }>) {
    const dataLabels = Object.keys(usersChart); // Extracts the month labels
    const dataFigures = Object.values(usersChart).map(chart => chart.amount_joined); // Extracts the amount joined

    setMonthLabels(dataLabels);
    setMonthFigures(dataFigures);
  }
console.log(overview, "over")
  return (
    <AdminWrapper>
      <div className="font-plus-jakarta-sans">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className='font-jakarta'>
            <div className="flex lg:flex-row flex-col  gap-4">
              <div className="lg:w-[70%] w-full lg:flex-grow">
                <div className="bg-[#1E1E1E] rounded-lg shadow-md p-4 h-full">
                  <div className="font-plus-jakarta-sans">
                    <p className="text-[20px] font-jakarta">User Registration Stats</p>
                    <BarChart
                      data={monthFigures}
                      labels={monthLabels}
                      backgroundColors={backgroundColors}
                    />
                  </div>
                </div>
              </div>
              <div className="lg:w-[30%] w-full flex-col flex gap-5">
                <div className="flex-grow">
                  <Propos
                    topLeftText="Total User"
                    middleLeftText={overview?.data?.all_users_count}
                    bottomLeftText="This month"
                    icon={<IoIosTrendingUp className='text-green-500' />}
                  />
                </div>
                <div className="flex-grow">
                  <Propos
                    topLeftText="Total Properties"
                    middleLeftText={overview?.data?.trannsaction_value?.total_investors}
                    bottomLeftText="This month"
                    icon={<IoIosTrendingUp className='text-green-500' />}
                  />
                </div>
                <div className="flex-grow">
                  <Propos
                    topLeftText="Total Bookings"
                    middleLeftText={overview?.data?.trannsaction_value?.transaction_volume}
                    bottomLeftText="This month"
                    icon={<IoIosTrendingUp className='text-green-500' />}
                  />
                </div>
              </div>
            </div>
            <div>
              <RecentTransaction overview={overview?.data?.recent_payments}/>
            </div>
          </div>
        )}
      </div>
    </AdminWrapper>
  );
};

export default Overview;
