import React, { useEffect, useState } from "react";
import AdminWrapper from "../../components/AdminWrapper";
import SearchIcon from "@mui/icons-material/Search";
import PaymentTable from "./PaymentTable";
import { paymentData } from "./paymentData";
import { Link } from "react-router-dom";
import { userRequest } from "../../requestMethod";

const Index = () => {
  // Set "guestList" as the default active tab
  const [activeTab, setActiveTab] = useState("guestList");
  const [investmentsData, setInvestmentData] = useState<any[]>([]);

  useEffect(() => {
    const getInvestments = async () => {
      try {
        const investments = await userRequest("/admin/payments/list");
        console.log(investments, "pay data"); // Check the structure of the response
        setInvestmentData(investments.data); // Assuming this is the correct path
      } catch (err) {
        console.log(err);
      }
    };
    getInvestments();
  }, []);

  return (
    <AdminWrapper>
      <div className="flex justify-between items-center">
        <div className="flex w-[45%] p-2 rounded-[50px] bg-[#1E1E1E] bg-opacity-25">
          <button
            className={`py-1 text-[14px] px-4 font-jakarta rounded-lg ${activeTab === "guestList"
                ? "bg-[#DEA73A] text-black"
                : "bg-transparent text-white"
              }`}
            onClick={() => setActiveTab("guestList")}
          >
            All payments
          </button>
          <button
            className={`py-1 px-4 text-[14px] font-jakarta flex items-center rounded-lg ${activeTab === "promo"
                ? "bg-[#DEA73A] text-black"
                : "bg-transparent text-white"
              }`}
            onClick={() => setActiveTab("promo")}
          >
            <img src="/assets/images/Ellipse 1844 (3).svg" alt="dot" />
            Slot purchase
          </button>
          <button
            className={`py-1 text-[14px] flex font-jakarta items-center px-4 rounded-lg ${activeTab === "habitableDays"
                ? "bg-[#DEA73A] text-black"
                : "bg-transparent text-white"
              }`}
            onClick={() => setActiveTab("habitableDays")}
          >
            <img src="/assets/images/Ellipse 1844 (4).svg" alt="dot" />
            Booking payment
          </button>
        </div>
        <Link to={"/admin/slotdetail"}>
            <p className="bg-[#DEA73A] text-black px-5 py-1 font-jakarta rounded-[10px]">Slot sold</p>
        </Link>
      </div>

      {/* Conditional Rendering Based on Active Tab */}
      {activeTab === "guestList" && (
        <>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center gap-2.5">
              <div className="relative">
                <input
                  type="text"
                  className="border rounded-lg border-[#353945] text-sm py-2 px-3 outline-none  bg-transparent"
                  placeholder="Search"
                />
                <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600" />
              </div>
            
            </div>
            <div>
              <button className="border font-jakarta border-[#B3B3B3] text-[14px] text-gray-600 bg-[#1E1E1E] py-1 px-5 rounded-lg">
                Export
              </button>
            </div>
          </div>

          <div className="my-5">
            <PaymentTable paymentData={investmentsData} />
          </div>
        </>
      )}

      {activeTab === "promo" && (
        <>
        <div className="mt-4">
             <div className="flex justify-between items-center mt-4">
            <div className="flex items-center gap-2.5">
              <div className="relative">
                <input
                  type="text"
                  className="border rounded-lg border-[#353945] text-sm py-2 px-3 outline-none  bg-transparent"
                  placeholder="Search"
                />
                <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600" />
              </div>
            
            </div>
            <div>
              <button className="border font-jakarta border-[#B3B3B3] text-[14px] text-gray-600 bg-[#1E1E1E] py-1 px-5 rounded-lg">
                Export
              </button>
            </div>
          </div>

        </div>
         <div className="my-5">
            <PaymentTable paymentData={investmentsData} />
          </div>
        </>
      )}

      {activeTab === "habitableDays" && (
        <>
        <div className="mt-4">
             <div className="flex justify-between items-center mt-4">
            <div className="flex items-center gap-2.5">
              <div className="relative">
                <input
                  type="text"
                  className="border rounded-lg border-[#353945] text-sm py-2 px-3 outline-none  bg-transparent"
                  placeholder="Search"
                />
                <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600" />
              </div>
            
            </div>
            <div>
              <button className="border border-[#B3B3B3] text-gray-600 bg-[#1E1E1E] py-1 px-5 rounded-lg">
                Export
              </button>
            </div>
          </div>

        </div>
         <div className="my-5">
            <PaymentTable paymentData={paymentData} />
          </div>
        </>
      )}
    </AdminWrapper>
  );
};

export default Index;
