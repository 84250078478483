// registerSlice.js
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  userData: Cookies.get('userData')
    ? JSON.stringify(Cookies.get('userData'))
    : null,
  error: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    registerUserSuccess: (state, action) => {
      state.userData = action.payload.userData;
      state.error = null;
    },
    registerUserFailure: (state, action) => {
      state.userData = null;
      state.error = action.payload;
    },
  },
});

export const { registerUserSuccess, registerUserFailure } =
  registerSlice.actions;

export default registerSlice.reducer;

// registerStart: (state) => {
//     state.isFetching = true;
//   },
//   registerSuccess: (state, action) => {
//     state.isFetching = false;
//     state.registered = true;
//     state.currentUser = action.payload;
//   },
//   registerFailure: (state, action) => {
//     state.isFetching = false;
//     state.error.valid = true;
//     state.error.message = action.payload
//   }

