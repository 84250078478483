// src/components/CongratulationsModal.tsx
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface CongratulationsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CongratulationsModal: React.FC<CongratulationsModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50">
      <div className="bg-[#1E1E1E] border border-[#6F767E] p-6 rounded-lg shadow-lg w-[20%] mx-auto relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={20} />
        </button>
        <div className="flex flex-col items-center">
          <FaCheckCircle size={48} className="text-green-600" />
          <p className="mt-2 text-white">ROI created <br/>successfully.</p>
          <Link to={"/admin/overview"}
            className="mt-4 px-4 py-2 bg-[#DEA838] text-black rounded-md hover:bg-[#DEA838] focus:outline-none"
          >
            Done
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsModal;
