import React from 'react';
import Card from './card';

const cardData = [
  {
    imageSrc: "/assets/images/MaskGroup1.svg",
    title: "Unit 3, The works, Warrington",
    address: "210 US Highway, Highland Lake, FL",
    price: "£14,236.00 per slot",
    handshakeIconSrc: "/assets/images/handshake.svg",
    vectorIconSrc: "/assets/images/Vector.svg",
    bedrooms: "2 Bedrooms",
    bathrooms: "2 Bathrooms",
    area: "473 Sq.Ft",
    bedIconSrc: "/assets/images/Bed.svg",
    bathIconSrc: "/assets/images/bathtub.svg",
    dashboardIconSrc: "/assets/images/dashboard.svg",
  },
  {
    imageSrc: "/assets/images/MaskGroup1.svg",
    title: "Unit 4, The works, Warrington",
    address: "211 US Highway, Highland Lake, FL",
    price: "£15,236.00 per slot",
    handshakeIconSrc: "/assets/images/handshake.svg",
    vectorIconSrc: "/assets/images/Vector.svg",
    bedrooms: "3 Bedrooms",
    bathrooms: "3 Bathrooms",
    area: "573 Sq.Ft",
    bedIconSrc: "/assets/images/Bed.svg",
    bathIconSrc: "/assets/images/bathtub.svg",
    dashboardIconSrc: "/assets/images/dashboard.svg",
  },
  {
    imageSrc: "/assets/images/MaskGroup1.svg",
    title: "Unit 5, The works, Warrington",
    address: "212 US Highway, Highland Lake, FL",
    price: "£16,236.00 per slot",
    handshakeIconSrc: "/assets/images/handshake.svg",
    vectorIconSrc: "/assets/images/Vector.svg",
    bedrooms: "4 Bedrooms",
    bathrooms: "4 Bathrooms",
    area: "673 Sq.Ft",
    bedIconSrc: "/assets/images/Bed.svg",
    bathIconSrc: "/assets/images/bathtub.svg",
    dashboardIconSrc: "/assets/images/dashboard.svg",
  },
  {
    imageSrc: "/assets/images/MaskGroup1.svg",
    title: "Unit 6, The works, Warrington",
    address: "213 US Highway, Highland Lake, FL",
    price: "£17,236.00 per slot",
    handshakeIconSrc: "/assets/images/handshake.svg",
    vectorIconSrc: "/assets/images/Vector.svg",
    bedrooms: "5 Bedrooms",
    bathrooms: "5 Bathrooms",
    area: "773 Sq.Ft",
    bedIconSrc: "/assets/images/Bed.svg",
    bathIconSrc: "/assets/images/bathtub.svg",
    dashboardIconSrc: "/assets/images/dashboard.svg",
  },
  {
    imageSrc: "/assets/images/MaskGroup1.svg",
    title: "Unit 7, The works, Warrington",
    address: "214 US Highway, Highland Lake, FL",
    price: "£18,236.00 per slot",
    handshakeIconSrc: "/assets/images/handshake.svg",
    vectorIconSrc: "/assets/images/Vector.svg",
    bedrooms: "6 Bedrooms",
    bathrooms: "6 Bathrooms",
    area: "873 Sq.Ft",
    bedIconSrc: "/assets/images/Bed.svg",
    bathIconSrc: "/assets/images/bathtub.svg",
    dashboardIconSrc: "/assets/images/dashboard.svg",
  },
  {
    imageSrc: "/assets/images/MaskGroup1.svg",
    title: "Unit 8, The works, Warrington",
    address: "215 US Highway, Highland Lake, FL",
    price: "£19,236.00 per slot",
    handshakeIconSrc: "/assets/images/handshake.svg",
    vectorIconSrc: "/assets/images/Vector.svg",
    bedrooms: "7 Bedrooms",
    bathrooms: "7 Bathrooms",
    area: "973 Sq.Ft",
    bedIconSrc: "/assets/images/Bed.svg",
    bathIconSrc: "/assets/images/bathtub.svg",
    dashboardIconSrc: "/assets/images/dashboard.svg",
  },
];

const All: React.FC = () => {
  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {cardData.map((card, index) => (
          <Card
            key={index}
            imageSrc={card.imageSrc}
            title={card.title}
            address={card.address}
            price={card.price}
            handshakeIconSrc={card.handshakeIconSrc}
            vectorIconSrc={card.vectorIconSrc}
            bedrooms={card.bedrooms}
            bathrooms={card.bathrooms}
            area={card.area}
            bedIconSrc={card.bedIconSrc}
            bathIconSrc={card.bathIconSrc}
            dashboardIconSrc={card.dashboardIconSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default All;
